import { useState, useEffect } from 'react';

// Custom hook: useSavedFilters
const useSavedFilters = () => {
  const [savedFilters, setSavedFilters] = useState(null);

  useEffect(() => {
    // Function to load the selected project from session storage
    const loadSelectedProject = () => {
      const savedValue = sessionStorage.getItem('savedFilters');
      if (savedValue) {
        setSavedFilters(JSON.parse(savedValue));
      } else {
        setSavedFilters([]);
      }
    };

    // Load the selected project when the component mounts
    loadSelectedProject();

    // Add an event listener for storage changes
    window.addEventListener('storage', loadSelectedProject);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('storage', loadSelectedProject);
    };
  }, []);

  return savedFilters;
};

export default useSavedFilters;
