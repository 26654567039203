// material-ui
import { Chip, Typography, Select, OutlinedInput, MenuItem, Box } from '@mui/material';

// project-imports
import { useRolesData } from 'hooks/roles/useRolesData';
import { FormattedMessage } from 'react-intl';
import { getContrastColor } from 'utils/utkuFunctions';

// ==============================|| ACCOUNT PROFILE - BASIC ||============================== //

function RoleSelect({ selectedRole = [], setSelectedRole, isMultiple }) {
  const { data: rolesData } = useRolesData();
  const roles = rolesData?.data?.data || [];

  const handleChange = (event) => {
    const {
      target: { value }
    } = event;
    setSelectedRole(typeof value === 'string' ? value.split(' ') : value);
  };
  return (
    <Select
      displayEmpty
      multiple={isMultiple}
      id="role-select"
      value={selectedRole}
      input={<OutlinedInput />}
      onChange={handleChange}
      renderValue={(selected) => {
        if (isMultiple) {
          if (selected === null) {
            return <Typography variant="subtitle1">{<FormattedMessage id="select_role" />}</Typography>;
          }
          let completeSelectedRoles = roles.filter((role) => selected.includes(role.id));

          if (completeSelectedRoles.length === 0) {
            return <Typography variant="subtitle1">{<FormattedMessage id="select_role" />}</Typography>;
          } else {
            return (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {completeSelectedRoles.map((role) => (
                  <RoleChip role={role} key={role.id} />
                ))}
              </Box>
            );
          }
        } else {
          let selectedRole = roles.filter((role) => selected === role.id)[0];
          if (selectedRole) {
            return (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                <RoleChip role={selectedRole} key={selectedRole.id} />
              </Box>
            );
          } else {
            return <Typography variant="subtitle1">{<FormattedMessage id="select_role" />}</Typography>;
          }
        }
      }}
    >
      {roles.map((role) => (
        <MenuItem key={role.name} value={role.id}>
          <RoleChip role={role} />
        </MenuItem>
      ))}
    </Select>
  );
}

function RoleChip({ role }) {
  return (
    <Chip
      style={{ backgroundColor: role.color }}
      label={
        <Typography variant="body1" color={getContrastColor(role.color)}>
          {role?.name}
        </Typography>
      }
    />
  );
}

export default RoleSelect;
