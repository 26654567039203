import { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import ReactApexChart from 'react-apexcharts';
import { useNavigate } from 'react-router-dom';
import { ThemeMode } from 'config';

const OfferedProductPricesChart = ({ chart }) => {
  // offer_id from params

  const { series, offer_ids, supplier_names } = chart;

  /* const {unit_price} = chart */
  const theme = useTheme();
  const navigate = useNavigate();

  const mode = theme.palette.mode;
  const line = theme.palette.divider;
  const { primary } = theme.palette.text;

  const handleOfferClick = (offer_id) => {
    navigate(`/offers/${offer_id}`);
  };

  const barChartOptions = {
    chart: {
      type: 'bar',
      height: 300,
      events: {
        click: function (event, chartContext, config) {
          if (config.dataPointIndex !== -1) {
            handleOfferClick(offer_ids[config.dataPointIndex]);
          }
        },
        legendClick: function (chartContext, seriesIndex, config) {
          // Hide all series
          config.globals.seriesNames.forEach((seriesName, index) => {
            if (index !== seriesIndex) {
              chartContext.hideSeries(seriesName);
            }
          });

          // Show the clicked series
          chartContext.showSeries(config.globals.seriesNames[seriesIndex]);
        }
      },
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        borderRadius: 5,
        horizontal: true,
        height: 20,
        startingShape: 'flat',
        endingShape: 'flat',
        distributed: false // This needs to be true to apply different colors per data point
      }
    },
    colors: [theme.palette.warning.main, theme.palette.primary.main] /*  offer_ids
      .map((offer_id) =>
        offer_id == id ? [theme.palette.success.main, theme.palette.info.main] : [theme.palette.warning.main, theme.palette.primary.main]
      )
      .flat(), */,
    xaxis: {
      labels: {
        style: {
          fontSize: '12px',
          colors: primary
        },
        formatter: function (value) {
          return `${value.toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' })}`;
        }
      }
    },
    yaxis: {
      labels: {
        style: {
          fontSize: '12px',
          colors: primary,
          align: 'left',
          cssClass: 'apexcharts-yaxis-label' // Apply custom CSS class
        }
      }
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: [primary]
      },
      formatter: function (value) {
        return `${value.toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' })}`;
      }
    },
    grid: {
      borderColor: line,
      strokeDashArray: 4
    },
    tooltip: {
      y: {
        formatter: function (value) {
          return `${value.toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' })}`;
        },
        title: {
          formatter: function () {
            return '';
          }
        }
      }
    },
    /*   colors: [theme.palette.warning.main, theme.palette.primary.main], */
    theme: {
      mode: mode === ThemeMode.DARK ? 'dark' : 'light'
    },
    legend: {
      onItemClick: {
        toggleDataSeries: false
      }
    }
  };

  console.log('barChartOptions colors', barChartOptions.colors);

  const [options, setOptions] = useState(barChartOptions);

  const categories = offer_ids.map((offer_id, index) => `${supplier_names[index]} | Teklif #${offer_id}`);

  useEffect(() => {
    setOptions((prevState) => ({
      ...prevState,
      xaxis: {
        ...prevState.xaxis,
        categories: categories
      },
      grid: {
        borderColor: line
      },
      theme: {
        mode: mode === ThemeMode.DARK ? 'dark' : 'light'
      },
      chart: {
        ...prevState.chart,
        background: undefined
      }
    }));

    setTimeout(() => {
      // resize the chart
      window.dispatchEvent(new Event('resize'));
    }, 10);
  }, [mode, primary, line, theme.palette, chart]);

  return (
    <div id="chart">
      <ReactApexChart options={options} series={series} type="bar" height={series?.[0]?.data?.length * 40 + 200} />
    </div>
  );
};

export default OfferedProductPricesChart;
