import axios from 'axios';
import axiosRetry from 'axios-retry';
import { enqueueSnackbar } from 'notistack';

const baseURL = process.env.REACT_APP_API_URL || 'http://localhost:8800';

console.log('REACT_APP_API_URL', baseURL);

const myAxios = axios.create({ baseURL, withCredentials: true });

const noNavigateTo404Urls = ['/api/works/tree'];

axiosRetry(myAxios, {
  retries: 0 // Number of retries
});
myAxios.interceptors.response.use(
  (response) => {
    const data = response.data;

    if (data.m) {
      enqueueSnackbar(response.data.m, { variant: 'success', preventDuplicate: true, autoHideDuration: 2000 });
    }

    return response;
  },
  (error) => {
    const response = error.response;
    if (!response) {
      enqueueSnackbar('Sunucuya ulaşılamıyor, lütfen daha sonra tekrar deneyin', {
        variant: 'warning',
        preventDuplicate: true,
        autoHideDuration: 2000
      });
      return Promise.reject(error);
    }

    const data = response.data;
    console.log('status', response.status);
    console.log('data', data);

    if (data?.m) {
      enqueueSnackbar(data.m, { variant: 'error', preventDuplicate: true, autoHideDuration: 2000 });
    }

    if (response.status === 401) {
      if (data.redirect) {
        setTimeout(() => {
          window.location.pathname = '/login';
        }, 10);
      }
    } else if (response.status === 404) {
      // if origil url doesn't start with any of the noNavigateTo404Urls, navigate to 404 page
      const isNavigateTo404 = !noNavigateTo404Urls.some((url) => response.config.url.startsWith(url));
      if (isNavigateTo404) {
        setTimeout(() => {
          window.location.pathname = '/404';
        }, 10);
      }
    } else if (response.status === 429) {
      // TOO MANY REQUESTS
      enqueueSnackbar(data?.m || 'Çok fazla istek gönderdiniz. Lütfen daha sonra tekrar deneyiniz.', {
        variant: 'error',
        preventDuplicate: true,
        autoHideDuration: 2000
      });
    }

    return Promise.reject((response && response.data) || 'Beklenmeyen hata oluştu.');
  }
);

export default myAxios;
