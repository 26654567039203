import { useState } from 'react';

// material-ui
import { TextField, Autocomplete, ListItem, ListItemText, ListItemAvatar, CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// project-imports
import { useAutocompleteUsers } from 'hooks/user/useUsersData';
import Avatar from 'components/@extended/Avatar';

// third-party
import { useAsyncDebounce } from 'react-table';

// ==============================|| ACCOUNT PROFILE - BASIC ||============================== //

function UserSelect({ selectedUser, setSelectedUser, onChange, label }) {
  const theme = useTheme();
  const [search, setSearch] = useState('');
  const [isFocused, setIsFocused] = useState(false);

  const { isLoading, isFetching, data } = useAutocompleteUsers({ search, isFocused });
  const users = data?.data || [];

  const onSearchChange = useAsyncDebounce((value) => {
    setSearch(value);
  }, 200);

  return (
    <Autocomplete
      sx={{ minWidth: 250 }}
      loading={isLoading || isFetching}
      loadingText="Yükleniyor..."
      id="user-select"
      value={selectedUser}
      options={users}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onChange={(event, newValue) => {
        if (onChange) onChange(newValue);
        if (setSelectedUser) setSelectedUser(newValue);
      }}
      onFocus={() => setIsFocused(true)}
      onBlur={() => {
        setIsFocused(false);
        setSearch('');
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={`${label || 'Kullanıcı'} Ara`}
          label={label !== undefined ? label : ''}
          value={search}
          onChange={(e) => {
            onSearchChange(e.target.value);
          }}
          sx={{ '& .MuiAutocomplete-input.Mui-disabled': { WebkitTextFillColor: theme.palette.text.primary } }}
          InputProps={{
            ...params.InputProps,
            startAdornment: selectedUser && (
              <ListItemAvatar>
                <Avatar size="sm" src={process.env.REACT_APP_API_URL + selectedUser.avatar_url}>
                  {selectedUser.name.charAt(0) + selectedUser.name.charAt(1)}
                </Avatar>
              </ListItemAvatar>
            ),
            endAdornment: (
              <>
                {isLoading || isFetching ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            )
          }}
        />
      )}
      renderOption={(props, option) => (
        <ListItem {...props}>
          <ListItemAvatar>
            <Avatar src={process.env.REACT_APP_API_URL + option.avatar_url}>{option.name.charAt(0) + option.name.charAt(1)}</Avatar>
          </ListItemAvatar>
          <ListItemText primary={option.name} secondary={option.username} />
        </ListItem>
      )}
      noOptionsText="Kullanıcı bulunamadı"
    />
  );
}

export default UserSelect;
