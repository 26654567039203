import { useQuery } from 'react-query';
import axios from 'utils/axios2';

const fetchExchangeRatesToday = async () => {
  const { data } = await axios.get('/api/exchange_rates/today');
  return data;
};

export const useExchangeRatesToday = () => {
  return useQuery('exchange-rates-today', fetchExchangeRatesToday, {
    cacheTime: 60 * 60 * 1000,
    staleTime: 60 * 60 * 1000,
    keepPreviousData: false,
    refetchInterval: 60 * 60 * 1000
  });
};
