// material-ui
import { CardContent, Modal, Typography, Stack } from '@mui/material';

// project-imports
import MainCard from 'components/MainCard';

// assets
import CircularWithPath from './@extended/progress/CircularWithPath';

// ==============================|| MODAL - BASIC ||============================== //

export default function LoadingModal({ open }) {
  return (
    <Modal
      disableAutoFocus
      open={open || false}
      sx={{
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width: 0
        }
      }}
    >
      <MainCard
        sx={{
          borderRadius: '12px'
        }}
        title="Lütfen bekleyiniz"
        modal
        darkTitle
        content={false}
      >
        <CardContent>
          <Stack spacing={2} justifyContent="center" alignItems="center">
            <Typography id="modal-modal-description">Yükleniyor...</Typography>
            <CircularWithPath size={60} value={parseFloat((Math.random() * 100).toFixed(0))} showLabel={true} />
          </Stack>
        </CardContent>
      </MainCard>
    </Modal>
  );
}
