import PropTypes from 'prop-types';

// material-ui
import { Button, Dialog, DialogContent, Stack, Typography } from '@mui/material';

// project-imports
import Avatar from 'components/@extended/Avatar';
import { PopupTransition } from 'components/@extended/Transitions';

// assets
import { ShieldCross, ShieldTick } from 'iconsax-react';

// third-party
import { FormattedMessage } from 'react-intl';

// ==============================|| CUSTOMER - DELETE ||============================== //

export default function AlertApprove({ highlight, is_approve, open, setOpen, handleApprove }) {
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      keepMounted
      TransitionComponent={PopupTransition}
      maxWidth="xs"
      aria-labelledby="column-delete-title"
      aria-describedby="column-delete-description"
    >
      <DialogContent sx={{ mt: 2, my: 1 }}>
        <Stack alignItems="center" spacing={3.5}>
          <Avatar color={is_approve ? 'success' : 'error'} sx={{ width: 72, height: 72, fontSize: '1.75rem' }}>
            {is_approve ? <ShieldTick fontSize="inherit" /> : <ShieldCross fontSize="inherit" />}
          </Avatar>
          <Stack spacing={2}>
            <Typography variant="h5" align="center" color={is_approve ? 'success.main' : 'error.main'}>
              {highlight}
            </Typography>
            <Typography variant="h5" align="center">
              {is_approve ? 'Onay Vermek İstediğinizden Emin Misiniz?' : 'Onayı Geri Almak İstediğinizden Emin Misiniz?'}
            </Typography>
          </Stack>

          <Stack direction="row" spacing={2} sx={{ width: 1 }}>
            <Button fullWidth onClick={() => setOpen(false)} color="secondary" variant="outlined">
              <FormattedMessage id="cancel" />
            </Button>
            <Button
              fullWidth
              color={is_approve ? 'success' : 'error'}
              variant="contained"
              onClick={() => {
                setOpen(true);
                handleApprove(is_approve);
              }}
              autoFocus
            >
              <FormattedMessage id={is_approve ? 'approve' : 'disapprove'} />
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

AlertApprove.propTypes = {
  title: PropTypes.string,
  des1: PropTypes.string,
  des2: PropTypes.string,
  des3: PropTypes.string,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  handleApprove: PropTypes.func
};
