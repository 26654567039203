// ==============================|| OVERRIDES - TABLE ROW ||============================== //

export default function TableRow(theme) {
  return {
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:last-of-type': {
            '& .MuiTableCell-root': {
              borderBottom: 'none'
            }
          },
          '& .MuiTableCell-root': {
            '&:last-of-type': {
              paddingRight: 24,
              [theme.breakpoints.down('sm')]: {
                paddingRight: 8
              }
            },
            '&:first-of-type': {
              paddingLeft: 24,
              [theme.breakpoints.down('sm')]: {
                paddingLeft: 8
              }
            }
          }
        }
      }
    }
  };
}
