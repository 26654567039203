import React from 'react';
import PropTypes from 'prop-types';
import { NumericFormat } from 'react-number-format';
export const getColorByRank = (rank, total) => {
  const rankPercentage = (rank / total) * 100;

  if (rank === 1) {
    return 'success';
  } else if (rank === total) {
    return 'error';
  } else if (rankPercentage <= 20) {
    return 'success';
  } else if (rankPercentage <= 40) {
    return 'info';
  } else if (rankPercentage <= 60) {
    return 'primary';
  } else if (rankPercentage <= 80) {
    return 'warning';
  } else {
    return 'error';
  }
};

export const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
  const { onChange, name, decimalScale = 2, decimalSeparator = ',', thousandSeparator = '.', allowNegative = true, ...other } = props;
  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        const { value: newValue } = values;

        // Pass the new value directly to the onChange handler
        onChange({
          target: {
            name,
            value: newValue === '' ? '' : newValue // Empty string is allowed
          }
        });
      }}
      decimalScale={decimalScale}
      decimalSeparator={decimalSeparator}
      thousandSeparator={thousandSeparator}
      fixedDecimalScale={false}
      allowNegative={allowNegative}
      valueIsNumericString
    />
  );
});

NumberFormatCustom.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  decimalScale: PropTypes.number,
  decimalSeparator: PropTypes.string,
  thousandSeparator: PropTypes.string,
  allowNegative: PropTypes.bool
};

export function arrayToObject(arr) {
  const result = {};
  arr.forEach((item) => {
    if (item.id !== undefined) {
      result[item.id] = item;
    }
  });
  return result;
}

export function groupBy(array, key) {
  return array.reduce((result, currentValue) => {
    (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
    return result;
  }, {});
}

export const getNowUTC = () => {
  const now = new Date();
  /* console.log('now Date', now); */
  const localNow = new Date(now.getTime() /*  + now.getTimezoneOffset() * 60000 */);
  /*  console.log('localNow Date', new Date(localNow)); */
  return new Date(localNow).getTime();
};

export const NumberFormatInteger = (props) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      decimalScale={0}
      decimalSeparator=","
      thousandSeparator="."
      fixedDecimalScale
      allowLeadingZeros={false}
    />
  );
};

export const NumberFormatPositive = (props) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      decimalScale={0}
      decimalSeparator=","
      thousandSeparator="."
      fixedDecimalScale
      allowNegative={false}
      allowLeadingZeros={false}
    />
  );
};

export const parseDate = (str) => {
  const parts = str.split('-');
  return new Date(parts[2], parts[1] - 1, parts[0]);
};

export const getContrastColor = (hexcolor) => {
  var r = parseInt(hexcolor.substring(1, 3), 16);
  var g = parseInt(hexcolor.substring(3, 5), 16);
  var b = parseInt(hexcolor.substring(5, 7), 16);
  var yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? 'black' : 'white';
};

export const isValidDate = (d) => {
  return d instanceof Date && !isNaN(d);
};

export const getRandomInt = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const getRandomElement = (array) => {
  return array[getRandomInt(0, array.length - 1)];
};

export const generateRandomAmount = (min, max, decimals = 2) => {
  let amount = (Math.random() * (max - min) + min).toFixed(decimals);
  amount = Math.round(amount * 4) / 4;
  return amount;
};

export const formatNumber = (numStr) => {
  let num = parseFloat(numStr);

  return num.toLocaleString('tr-TR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
};
