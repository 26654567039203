import { useQuery, useMutation, useQueryClient } from 'react-query';
import axios from 'utils/axios2';

const fetchAllRoles = () => {
  return axios.get('/api/roles');
};

export const useRolesData = () => {
  return useQuery('roles', fetchAllRoles, { cacheTime: 1 * 60 * 1000, staleTime: 1 * 60 * 1000, keepPreviousData: true });
};

const fetchPermissions = () => {
  return axios.get('/api/roles/permissions');
};

export const usePermissionsData = (params) => {
  return useQuery('permissions', fetchPermissions, {
    enabled: params?.isFocused,
    staleTime: 1000 * 60 * 60,
    cacheTime: 1000 * 60 * 60,
    keepPreviousData: true
  });
};

const addRole = (body) => {
  return axios.post('/api/roles', body);
};

export const useAddRole = () => {
  const queryClient = useQueryClient();
  return useMutation(addRole, {
    onSuccess: () => {
      queryClient.invalidateQueries('roles');
    }
  });
};

const updateRole = (body) => {
  return axios.put('/api/roles', body);
};

export const useUpdateRole = () => {
  const queryClient = useQueryClient();
  return useMutation(updateRole, {
    onSuccess: () => {
      queryClient.invalidateQueries('roles');
    }
  });
};

const deleteRole = (id) => {
  return axios.delete(`/api/roles/${id}`);
};

export const useDeleteRole = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteRole, {
    onSuccess: () => {
      queryClient.invalidateQueries('roles');
    }
  });
};
