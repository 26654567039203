import React from 'react';
import { CardMedia, ListItemText, Stack } from '@mui/material';
import AvatarGroup from '@mui/material/AvatarGroup'; // Import AvatarGroup from Material-UI
import Avatar from 'components/@extended/Avatar';
import CustomTooltip from 'components/@extended/Tooltip';
import MainCard from 'components/MainCard';

// Bigger avatar and name
const TooltipContent = ({ project }) => (
  <MainCard border={false} content={false}>
    <CardMedia component="img" image={process.env.REACT_APP_API_URL + project?.banner_url} alt={project?.name} />
    <ListItemText primaryTypographyProps={{ variant: 'h3', m: 2 }} primary={project?.name} />
  </MainCard>
);

const ProjectCell = ({ project, avatarProps, textProps }) => {
  if (!project || (Array.isArray(project) && project.length === 0)) return null;

  const isArray = Array.isArray(project);

  if (isArray) {
    // Render each avatar with its own tooltip
    return (
      <CustomTooltip
        key={'asdf'}
        color={'primary'}
        arrow
        placement={'right'}
        title={
          <Stack spacing={1}>
            {project.map((p, index) => (
              <ProjectCell key={index} project={p} avatarProps={avatarProps} textProps={textProps} />
            ))}
          </Stack>
        }
      >
        <Stack direction={'row'} spacing={1} alignItems={'center'}>
          <AvatarGroup max={5}>
            {project.map((p, index) => (
              <Avatar key={index} size="sm" src={process.env.REACT_APP_API_URL + p?.banner_url} {...avatarProps}>
                {p?.name?.charAt(0) + p?.name?.charAt(1)}
              </Avatar>
            ))}
          </AvatarGroup>
        </Stack>
      </CustomTooltip>
    );
  } else {
    // Single project case
    return (
      <CustomTooltip placement={'right'} color={'primary'} arrow title={<TooltipContent project={project} />}>
        <Stack direction={'row'} spacing={1} alignItems={'center'}>
          <Avatar size="sm" src={process.env.REACT_APP_API_URL + project?.banner_url} {...avatarProps}>
            {project?.name?.charAt(0) + project?.name?.charAt(1)}
          </Avatar>
          <ListItemText primary={project?.name} {...textProps} />
        </Stack>
      </CustomTooltip>
    );
  }
};

export default ProjectCell;
