import { useEffect } from 'react';

const usePreventNavigation = (isChanged) => {
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isChanged) {
        event.preventDefault();
        event.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isChanged]);
};

export default usePreventNavigation;
