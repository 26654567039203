// material-ui
import { CardContent, CircularProgress, Stack } from '@mui/material';

// project-imports
import MainCard from 'components/MainCard';

export default function LoadingCard() {
  return (
    <MainCard
      sx={{
        borderRadius: '12px',
        height: '100%',
        display: 'flex'
      }}
      title="Yükleniyor..."
      darkTitle
      content={false}
    >
      <CardContent>
        <Stack spacing={4} justifyContent="center" alignItems="center">
          <CircularProgress size={60} />
        </Stack>
      </CardContent>
    </MainCard>
  );
}
