import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { List, ListItemText, ListItem } from '@mui/material';

// project-imports
import { DropzopType } from 'config';
import IconButton from 'components/@extended/IconButton';

// utils
import getDropzoneData from 'utils/getDropzoneData';

// assets
import { CloseCircle, Document } from 'iconsax-react';
import ImgFileAi from 'assets/images/icons/file/img-file-ai.svg';
import ImgFileDoc from 'assets/images/icons/file/img-file-doc.svg';
import ImgFilePdf from 'assets/images/icons/file/img-file-pdf.svg';
import ImgFilePpt from 'assets/images/icons/file/img-file-ppt.svg';
import ImgFileRar from 'assets/images/icons/file/img-file-rar.svg';
import ImgFileTxt from 'assets/images/icons/file/img-file-txt.svg';
import ImgFileXls from 'assets/images/icons/file/img-file-xls.svg';
// ==============================|| MULTI UPLOAD - PREVIEW ||============================== //

export default function FilesPreview({ showList = false, files, onRemove, type }) {
  const theme = useTheme();
  const hasFile = files.length > 0;
  const layoutType = type;

  return (
    <List
      disablePadding
      sx={{
        ...(hasFile && type !== DropzopType.standard && { my: 3 }),
        ...(type === DropzopType.standard && { width: 'calc(100% - 84px)' })
      }}
    >
      {files.map((file, index) => {
        const { key, name, size, preview, type } = getDropzoneData(file, index);
        /* console.log('key', key, 'name', name, 'size', size, 'preview', preview, 'type', type); */
        if (showList) {
          return (
            <ListItem
              key={key}
              sx={{
                p: 0,
                m: 0.5,
                width: layoutType === DropzopType.standard ? 64 : 80,
                height: layoutType === DropzopType.standard ? 64 : 80,
                borderRadius: 1.25,
                position: 'relative',
                display: 'inline-flex',
                verticalAlign: 'text-top',
                border: `solid 1px ${theme.palette.divider}`,
                overflow: 'hidden'
              }}
            >
              {type?.includes('image') ? (
                <img alt="preview" src={preview} style={{ width: '100%' }} />
              ) : key?.includes('ai') ? (
                <ImgFileAi />
              ) : key?.includes('doc') ? (
                <ImgFileDoc style={{ width: '100%' }} />
              ) : key?.includes('pdf') ? (
                <ImgFilePdf style={{ width: '100%' }} />
              ) : key?.includes('ppt') ? (
                <ImgFilePpt style={{ width: '100%' }} />
              ) : key?.includes('rar') ? (
                <ImgFileRar style={{ width: '100%' }} />
              ) : key?.includes('txt') ? (
                <ImgFileTxt style={{ width: '100%' }} />
              ) : key?.includes('xls') ? (
                <ImgFileXls alt="preview" src={ImgFileXls} style={{ width: '100%' }} />
              ) : (
                {
                  /* <Document variant="Bold" style={{ width: '100%', fontSize: '1.5rem' }} /> */
                }
              )}

              {onRemove && (
                <IconButton
                  size="small"
                  color="error"
                  shape="rounded"
                  onClick={() => onRemove(file)}
                  sx={{
                    fontSize: '0.875rem',
                    bgcolor: 'background.paper',
                    p: 0,
                    width: 'auto',
                    height: 'auto',
                    top: 2,
                    right: 2,
                    position: 'absolute'
                  }}
                >
                  <CloseCircle variant="Bold" />
                </IconButton>
              )}
            </ListItem>
          );
        }

        return (
          <ListItem
            key={key}
            sx={{
              my: 1,
              px: 2,
              py: 0.75,
              borderRadius: 0.75,
              border: (theme) => `solid 1px ${theme.palette.divider}`
            }}
          >
            <Document variant="Bold" style={{ width: '30px', height: '30px', fontSize: '1.15rem', marginRight: 4 }} />

            <ListItemText
              primary={typeof file === 'string' ? file : name}
              secondary={typeof file === 'string' ? '' : (size / 1024 / 1024).toFixed(2) + ' MB'}
              primaryTypographyProps={{ variant: 'subtitle2' }}
              secondaryTypographyProps={{ variant: 'caption' }}
            />

            {onRemove && (
              <IconButton edge="end" size="small" onClick={() => onRemove(file)}>
                <CloseCircle variant="Bold" style={{ fontSize: '1.15rem' }} />
              </IconButton>
            )}
          </ListItem>
        );
      })}
    </List>
  );
}

FilesPreview.propTypes = {
  showList: PropTypes.bool,
  files: PropTypes.array,
  onRemove: PropTypes.func,
  type: PropTypes.string
};
