import { AddSquare, ArrowUp, Folder2, Hierarchy, Message, Messages3, ShieldTick, Trash } from 'iconsax-react';

export const notificationTypes = {
  update: {
    key: 'update',
    color: 'info',
    icon: <Hierarchy size={3} variant="Bold" />,
    label: 'Değişiklik'
  },
  offer_revision: {
    key: 'offer_revision',
    color: 'info',
    icon: <Hierarchy size={3} variant="Bold" />,
    label: 'Teklif Revizyonu'
  },
  approve: {
    key: 'approve',
    color: 'success',
    icon: <ShieldTick size={3} variant="Bold" />,
    label: 'Onay'
  },
  comment: {
    key: 'comment',
    color: 'secondary',
    icon: <Messages3 size={3} variant="Bold" />,
    label: 'Yorum'
  },
  insert: {
    key: 'insert',
    color: 'primary',
    icon: <AddSquare size={3} variant="Bold" />,
    label: 'Ekleme'
  },
  bulk_insert: {
    key: 'bulk_insert',
    color: 'primary',
    icon: <ArrowUp size={3} variant="Bold" />,
    label: 'Çoklu Ekleme'
  },
  delete: {
    key: 'delete',
    color: 'error',
    icon: <Trash size={3} variant="Bold" />,
    label: 'Silme'
  },
  attachement: {
    key: 'attachement',
    color: 'warning',
    icon: <Folder2 size={3} variant="Bold" />,
    label: 'Belge'
  },
  message: {
    key: 'message',
    color: 'secondary',
    icon: <Message size={3} variant="Bold" />,
    label: 'Mesaj'
  },
  group_message: {
    key: 'group_message',
    color: 'secondary',
    icon: <Message size={3} variant="Bold" />,
    label: 'Grup Mesajı'
  }
};
