import { useState } from 'react';

// material-ui

// project-imports
import MovementPanel from './MovementPanel';
import IconButton from './@extended/IconButton';

// assets
import { Discover } from 'iconsax-react';

const DiscoverButton = ({ table_name, id, nano_id, ...props }) => {
  const [panelVisible, setPanelVisible] = useState(false);

  const handleDrawerOpen = () => {
    setPanelVisible(!panelVisible);
  };

  return (
    <IconButton
      {...props}
      onClick={(e) => {
        e.stopPropagation();
        handleDrawerOpen();
      }}
      color="secondary"
    >
      <MovementPanel
        open={panelVisible}
        handleDrawerOpen={handleDrawerOpen}
        table_name={table_name}
        object_id={id}
        nano_id={nano_id} //only in supplierScreen
      />
      <Discover variant="Bulk" />
    </IconButton>
  );
};

export default DiscoverButton;
