import React from 'react';
import { Chip, Typography } from '@mui/material';
import { getContrastColor } from 'utils/utkuFunctions';

const RoleCell = ({ value }) => {
  return (
    <Chip
      variant="filled"
      label={
        <Typography
          variant="body1"
          sx={{
            color: getContrastColor(value.color)
          }}
        >
          {value.name}
        </Typography>
      }
      style={{ backgroundColor: value.color }}
    />
  );
};

export default RoleCell;
