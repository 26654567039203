import { useState } from 'react';

// material-ui
import { TextField, Autocomplete, ListItem, CircularProgress, ListItemText } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// project-imports
import { useAutocompleteProductNames } from 'hooks/products/useProductsData';

import { useAsyncDebounce } from 'react-table';

// ==============================|| ACCOUNT PROFILE - BASIC ||============================== //

function ProductNameSelect({ selectedProductName, setCreationVisible, setSelectedProductName, formik, onChange }) {
  const theme = useTheme();
  const [search, setSearch] = useState('');
  const [isFocused, setIsFocused] = useState(false);

  const { isLoading, isFetching, data } = useAutocompleteProductNames({ search, isFocused });
  const productNames = data?.data || [];

  const onSearchWordChange = useAsyncDebounce((value) => {
    setSearch(value);
  }, 300);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && !productNames.some((product) => product.name === search)) {
      setCreationVisible && setCreationVisible(true);
    }
  };

  return (
    <>
      <Autocomplete
        loading={isLoading}
        loadingText="Ürün İsimleri Getiriliyor..."
        id="product-name-select"
        value={selectedProductName || null}
        options={productNames}
        isOptionEqualToValue={(option, value) => option === value}
        getOptionLabel={(option) => option}
        onChange={(event, newValue) => {
          if (onChange) {
            onChange(newValue);
          }
          console.log('setSelectedProductName', newValue);
          setSelectedProductName(newValue);
          if (!newValue) {
            setSearch('');
          }
        }}
        onFocus={() => setIsFocused(true)}
        onBlur={() => {
          setIsFocused(false);
          setSelectedProductName(selectedProductName || search);
          setSearch('');
        }}
        filterOptions={(options) => options}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Ürünü tanımlayan bir isim girin"
            sx={{ '& .MuiAutocomplete-input.Mui-disabled': { WebkitTextFillColor: theme.palette.text.primary } }}
            onChange={(e) => {
              onSearchWordChange(e.target.value), onChange(e.target.value);
            }}
            onKeyPress={handleKeyPress} // Add the key press event handler
            error={formik && formik.touched.product && Boolean(formik.errors.product)}
            helperText={formik && formik.touched.product && formik.errors.product && formik.errors.product}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isLoading || isFetching ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              )
            }}
          />
        )}
        renderOption={(props, option) => (
          <ListItem {...props} disablePadding disableGutters>
            <ListItemText primary={option} />
          </ListItem>
        )}
        noOptionsText="Harika! Bu ürünü daha önce eklememişsiniz."
        freeSolo
      />
    </>
  );
}

export default ProductNameSelect;
