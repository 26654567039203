import { useState, useEffect } from 'react';

// material-ui
import { TextField, Autocomplete, ListItem, ListItemText, Dialog, ListItemAvatar, Chip, CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// project-imports
import { PopupTransition } from 'components/@extended/Transitions';
import { useAutocompleteProducts } from 'hooks/products/useProductsData';
import ProductCreationForm from 'sections/forms/validation/ProductCreationForm';

import { useAsyncDebounce } from 'react-table';
import ProductImageCell from 'components/data/ProductImageCell';

// ==============================|| ACCOUNT PROFILE - BASIC ||============================== //

function ProductSelect({
  selectedProduct,
  setSelectedProduct,
  creationVisible,
  setCreationVisible,
  formik,
  onChange,
  isMultiple,
  predefinedData
}) {
  const theme = useTheme();
  const [search, setSearch] = useState('');
  const [isFocused, setIsFocused] = useState(false);

  const { isLoading, isFetching, data } = useAutocompleteProducts({ search, isFocused });
  const fetchedProducts = data?.data || [];
  const products = predefinedData || fetchedProducts;

  const onSearchWordChange = useAsyncDebounce((value) => {
    setSearch(value);
  }, 300);

  useEffect(() => {
    if (predefinedData) {
      setSearch('');
      setIsFocused(false);
    }
  }, [predefinedData]);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && !products.some((product) => product.name === search)) {
      setCreationVisible(true);
    }
  };

  return (
    <>
      <Dialog maxWidth="md" TransitionComponent={PopupTransition} open={creationVisible} onClose={() => setCreationVisible(false)}>
        <ProductCreationForm
          newName={search}
          setSelectedProduct={setSelectedProduct}
          setCreationVisible={setCreationVisible}
          distinctValues={data?.distinctValues}
        />
      </Dialog>
      <Autocomplete
        multiple={isMultiple}
        loading={isLoading && !predefinedData}
        loadingText="Yükleniyor..."
        id="product-select"
        value={selectedProduct || null}
        options={products}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option.name}
        onChange={(event, newValue) => {
          if (onChange) {
            onChange(newValue);
          }
          setSelectedProduct(newValue);
          if (!newValue) {
            setSearch('');
          }
        }}
        onFocus={() => setIsFocused(true)}
        onBlur={() => {
          setIsFocused(false);
          setSearch('');
        }}
        filterOptions={(options) => options}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Ürün Ara"
            sx={{ '& .MuiAutocomplete-input.Mui-disabled': { WebkitTextFillColor: theme.palette.text.primary } }}
            onChange={(e) => onSearchWordChange(e.target.value)}
            onKeyPress={handleKeyPress} // Add the key press event handler
            error={formik && formik.touched.product && Boolean(formik.errors.product)}
            helperText={formik && formik.touched.product && formik.errors.product && formik.errors.product}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isLoading || isFetching ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              )
            }}
          />
        )}
        renderOption={(props, option) => (
          <ListItem {...props} disablePadding disableGutters>
            <ListItemText primary={option.name} />
            <ListItemAvatar>
              <ProductImageCell image_url={option.image_url} avatarProps={{ size: 'md' }} />
            </ListItemAvatar>
            <ListItemAvatar>
              <Chip label={option.type} color="primary" />
            </ListItemAvatar>
          </ListItem>
        )}
        noOptionsText="Aradığınız ürünü bulamadık. Yeni ürün tanımlamak için Enter tuşuna basabilirsiniz."
      />
    </>
  );
}

export default ProductSelect;
