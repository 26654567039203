// material-ui
import { TextField, Autocomplete, ListItem, ListItemText } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// project-imports
import { useAutocompleteStorages } from 'hooks/storage/useStoragesData';
import { useState } from 'react';
import { useAsyncDebounce } from 'react-table';

// third-party
import { getIn } from 'formik';

function StorageSelect({ selectedStorage, setSelectedStorage, formik, id, label, onChange, params }) {
  const theme = useTheme();
  const [search, setSearch] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const { isLoading, data: storagesData } = useAutocompleteStorages({ search, isFocused, ...params });

  const onSearchWordChange = useAsyncDebounce((value) => {
    setSearch(value);
  }, 300);

  const storages = storagesData?.data || [];
  return (
    <Autocomplete
      loading={isLoading}
      loadingText="Yükleniyor..."
      id="storage-select"
      value={selectedStorage || null}
      options={storages}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => {
        return option.id == value.id;
      }}
      onChange={(event, newValue) => {
        if (onChange) {
          onChange(newValue);
        }
        setSelectedStorage && setSelectedStorage(newValue);
        if (!newValue) {
          setSearch('');
        }
      }}
      onBlur={() => {
        setSearch('');
        setIsFocused(false);
      }}
      onFocus={() => setIsFocused(true)}
      renderInput={(params) => (
        <TextField
          {...params}
          data-cy="storage-select-search"
          placeholder="Depo Ara"
          label={!label ? label : 'Depo'}
          onChange={(e) => onSearchWordChange(e.target.value)}
          sx={{ '& .MuiAutocomplete-input.Mui-disabled': { WebkitTextFillColor: theme.palette.text.primary } }}
          error={getIn(formik?.errors, id) && getIn(formik?.touched, id)}
          helperText={getIn(formik?.errors, id) && getIn(formik?.touched, id) && getIn(formik?.errors, id)}
        />
      )}
      renderOption={(props, option) => (
        <ListItem {...props}>
          <ListItemText primary={option.name} secondary={option.type} />
        </ListItem>
      )}
      noOptionsText="Depo bulunamadı"
    />
  );
}

export default StorageSelect;
