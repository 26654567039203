// material-ui
import { Button, Grid, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// project-imports
import { ThemeMode } from 'config';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AnimateButton from 'components/@extended/AnimateButton';
import AuthWrapper from 'sections/auth/AuthWrapper';
// third-party
import OtpInput from 'react18-input-otp';

// ================================|| ENTER OFFER NANO ID  ||================================ //

const EnterOfferNanoId = () => {
  const theme = useTheme();
  const [offerNanoId, setOfferNanoId] = useState();
  const navigate = useNavigate();

  const borderColor = theme.palette.mode === ThemeMode.DARK ? theme.palette.secondary[200] : theme.palette.secondary.light;

  return (
    <AuthWrapper>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Stack spacing={1}>
            <Typography variant="h3">Teklif Anahtarınızı Giriniz</Typography>
            <Typography color="secondary">Teklif anahtarınızı e-Postanızdan veya satın alma yetkilinizden öğrenebilirsiniz</Typography>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Typography>Bu koda sahip olan tekliflerinizi görebilir ve düzenleme yapabilir</Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <OtpInput
                shouldAutoFocus={true}
                value={offerNanoId}
                onChange={(offerNanoId) => setOfferNanoId(offerNanoId)}
                numInputs={10}
                containerStyle={{ justifyContent: 'space-between' }}
                inputStyle={{
                  width: '100%',
                  margin: '2px',
                  padding: '10px',
                  border: `1px solid ${borderColor}`,
                  borderRadius: 4,
                  ':hover': {
                    borderColor: theme.palette.primary.main
                  }
                }}
                focusStyle={{
                  outline: 'none',
                  boxShadow: theme.customShadows.primary,
                  border: `1px solid ${theme.palette.primary.main}`
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <AnimateButton>
                <Button
                  onClick={() => {
                    if (offerNanoId && offerNanoId.length === 10) {
                      navigate(`/teklif/${offerNanoId}`, { replace: true });
                    }
                  }}
                  disableElevation
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Sorgula
                </Button>
              </AnimateButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </AuthWrapper>
  );
};

export default EnterOfferNanoId;
