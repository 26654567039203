export const paymentTypes = [
  { name: 'Nakit', color: 'success' },
  { name: 'Vade', color: 'success' },
  { name: 'Çek', color: 'warning' },
  { name: 'Senet', color: 'info' },
  { name: 'Avans', color: 'error' },
  { name: 'Banka Havalesi', color: 'primary' },
  { name: 'Mail Order/Kredi Kartı', color: 'secondary' },
  { name: 'Avans Teminat Mektubu', color: 'default' }
];
