import React from 'react';
import CustomTooltip from 'components/@extended/Tooltip';
import { Global, Lock } from 'iconsax-react';
import { Stack } from '@mui/material';

const PublicPrivateCell = ({ value }) => {
  return value == 0 ? (
    <CustomTooltip style={{ flexGrow: 0 }} arrow title="Herkese Açık" color="success">
      <Stack alignItems="center">
        <Global />
      </Stack>
    </CustomTooltip>
  ) : (
    <CustomTooltip style={{ flexGrow: 0 }} arrow title="Özel" color="error">
      <Stack alignItems="center">
        <Lock />
      </Stack>
    </CustomTooltip>
  );
};

export default PublicPrivateCell;
