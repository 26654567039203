import { useQuery, useQueryClient } from 'react-query';
import axios from 'utils/axios2';

const fetchMovementPanelData = ({ queryKey }) => {
  const table_name = queryKey[1];
  const object_id = queryKey[2];
  const nano_id = queryKey[3]; // Optional parameter

  let url = `/api/movement_panel?table_name=${table_name}&object_id=${object_id}`;
  if (nano_id) {
    url += `&nano_id=${nano_id}`;
  }

  return axios.get(url);
};

export const useMovementPanelData = (table_name, object_id, enabled, nano_id) => {
  const queryClient = useQueryClient();
  const isEnabled = enabled && table_name !== undefined && object_id !== undefined;

  return useQuery(['movement_panel', table_name, object_id, nano_id], fetchMovementPanelData, {
    enabled: isEnabled,
    cacheTime: 0.2 * 60 * 1000,
    staleTime: 0.2 * 60 * 1000,
    onSuccess: () => {
      // Invalidate the notifications query key on successful data fetch
      queryClient.invalidateQueries('notifications');
    }
  });
};
