/* import { AES, enc, mode, pad } from 'crypto-js'; */
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router';

// material-ui
import { Button, Grid, InputLabel, List, ListItemText, MenuItem, Select, Stack, Typography, TextField } from '@mui/material';

// project-imports
import { useCreateOffer, useCreateOfferWithToken, useOfferForm } from 'hooks/offers/useOffersData';
import MainCard from 'components/MainCard';
import OfferedProductsTable from 'sections/tables/react-table/OfferedProductsTable';
import ExpenseTable from 'sections/tables/react-table/ExpenseTable';
import ProjectCell from 'components/data/ProjectCell';
import CompanyCell from 'components/data/CompanyCell';
import ChiefCell from 'components/data/ChiefCell';
import { paymentTypes } from 'data/paymentTypes';
import Loader from 'components/Loader';

// third-party
import * as yup from 'yup';
import { FormikProvider, useFormik } from 'formik';
import Lottie from 'lottie-react';
import animationData from '../../../assets/lottie/offer-success.json';
import animationDataForApproved from '../../../assets/lottie/approved.json';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import useAuth from 'hooks/useAuth';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import OfferHeader from './components/OfferHeader';

export const totalsText = {
  total_price: 'Toplam Mal Hizmet Bedeli',
  total_discount: 'Toplam İskonto',
  total_vat: 'Toplam KDV',
  total_tevkifat: 'Toplam Tevkifat',
  grand_total: 'Vergiler Dahil Toplam Tutar',
  net_total: 'Net Ödenecek Tutar'
};

export const offerValidation = yup.object().shape({
  payment_type: yup.string().required('Ödeme tipi gereklidir'),
  description: yup.string().max(255, 'Açıklama en fazla 255 karakter olabilir'),
  conditions: yup.string(),
  expenses: yup.array().of(
    yup
      .object()
      .shape({
        type: yup.string().min(1, 'Tip Seçiniz').required('Tip seçimi gereklidir'),
        description: yup.string().max(255, 'Açıklama en fazla 255 karakter olabilir'),
        price: yup.number().required('Fiyat gereklidir').min(0.01, "Fiyat 0'dan büyük olmalıdır"),
        currency_code: yup.string().required('Para birimi gereklidir').oneOf(['TRY', 'USD', 'EUR'], 'Geçersiz para birimi'),
        discount_type: yup.string().required('İskonto tipi gereklidir'),
        discount: yup
          .number()
          .required('İskonto gereklidir')
          .min(0, 'İskonto 0 dan büyük olmalıdır')
          .test('maxDiscount', 'İskonto geçersiz', function (value) {
            const discountType = this.resolve(yup.ref('discount_type'));
            const price = this.resolve(yup.ref('price'));
            const currency_code = this.resolve(yup.ref('currency_code'));

            if (discountType === 'P' && value > 100) {
              return this.createError({ message: 'İskonto 100% den büyük olamaz' });
            } else if (discountType === 'V' && value > price) {
              return this.createError({ message: `İskonto toplam fiyattan (${currency_code} ${price}) büyük olamaz` });
            }
            return true;
          }),
        vat: yup
          .number()
          .required('KDV gereklidir')
          .min(0, 'KDV 0% veya daha büyük olmalıdır')
          .max(100, 'KDV 100% veya daha küçük olmalıdır'),
        tevkifat: yup
          .number()
          .required('Tevkifat gereklidir')
          .min(0, 'Tevkifat 0% veya daha büyük olmalıdır')
          .max(100, 'Tevkifat 100% veya daha küçük olmalıdır')
      })
      .default([])
  )
});

export function offer_calculation(offered_products, expenses, exchangeRates) {
  let total_price = 0;
  let total_discount = 0;
  let total_vat = 0;
  let total_tevkifat = 0;
  let grand_total = 0;
  let net_total = 0;

  offered_products.forEach((product) => {
    let product_price = (product.price ?? 0) * product?.offered_amount;
    let product_discount =
      product.discount_type === 'P' ? (parseFloat(product.discount) * parseFloat(product_price)) / 100 : parseFloat(product.discount);
    let product_vat = ((product_price - product_discount) * parseFloat(product.vat)) / 100;
    let product_tevkifat = (product_vat * parseFloat(product.tevkifat)) / 100;

    if (product.currency_code !== 'TRY') {
      const currencyRate = exchangeRates[product?.currency_code] || { buy_price: 1 };
      product_price *= currencyRate.buy_price;
      product_discount *= currencyRate.buy_price;
      product_vat *= currencyRate.buy_price;
      product_tevkifat *= currencyRate.buy_price;
    }
    total_price += product_price;
    total_discount += product_discount;
    total_vat += product_vat;
    total_tevkifat += product_tevkifat;
  });

  expenses.forEach((expense) => {
    let expense_price = parseFloat(expense.price);
    let expense_discount = expense.discount_type === 'P' ? (expense.discount * expense_price) / 100 : parseFloat(expense.discount);
    let expense_vat = ((expense_price - expense_discount) * expense.vat) / 100;
    let expense_tevkifat = (expense_vat * expense.tevkifat) / 100;
    if (expense.currency_code !== 'TRY') {
      const currencyRate = exchangeRates[expense?.currency_code] || { buy_price: 1 };
      expense_price *= currencyRate.buy_price;
      expense_discount *= currencyRate.buy_price;
      expense_vat *= currencyRate.buy_price;
      expense_tevkifat *= currencyRate.buy_price;
    }
    total_price += expense_price;
    total_discount += expense_discount;
    total_vat += expense_vat;
    total_tevkifat += expense_tevkifat;
  });

  grand_total = total_price - total_discount + total_vat;
  net_total = grand_total - total_tevkifat;
  return { total_price, total_discount, total_vat, grand_total, total_tevkifat, net_total };
}

const CreateOffer = () => {
  const { user } = useAuth();
  const isLoggedIn = user != null;
  const { offer_nanoid } = useParams();

  const navigate = useNavigate();
  const { data: offerForm, isLoading: formLoading } = useOfferForm(offer_nanoid);
  const chief = offerForm?.data?.chief;

  const existingOffer = offerForm?.data?.offer;

  const [requestedProducts, setRequestedProducts] = useState(offerForm?.data?.requestedProducts ?? offerForm?.data?.offered_products ?? []);

  const [offeredProducts, setOfferedProducts] = useState([]);
  const method = offerForm?.method;
  const { mutate: createOfferWithToken, data: createWithTokenResponse } = useCreateOfferWithToken();
  const { mutate: createOffer, data: createResponse } = useCreateOffer();
  const [totals, setTotals] = useState({
    total_price: 0,
    total_discount: 0,
    total_vat: 0,
    total_tevkifat: 0,
    grand_total: 0,
    net_total: 0
  });
  const [formikErrors, setFormikErrors] = useState({});

  const formik = useFormik({
    initialValues: {
      payment_type: existingOffer?.payment_type || '',
      description: existingOffer?.description || '',
      expenses: existingOffer?.expenses || [],
      conditions: existingOffer?.conditions || ''
    },
    enableReinitialize: true,
    validationSchema: offerValidation,
    onSubmit: (values) => {
      const payload = {
        nano_id: existingOffer?.nano_id ?? offer_nanoid,
        payment_type: values.payment_type,
        description: values.description,
        expenses: values.expenses,
        conditions: values.conditions,
        offered_products: offeredProducts
      };
      if (method == 'manual' || isLoggedIn) {
        createOffer(payload);
      } else {
        createOfferWithToken(payload);
      }
    }
  });

  useEffect(() => {
    offerForm?.data?.requestedProducts && setRequestedProducts(offerForm?.data?.requestedProducts ?? []);
  }, [offerForm?.data?.requestedProducts]);

  useEffect(() => {
    if (offerForm?.data?.offered_products) {
      /* setRequestedProducts(offerForm?.data?.offered_products || []); */
      setOfferedProducts(offerForm?.data?.offered_products || []);
    }
  }, [offerForm?.data?.offered_products]);

  useEffect(() => {
    const totals = offer_calculation(offeredProducts, formik.values.expenses, offerForm?.data?.exchangeRates);
    setTotals(totals);
  }, [offeredProducts, formik.values.expenses]);

  useEffect(() => {
    if (offerForm?.message === 'This offer token is already used to create an offer') {
      if (method === 'mail') {
        /* navigate('/offer-exists'); */
      } else if (method === 'manual') {
        const existingOffer = offerForm?.data?.offer;
        console.log('existingOffer', existingOffer);
      }
    } else if (offerForm?.message === 'This offer token is not valid') {
      // remove the last path from the url
      const url = window.location.href;
      const urlParts = url.split('/');
      urlParts.pop();
      const newUrl = urlParts.join('/');
      navigate(newUrl);
    }
  }, [offerForm]);

  useEffect(() => {
    if (createWithTokenResponse?.success) {
      // do nothing
    }
  }, [createWithTokenResponse]);

  useEffect(() => {
    if (createResponse?.success) {
      navigate(`/offers/${createResponse?.insert_id}`);
    }
  }, [createResponse]);

  const urlIsCreateOffer = window.location.href.includes('create-offer');
  const urlIsFillOffer = window.location.href.includes('fill-offer') || window.location.href.includes('teklif');
  /* const urlIsEditOffer = window.location.href.includes('edit-offer'); */

  const renderForm = urlIsCreateOffer ? (existingOffer?.id == null ? true : false) : true;
  useEffect(() => {
    if (existingOffer?.id != null && urlIsFillOffer && existingOffer?.remaining_revision > 0) {
      closeSnackbar('dismiss-offer-revision');
      enqueueSnackbar(`Bu teklif için ${existingOffer?.remaining_revision} revizyon hakkınız bulunmaktadır.`, {
        variant: 'secondary',
        key: 'offer-revision-count', // Use a consistent key to update the snackbar
        preventDuplicate: true,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center'
        },
        autoHideDuration: 1000 * 60 * 60 * 24,
        action: (key) => (
          <Stack direction={'row'} alignItems={'center'} spacing={1}>
            <Button
              variant="contained"
              color={'success'}
              onClick={() => {
                navigate(`/edit-offer/${existingOffer?.nano_id}`);
              }}
            >
              {urlIsFillOffer ? 'Teklifi Düzenle' : 'Düzenlemekten Vazgeç'}
            </Button>
            <Button
              variant="text"
              color="secondary"
              onClick={() => {
                closeSnackbar(key);
              }}
            >
              Kapat
            </Button>
          </Stack>
        )
      });

      // Optionally close the snackbar when navigating away
      return () => {
        closeSnackbar('offer-revision-count');
      };
    }
  }, [existingOffer, urlIsFillOffer, window.location.pathname, enqueueSnackbar, navigate]);

  console.log('existingOffer', existingOffer);
  const isApproved = existingOffer?.id && existingOffer?.approve_level_max == existingOffer?.approve_level;
  console.log(
    'approve_level_max',
    existingOffer?.approve_level_max,
    'approve_level',
    existingOffer?.approve_level,
    'isApproved',
    isApproved
  );
  console.log(offerForm?.data?.approve_level_max, offerForm?.data?.approve_level_max, 'isApproved: ', isApproved);

  const [isSupplierDrawerOpen, setIsSupplierDrawerOpen] = useState(false);
  const handleSupplierDrawerOpen = () => {
    setIsSupplierDrawerOpen(!isSupplierDrawerOpen);
  };

  if (formLoading) {
    return <Loader />;
  }

  return (
    <FormikProvider value={formik}>
      {!isLoggedIn && (
        <OfferHeader
          offerForm={offerForm}
          existingOffer={existingOffer}
          isSupplierDrawerOpen={isSupplierDrawerOpen}
          handleSupplierDrawerOpen={handleSupplierDrawerOpen}
        />
      )}

      {formLoading && <Loader />}
      <form onSubmit={formik.handleSubmit}>
        <Stack spacing={3} sx={{ mt: isLoggedIn ? 0 : 1 }}>
          {isLoggedIn && offerForm?.message === 'This offer token is already used to create an offer' /* method == 'manual'  */ && (
            <MainCard sx={{ flexGrow: 0 }} title="Teklifiniz Başarıyla Oluşturuldu!">
              <Stack direction="row" justifyContent={'flex-end'} spacing={2}>
                <Button onClick={() => navigate('/home')} variant="contained" color="secondary">
                  Anasayfaya Dön
                </Button>
                <Button onClick={() => navigate('/offers')} variant="contained" color="primary">
                  Tekliflere Git
                </Button>
                <Button onClick={() => navigate(`/offers/${existingOffer?.id}`)} variant="contained" color="success">
                  {`Teklif #${existingOffer?.id}`} {offerForm?.satisfy_from_quotation == 1 && ` (Anlaşmalı)`}
                </Button>
              </Stack>
            </MainCard>
          )}

          {renderForm && (
            <MainCard
              sx={{ pointer: 'none' }}
              title={existingOffer?.id == null ? `Talep #${offerForm?.data?.offer_request?.id}` : `Teklif #${existingOffer?.id}`}
            >
              <Grid container padding={2} spacing={4}>
                {existingOffer?.id != null &&
                  !urlIsCreateOffer &&
                  (isApproved ? (
                    <Grid item xs={12}>
                      <Stack direction="row" alignItems="center" justifyContent="center" spacing={4}>
                        <Typography sx={{ fontSize: 44, textAlign: 'center' }}>{'Teklifinizi Onayladık'}</Typography>
                        <div style={{ width: 180, height: 180 }}>
                          <Lottie animationData={animationDataForApproved} loop={true} />
                        </div>
                      </Stack>
                    </Grid>
                  ) : (
                    <Grid item xs={12}>
                      <Stack direction="row" alignItems="center" justifyContent="center" spacing={4}>
                        <Typography sx={{ fontSize: 44, textAlign: 'center' }}>{'Teklifinizi İnceliyoruz'}</Typography>
                        <div style={{ width: 180, height: 180 }}>
                          <Lottie animationData={animationData} loop={true} />
                        </div>
                      </Stack>
                    </Grid>
                  ))}
                <Grid item xs={12} lg={3}>
                  <Typography variant="h5">Proje</Typography>
                  <ProjectCell
                    project={offerForm?.data?.project || existingOffer?.project}
                    avatarProps={{ size: 'md' }}
                    textProps={{ primaryTypographyProps: { variant: 'h5' } }}
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Typography variant="h5">Talep Eden Firma</Typography>
                  <CompanyCell company={offerForm?.data?.demanding || existingOffer?.demanding} maxWidth={400} />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Typography variant="h5">Tedarikçi Firma</Typography>
                  <CompanyCell company={offerForm?.data?.supplier || existingOffer?.supplier} maxWidth={400} />
                </Grid>
                {chief && (
                  <Grid item xs={12} lg={3}>
                    <Typography variant="h5">Şantiye Şefi</Typography>
                    <ChiefCell value={chief} />
                  </Grid>
                )}

                <Grid item xs={12}>
                  <Stack spacing={2}>
                    <OfferedProductsTable
                      setFormikErrors={setFormikErrors}
                      data={existingOffer?.id == null ? requestedProducts : offeredProducts}
                      readOnly={existingOffer?.id != null}
                      exchangeRates={offerForm?.data?.exchangeRates}
                      setOfferedProducts={setOfferedProducts}
                    />

                    <InputLabel>Ekstra Giderler</InputLabel>

                    <ExpenseTable
                      exchangeRates={offerForm?.data?.exchangeRates}
                      readOnly={existingOffer?.id != undefined}
                      expenses={formik.values.expenses}
                      values={formik.values}
                      handleChange={formik.handleChange}
                      handleBlur={formik.handleBlur}
                      touched={formik.touched}
                      errors={formik.errors}
                    />
                    <Grid container spacing={2}>
                      <Grid item xs={12} lg={3}>
                        <InputLabel>Ödeme Tipi</InputLabel>

                        <Stack direction="row" justifyContent={'flex-end'} spacing={2}>
                          <Select
                            id="payment_type"
                            name="payment_type"
                            data-cy="payment-type-select"
                            placeholder="Ödeme Tipi Seçiniz"
                            disabled={existingOffer?.id != null}
                            value={formik.values.payment_type}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.payment_type && Boolean(formik.errors.payment_type)}
                            fullWidth
                          >
                            {paymentTypes.map((type) => (
                              <MenuItem id={type} key={type.value} value={type.name}>
                                {type.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} lg={9}>
                        <InputLabel>Açıklama</InputLabel>
                        <Stack direction="row" justifyContent={'flex-end'} spacing={2}>
                          <TextField
                            disabled={existingOffer?.id != null}
                            name="description"
                            placeholder="Ör: Vade 30 gündür"
                            value={formik.values.description}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.description && Boolean(formik.errors.description)}
                            fullWidth
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={12} lg={12}>
                        <InputLabel>Ödeme Koşulları</InputLabel>
                        {existingOffer?.id == null ? (
                          <ReactQuill
                            value={formik.values.conditions}
                            onChange={(e) => {
                              formik.setFieldValue('conditions', e);
                            }}
                          />
                        ) : (
                          <Typography
                            mt={3}
                            component="div"
                            dangerouslySetInnerHTML={{ __html: existingOffer?.conditions }}
                            sx={{
                              wordBreak: 'break-word', // Ensures long words break to fit the container
                              whiteSpace: 'normal', // Ensures text wraps normally
                              overflowWrap: 'break-word' // Ensures wrapping for long strings without spaces
                            }}
                          />
                        )}
                      </Grid>
                    </Grid>

                    <Stack direction="row" justifyContent={'flex-end'} spacing={2}>
                      <List>
                        {Object.keys(totals).map((key) => (
                          <ListItemText
                            primaryTypographyProps={{
                              variant: 'h4'
                            }}
                            key={key}
                            primary={
                              isNaN(totals[key])
                                ? '...'
                                : parseFloat(totals[key]).toLocaleString('tr-TR', {
                                    style: 'currency',
                                    currency: 'TRY'
                                  })
                            }
                            secondary={totalsText[key]}
                          />
                        ))}
                      </List>
                    </Stack>
                    <Stack direction="row" justifyContent={'flex-end'} spacing={2}>
                      <Button
                        id="submit-offer"
                        disabled={existingOffer?.id != null || Object.keys(formikErrors).length > 0}
                        type="submit"
                        variant="contained"
                        color="primary"
                      >
                        Teklifi Kaydet
                      </Button>
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
            </MainCard>
          )}
        </Stack>
      </form>
    </FormikProvider>
  );
};

export default CreateOffer;
