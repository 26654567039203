// material-ui
import { TextField, Autocomplete, ListItem } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ApproveCell from 'components/data/ApproveCell';

/* import { ShieldTick, ShieldCross } from 'iconsax-react';
 */
// ==============================|| ACCOUNT PROFILE - BASIC ||============================== //

const options = [
  { name: 'Onaysız', level: 0 },
  { name: '1. Kademe Onay', level: 1 },
  { name: '2. Kademe Onay', level: 2 },
  { name: '3. Kademe Onay', level: 3 }
];

function ApproveLevelSelect({ selectedApproveLevel, setSelectedApproveLevel, onChange }) {
  const theme = useTheme();

  return (
    <Autocomplete
      id="approve-level-select"
      value={selectedApproveLevel || null}
      options={options}
      getOptionLabel={(option) => option.name}
      onChange={(event, newValue) => {
        onChange != undefined ? onChange() : null;
        setSelectedApproveLevel(newValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Onay Seviyesi Ara"
          label=""
          sx={{ '& .MuiAutocomplete-input.Mui-disabled': { WebkitTextFillColor: theme.palette.text.primary } }}
        />
      )}
      renderOption={(props, option) => (
        <ListItem {...props}>
          <ApproveCell value={option.level} />
        </ListItem>
      )}
      /* noOptionsText="Proje bulunamadı" */
    />
  );
}

export default ApproveLevelSelect;
