import React from 'react';

// material-ui
import { Chip, Grid, Stack, Typography, Skeleton } from '@mui/material';

// project-imports
import MainCard from 'components/MainCard';

// assets
import cardBack from 'assets/images/widget/img-dropbox-bg.svg';

const NotificationCardSkeleton = () => {
  return (
    <MainCard
      boxShadow
      shadow={'3'}
      sx={{
        cursor: 'pointer',
        bgcolor: (theme) => (theme.palette.mode === 'dark' ? 'secondary.100' : 'secondary.200'),
        '&:hover': {
          bgcolor: (theme) => (theme.palette.mode === 'dark' ? 'secondary.200' : 'primary.lighter')
        },
        '&:after': {
          content: '""',
          background: `url("${cardBack}") 100% / cover no-repeat`,
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 0,
          opacity: (theme) => (theme.palette.mode === 'dark' ? 0.2 : 0)
        }
      }}
    >
      <Grid container spacing={0.5}>
        <Grid item xs={12}>
          <Stack direction="row" alignItems="center" justifyContent={'space-between'} spacing={1}>
            <Stack direction={'row'} alignItems="center" spacing={1}>
              <Skeleton variant="circular" width={32} height={32} />
              <Skeleton variant="text" width={60} />
            </Stack>
            <Chip sx={{ zIndex: 9 }} label={<Skeleton variant="text" width={40} />} size="small" variant={'filled'} />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Typography sx={{ zIndex: 9 }} textAlign={'left'} variant="subtitle1">
            <Skeleton variant="text" width="80%" />
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography sx={{ zIndex: 9 }} variant="body2" textAlign={'left'}>
            <Skeleton variant="text" width="50%" />
          </Typography>
        </Grid>
      </Grid>
    </MainCard>
  );
};

export default NotificationCardSkeleton;
