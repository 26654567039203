import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Grid, Stack, Typography } from '@mui/material';

// project-imports
import MainCard from 'components/MainCard';
import Dot from 'components/@extended/Dot';
import Avatar from 'components/@extended/Avatar';
import { ThemeMode } from 'config';
import { FormattedRelativeTime } from 'react-intl';

// ==============================|| KANBAN BOARD - ITEM COMMENT ||============================== //

const CommentCard = ({ comment }) => {
  const theme = useTheme();
  const user = comment.user;

  return (
    <MainCard
      content={false}
      sx={{
        background: theme.palette.mode === ThemeMode.DARK ? theme.palette.secondary[100] : theme.palette.secondary.lighter,
        p: 1.5,
        mt: 1.25
      }}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Grid container wrap="nowrap" alignItems="center" spacing={1}>
            <Grid item>
              <Avatar sx={{ width: 24, height: 24 }} size="sm" alt="User 1" src={process.env.REACT_APP_API_URL + user.avatar_url} />
            </Grid>
            <Grid item xs zeroMinWidth>
              <Grid container alignItems="center" spacing={1} justifyContent="space-between">
                <Grid item>
                  <Typography align="left" variant="subtitle1" component="div">
                    {user.name}
                  </Typography>
                </Grid>
                <Grid item>
                  <Stack direction="row" alignItems="center" spacing={0.5}>
                    <Dot size={6} sx={{ mt: -0.25 }} color="secondary" />
                    <Typography variant="caption" color="secondary">
                      <FormattedRelativeTime
                        value={(new Date(comment.timestamp).getTime() - new Date(Date.now()).getTime()) / 1000}
                        updateIntervalInSeconds={1}
                      />
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ '&.MuiGrid-root': { pt: 1.5 } }}>
          <Typography align="left" variant="body2">
            {comment?.content}
          </Typography>
        </Grid>
      </Grid>
    </MainCard>
  );
};

CommentCard.propTypes = {
  comment: PropTypes.object,
  user: PropTypes.object
};

export default CommentCard;
