// ==============================|| OVERRIDES - CARD CONTENT ||============================== //

export default function CardContent(theme) {
  return {
    MuiCardContent: {
      styleOverrides: {
        root: {
          [theme.breakpoints.down('sm')]: {
            padding: 12
          },
          padding: 24,
          '&:last-child': {
            paddingBottom: 24,
            [theme.breakpoints.down('sm')]: {
              paddingBottom: 12
            }
          }
        }
      }
    }
  };
}
