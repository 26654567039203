import { useEffect } from 'react';

import { enqueueSnackbar, closeSnackbar } from 'notistack';

const NoConnectionSnack = () => {
  useEffect(() => {
    const handleOnline = () => {
      closeSnackbar();
      setTimeout(() => {
        enqueueSnackbar('Bağlantı sağlandı!', {
          variant: 'success',
          preventDuplicate: true,
          autoHideDuration: 3000,
          anchorOrigin: { horizontal: 'center', vertical: 'bottom' }
        });
      }, 500);
    };

    const handleOffline = () => {
      enqueueSnackbar('İnternete bağlı değilsiniz', {
        variant: 'error',
        preventDuplicate: true,
        persist: true,
        anchorOrigin: { horizontal: 'center', vertical: 'bottom' }
      });
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, [closeSnackbar]);

  return null;
};

export default NoConnectionSnack;
