import React, { useState, useMemo, useEffect, useRef } from 'react';
import { Stack, Box, Typography, Grid, Chip, IconButton, ListItemText } from '@mui/material';

import MainCard from 'components/MainCard';
import UserCell from 'components/data/UserCell';
import RoleCell from 'components/data/RoleCell';
import { useAccessInfo } from 'hooks/access/useAccessData';
import LoadingCardContent from 'components/LoadingCardContent';
import { ArrowDown2 } from 'iconsax-react';

import menuItems from 'menu-items';
import { AllRoutes } from 'routes';

export const extractMenuItems = (items) => {
  const list = [];
  items.forEach((item) => {
    if (item.type == 'group') {
      list.push(...extractMenuItems(item.children));
      return;
    }
    list.push(item);
    if (item.children) {
      list.push(...extractMenuItems(item.children));
    }
  });
  return list;
};

export const extractItemsWithFullPath = (items, parentPath = '') => {
  const list = [];
  items.forEach((item) => {
    if (item.children) {
      list.push(...extractItemsWithFullPath(item.children, parentPath + item.path + '/'));
    } else {
      list.push({ ...item, fullPath: (parentPath + item.path).replaceAll('//', '/').replaceAll('//', '/') });
    }
  });
  return list;
};

const AccessInfoDisplay = ({ params, narrow }) => {
  const { data: accessInfoData, isLoading } = useAccessInfo(params);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const contentRef = useRef(null);

  const menuItemsList = useMemo(() => extractMenuItems(menuItems.items), []);
  const routesList = useMemo(() => extractItemsWithFullPath(AllRoutes), []);

  const ITEM = menuItemsList.find((item) => item.id == params.key) || routesList.find((item) => item.fullPath == params.key);

  const groupedData = accessInfoData?.groupped || [];

  useEffect(() => {
    const checkOverflow = () => {
      if (contentRef.current) {
        setIsOverflowing(contentRef.current.scrollHeight > contentRef.current.clientHeight);
      }
    };

    checkOverflow();
    window.addEventListener('resize', checkOverflow);

    return () => {
      window.removeEventListener('resize', checkOverflow);
    };
  }, []);

  const handleScrollToBottom = () => {
    if (contentRef.current) {
      contentRef.current.scrollTo({ top: contentRef.current.scrollHeight, behavior: 'smooth' });
    }
  };

  return (
    <MainCard
      subheader={'Erişimi Olan Kullanıcılar'}
      title={
        <Stack direction={'row'} flexGrow={1} justifyContent={'space-between'} alignItems={'center'}>
          {ITEM && ITEM.type && (
            <Stack direction={'row'} key={ITEM.id}>
              {ITEM.icon && <ITEM.icon style={{ marginRight: 8 }} />}
              <ListItemText /* secondary={ITEM.id} */>{ITEM.title}</ListItemText>
            </Stack>
          )}
          {/*  <Typography>{'Erişim Bilgileri'}</Typography> */}
          <Typography></Typography>
        </Stack>
      }
    >
      {isLoading && <LoadingCardContent />}

      <Box position="relative">
        <Box
          ref={contentRef}
          sx={{
            maxHeight: '700px',

            overflowY: 'auto',
            pr: 1,
            '&::-webkit-scrollbar': { display: 'none' },
            '&': { msOverflowStyle: 'none', scrollbarWidth: 'none' }
          }}
        >
          <Box sx={{ overflow: 'hidden' }}>
            {!isLoading && groupedData.length === 0 ? (
              <Chip label="Herkes" color="info" />
            ) : (
              groupedData.map((group) => (
                <Stack key={group.role_name} spacing={0.5} marginTop={4}>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <RoleCell value={{ name: group.role_name, color: group.role_color }} />
                    <Typography variant="h5" sx={{ color: group.role_color }}>
                      ({group.users.length})
                    </Typography>
                  </Stack>
                  <Grid container spacing={1}>
                    {group.users.map((user) => (
                      <Grid key={user.id} item xs={narrow ? 12 : 3}>
                        <UserCell key={user.id} user={user} avatarProps={{ size: 'md' }} />
                      </Grid>
                    ))}
                  </Grid>
                </Stack>
              ))
            )}
          </Box>
        </Box>
        {isOverflowing && (
          <IconButton
            sx={{
              position: 'absolute',
              bottom: 0,
              left: '50%',
              transform: 'translateX(-50%)',
              zIndex: 1
            }}
            onClick={handleScrollToBottom}
          >
            <ArrowDown2 />
          </IconButton>
        )}
      </Box>
    </MainCard>
  );
};

export default AccessInfoDisplay;
