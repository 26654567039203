import {
  Grid,
  Table,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  TextField,
  InputAdornment,
  Chip,
  Button,
  Select,
  MenuItem,
  FormControl,
  ListItemText,
  IconButton,
  Typography
} from '@mui/material';
import { NumberFormatCustom } from 'utils/utkuFunctions';
import { useFormik } from 'formik';
import * as yup from 'yup';
import CustomTooltip from 'components/@extended/Tooltip';
import { useEffect } from 'react';

const getValidationSchema = (index) => {
  return yup.object({
    [`product_id-${index}`]: yup.number().required('Ürün zorunludur'),
    [`quotation_amount-${index}`]: yup.number().min(0).required('Miktar zorunludur'),
    [`used_amount-${index}`]: yup
      .number()
      .min(0)
      .max(yup.ref(`quotation_amount-${index}`), 'Kullanılan miktar anlaşılan miktarı geçemez'),
    [`brand-${index}`]: yup.string().max(56, 'Marka 56 karakterden uzun olamaz'),
    [`model-${index}`]: yup.string().max(56, 'Model 56 karakterden uzun olamaz'),
    [`price-${index}`]: yup
      .number()
      .required('Birim Fiyat gereklidir')
      .test('minPrice', 'Fiyat 0 olamaz', function (value) {
        const currencyCode = this.resolve(yup.ref(`currency_code-${index}`));
        if (value == 0) {
          return true; // Allow 0 as "I don't have this item"
        }
        if (value < 0.01) {
          return this.createError({
            message: `Fiyat ${currencySymbol(currencyCode)}0.01'den küçük olamaz`
          });
        }
        return true;
      }),
    [`currency_code-${index}`]: yup.string().required('Para birimi gereklidir').oneOf(['TRY', 'USD', 'EUR'], 'Geçersiz para birimi'),
    [`discount-${index}`]: yup
      .number()
      .required('İskonto gereklidir')
      .min(0, 'İskonto 0 dan büyük olmalıdır')
      .test('maxDiscount', function (value) {
        const discountType = this.resolve(yup.ref(`discount_type-${index}`));
        const price = this.resolve(yup.ref(`price-${index}`));
        const amount = this.resolve(yup.ref(`amount-${index}`));
        const currencyCode = this.resolve(yup.ref(`currency_code-${index}`));
        if (discountType === 'P' && value > 100) {
          return this.createError({ message: 'İskonto 100% den büyük olamaz' });
        } else if (discountType === 'V' && value > price * amount) {
          return this.createError({
            message: `İskonto toplam fiyattan (${currencySymbol(currencyCode)}${(price * amount).toFixed(2)}) büyük olamaz`
          });
        }
        return true;
      }),
    [`discount_type-${index}`]: yup.string().required('İskonto tipi gereklidir'),
    [`vat-${index}`]: yup.number().required('KDV gereklidir'),
    [`tevkifat-${index}`]: yup
      .number()
      .required('Tevkifat gereklidir')
      .min(0, "Tevkifat 0'dan küçük olamaz")
      .max(100, "Tevkifat 100'den büyük olamaz")
  });
};

// Function to get the combined validation schema for all rows
const getCombinedValidationSchema = (data) => {
  const schemaFields = data.reduce((acc, row, index) => {
    const validationSchema = getValidationSchema(index);
    return { ...acc, ...validationSchema.fields };
  }, {});

  return yup.object().shape(schemaFields);
};

import { CurrencyChip, currencySymbol } from 'utils/currencyFunction';
import { Trash } from 'iconsax-react';
import { useExchangeRatesToday } from 'hooks/exchangeRates/useExchangeRatesData';
import Loader from 'components/Loader';

const ProductQuotasTable = ({ data, setData, setFormikErrors, readOnly, deleteRow }) => {
  const { data: exchangeRatesData, isLoading: exhangesLoading } = useExchangeRatesToday();
  const exchangeRates = exchangeRatesData?.data;
  const formik = useFormik({
    initialValues: data.reduce((acc, row, index) => {
      acc[`product_id-${index}`] = row?.product?.id || '';
      acc[`quotation_amount-${index}`] = row?.quotation_amount || '';
      acc[`used_amount-${index}`] = row?.used_amount || '0';
      acc[`delivered_amount-${index}`] = row?.delivered_amount || '0';
      acc[`brand-${index}`] = row?.brand || '';
      acc[`model-${index}`] = row?.model || '';
      acc[`price-${index}`] = row?.price || '0';
      acc[`currency_code-${index}`] = row?.currency_code || 'TRY';
      acc[`discount-${index}`] = row?.discount || '0';
      acc[`discount_type-${index}`] = row?.discount_type || 'P';
      acc[`vat-${index}`] = row?.vat || 20;
      acc[`tevkifat-${index}`] = row?.tevkifat || '0';
      return acc;
    }, {}),
    enableReinitialize: true,

    validationSchema: getCombinedValidationSchema(data),
    onSubmit: (values) => {
      // Handle form submission
      console.log('values', values);
    }
  });

  const validateAll = () => {
    if (readOnly) return;
    formik.validateForm().then((errors) => {
      console.log('errors', errors);
      formik.setTouched(
        Object.keys(errors).reduce((acc, key) => {
          acc[key] = true;
          return acc;
        }, {})
      );
    });
  };

  useEffect(() => {
    validateAll();
    setFormikErrors && setFormikErrors(formik.errors);
  }, [formik.errors]);

  useEffect(() => {
    // Map formik values back to the original data structure
    const updatedProducts = data.map((row, index) => {
      return {
        ...row,
        product_id: formik.values[`product_id-${index}`] || null,
        quotation_amount: formik.values[`quotation_amount-${index}`] || '',
        used_amount: formik.values[`used_amount-${index}`] || '',
        delivered_amount: formik.values[`delivered_amount-${index}`] || '',
        brand: formik.values[`brand-${index}`],
        model: formik.values[`model-${index}`],
        price: formik.values[`price-${index}`],
        currency_code: formik.values[`currency_code-${index}`],
        discount: formik.values[`discount-${index}`],
        discount_type: formik.values[`discount_type-${index}`],
        vat: formik.values[`vat-${index}`],
        tevkifat: formik.values[`tevkifat-${index}`]
      };
    });

    !readOnly && setData && setData(updatedProducts);
  }, [formik.values]);
  if (exhangesLoading) {
    return <Loader />;
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TableContainer sx={{ cursor: readOnly ? 'not-allowed' : 'pointer' }}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ minWidth: 100 }} align="left">
                    Ürün
                  </TableCell>
                  <TableCell sx={{ minWidth: 120 }} align="left">
                    Marka
                  </TableCell>
                  <TableCell sx={{ minWidth: 120 }} align="left">
                    Model
                  </TableCell>

                  <TableCell sx={{ minWidth: 160 }} align="left">
                    Anlaşılan Miktar
                  </TableCell>
                  {readOnly && (
                    <>
                      <TableCell sx={{ minWidth: 160 }} align="left">
                        Kullanılan Miktar
                      </TableCell>
                      <TableCell sx={{ minWidth: 160 }} align="left">
                        Teslim Edilen Miktar
                      </TableCell>
                    </>
                  )}

                  <TableCell sx={{ minWidth: readOnly ? 120 : 180 }} align="left">
                    BİRİm Fİyat
                  </TableCell>
                  <TableCell sx={{ minWidth: readOnly ? 80 : 140 }} align="left">
                    İskonto
                  </TableCell>
                  <TableCell sx={{ minWidth: readOnly ? 80 : 90 }} align="left">
                    KDV
                  </TableCell>
                  <TableCell sx={{ minWidth: readOnly ? 80 : 90 }} align="left">
                    TEVKİFAT
                  </TableCell>
                  <TableCell sx={{ minWidth: 180 }} align="right">
                    Toplam Fiyat
                  </TableCell>
                  {!readOnly && <TableCell align="right"></TableCell>}
                  {/* <TableCell sx={{ minWidth: 200 }} align="right">
                    Toplam İskonto
                  </TableCell>
                  <TableCell sx={{ minWidth: 200 }} align="right">
                    Toplam KDV
                  </TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row, index) => {
                  return (
                    <>
                      <TableRow hover key={row.id}>
                        <TableCell align="left" sx={{ p: 0.5 }}>
                          {row?.product?.name}
                        </TableCell>

                        <TableCell align="left" sx={{ p: 0.5 }}>
                          {readOnly ? (
                            <Typography>{formik.values[`brand-${index}`]}</Typography>
                          ) : (
                            <TextField
                              name={`brand-${index}`}
                              multiline
                              value={formik.values[`brand-${index}`]}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              error={formik.touched[`brand-${index}`] && Boolean(formik.errors[`brand-${index}`])}
                              helperText={formik.touched[`brand-${index}`] && formik.errors[`brand-${index}`]}
                              inputProps={{ readOnly }}
                            />
                          )}
                        </TableCell>
                        <TableCell align="left" sx={{ p: 0.5 }}>
                          {readOnly ? (
                            <Typography>{formik.values[`model-${index}`]}</Typography>
                          ) : (
                            <TextField
                              name={`model-${index}`}
                              multiline
                              value={formik.values[`model-${index}`]}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              error={formik.touched[`model-${index}`] && Boolean(formik.errors[`model-${index}`])}
                              helperText={formik.touched[`model-${index}`] && formik.errors[`model-${index}`]}
                              inputProps={{ readOnly }}
                            />
                          )}
                        </TableCell>
                        <TableCell align="left">
                          {readOnly ? (
                            <Typography>
                              {formik.values[`quotation_amount-${index}`]} {row?.product?.unit}
                            </Typography>
                          ) : (
                            <TextField
                              data-cy={`quotation_amount-${index}`}
                              name={`quotation_amount-${index}`}
                              value={formik.values[`quotation_amount-${index}`]}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              error={formik.touched[`quotation_amount-${index}`] && Boolean(formik.errors[`quotation_amount-${index}`])}
                              helperText={formik.touched[`quotation_amount-${index}`] && formik.errors[`quotation_amount-${index}`]}
                              inputProps={{ readOnly }}
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                                endAdornment: <InputAdornment>{row?.product?.unit}</InputAdornment>
                              }}
                            />
                          )}
                        </TableCell>
                        {readOnly && (
                          <>
                            <TableCell align="left">
                              <Typography>
                                {formik.values[`used_amount-${index}`]} {row?.product?.unit}
                              </Typography>
                            </TableCell>
                            <TableCell align="left">
                              <Typography>
                                {formik.values[`delivered_amount-${index}`]} {row?.product?.unit}
                              </Typography>
                            </TableCell>
                          </>
                        )}

                        <TableCell align="left" sx={{ p: 0.5 }}>
                          {readOnly ? (
                            <Typography>
                              {currencySymbol(formik.values[`currency_code-${index}`])}
                              {formik.values[`price-${index}`]}
                            </Typography>
                          ) : (
                            <TextField
                              data-cy={`price-${index}`}
                              name={`price-${index}`}
                              value={formik.values[`price-${index}`]}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              error={formik.touched[`price-${index}`] && Boolean(formik.errors[`price-${index}`])}
                              helperText={formik.touched[`price-${index}`] && formik.errors[`price-${index}`]}
                              inputProps={{ readOnly }}
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <FormControl variant="standard">
                                      <Select
                                        aria-label="Select currency"
                                        value={formik.values[`currency_code-${index}`] || 'TRY'}
                                        onChange={(e) => formik.setFieldValue(`currency_code-${index}`, e.target.value)}
                                        displayEmpty
                                        disableUnderline
                                        inputProps={{ 'aria-label': 'Select currency', readOnly }}
                                        renderValue={(value) => CurrencyChip(value)}
                                        sx={{ minWidth: 20 }}
                                      >
                                        <MenuItem value="" disabled>
                                          <em>Para Birimi</em>
                                        </MenuItem>
                                        <MenuItem value="TRY">
                                          <ListItemText primary="₺" secondary="TRY" />
                                        </MenuItem>
                                        <MenuItem value="USD">
                                          <ListItemText
                                            primary="$"
                                            secondary={`USD (${exchangeRates?.USD?.buy_price?.toLocaleString('tr-TR', {
                                              style: 'currency',
                                              currency: 'TRY'
                                            })})`}
                                          />
                                        </MenuItem>
                                        <MenuItem value="EUR">
                                          <ListItemText
                                            primary="€"
                                            secondary={`EUR (${exchangeRates?.EUR?.buy_price?.toLocaleString('tr-TR', {
                                              style: 'currency',
                                              currency: 'TRY'
                                            })})`}
                                          />
                                        </MenuItem>
                                      </Select>
                                    </FormControl>
                                  </InputAdornment>
                                )
                              }}
                            />
                          )}
                        </TableCell>
                        <TableCell align="left" sx={{ p: 0.5 }}>
                          {readOnly ? (
                            <Typography>
                              {formik.values[`discount-${index}`]}{' '}
                              {formik.values[`discount_type-${index}`] === 'P'
                                ? '%'
                                : currencySymbol(formik.values[`currency_code-${index}`])}
                            </Typography>
                          ) : (
                            <TextField
                              name={`discount-${index}`}
                              value={formik.values[`discount-${index}`]}
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              error={formik.touched[`discount-${index}`] && Boolean(formik.errors[`discount-${index}`])}
                              helperText={formik.touched[`discount-${index}`] && formik.errors[`discount-${index}`]}
                              inputProps={{ readOnly }}
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                                endAdornment: (
                                  <InputAdornment>
                                    <CustomTooltip
                                      color={formik.values[`discount_type-${index}`] === 'P' ? 'primary' : 'success'}
                                      title="Yüzdelik (%) veya Değer ile giriş yapabilirsiniz"
                                      arrow
                                    >
                                      <Chip
                                        variant="outlined"
                                        label={
                                          formik.values[`discount_type-${index}`] === 'P'
                                            ? '%'
                                            : currencySymbol(formik.values[`currency_code-${index}`])
                                        }
                                        color={formik.values[`discount_type-${index}`] === 'P' ? 'primary' : 'success'}
                                        onClick={() => {
                                          if (!readOnly) {
                                            formik.setFieldValue(
                                              `discount_type-${index}`,
                                              formik.values[`discount_type-${index}`] === 'P' ? 'V' : 'P'
                                            );
                                          }
                                        }}
                                      />
                                    </CustomTooltip>
                                  </InputAdornment>
                                )
                              }}
                            />
                          )}
                        </TableCell>

                        <TableCell align="left" sx={{ p: 0.5 }}>
                          {readOnly ? (
                            <Typography>{formik.values[`vat-${index}`]} %</Typography>
                          ) : (
                            <TextField
                              name={`vat-${index}`}
                              value={formik.values[`vat-${index}`]}
                              onChange={formik.handleChange}
                              inputProps={{ readOnly }}
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                                endAdornment: <InputAdornment>%</InputAdornment>
                              }}
                              onBlur={formik.handleBlur}
                              helperText={formik.touched[`vat-${index}`] && formik.errors[`vat-${index}`]}
                              error={formik.touched[`vat-${index}`] && Boolean(formik.errors[`vat-${index}`])}
                            />
                          )}
                        </TableCell>
                        <TableCell align="left" sx={{ p: 0.5 }}>
                          {readOnly ? (
                            <Typography>{formik.values[`tevkifat-${index}`]}</Typography>
                          ) : (
                            <TextField
                              name={`tevkifat-${index}`}
                              value={formik.values[`tevkifat-${index}`]}
                              onChange={formik.handleChange}
                              inputProps={{ readOnly }}
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                                endAdornment: <InputAdornment>%</InputAdornment>
                              }}
                              onBlur={formik.handleBlur}
                              helperText={formik.touched[`tevkifat-${index}`] && formik.errors[`tevkifat-${index}`]}
                              error={formik.touched[`tevkifat-${index}`] && Boolean(formik.errors[`tevkifat-${index}`])}
                            />
                          )}
                        </TableCell>

                        <TableCell align="right" sx={{ p: 1 }}>
                          {/* Conditional TextField */}
                          {formik.values[`currency_code-${index}`] === 'TRY' ? (
                            // If the currency is TRY, just show the TRY value
                            <TextField
                              value={formik.values[`price-${index}`] * formik.values[`quotation_amount-${index}`]}
                              inputProps={{ readOnly: true }}
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                                startAdornment: <InputAdornment position="start">₺</InputAdornment>
                              }}
                              variant="standard"
                              sx={{ mt: 1 }}
                            />
                          ) : (
                            <TextField
                              value={
                                `≈ ${currencySymbol('TRY')}` +
                                (
                                  formik.values[`price-${index}`] *
                                  row.quotation_amount *
                                  parseFloat(exchangeRates[formik.values[`currency_code-${index}`]]?.buy_price)
                                ).toFixed(2)
                              }
                              inputProps={{ readOnly: true }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    {currencySymbol(formik.values[`currency_code-${index}`])}
                                    {parseFloat(formik.values[`price-${index}`] * row.quotation_amount).toFixed(2)}
                                  </InputAdornment>
                                ),
                                readOnly: true
                              }}
                              variant="standard"
                              sx={{ mt: 1 }}
                            />
                          )}
                        </TableCell>
                        {!readOnly && (
                          <TableCell>
                            <IconButton
                              onClick={() => {
                                deleteRow(row);
                              }}
                            >
                              <Trash />
                            </IconButton>
                          </TableCell>
                        )}
                      </TableRow>
                    </>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      {1 === '2' && <Button onClick={() => validateAll()}>Validate</Button>}
    </form>
  );
};

export default ProductQuotasTable;
