import PropTypes from 'prop-types';
import { ListItem, ListItemText, Stack, Chip, Typography } from '@mui/material';
import { FormattedDate } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import CustomTooltip from 'components/@extended/Tooltip';
function OfferItem({ offer }) {
  const navigate = useNavigate();
  return (
    <ListItem
      divider
      sx={{
        cursor: 'pointer',
        '&:hover': {
          bgcolor: 'background.default'
        },
        '&:active': {
          bgcolor: 'background.default'
        }
      }}
      onClick={() => navigate(`/teklif/${offer.nano_id}`)}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ width: '100%' }}>
        <Stack>
          <ListItemText
            primary={`Teklif #${offer.id}`}
            secondary={<FormattedDate value={offer.created_at} year="numeric" month="long" day="2-digit" />}
          />
          <Typography variant="body2" color="text.secondary">
            {parseFloat(offer.net_total).toLocaleString('tr-TR', {
              style: 'currency',
              currency: offer.currency_code || 'TRY'
            })}
          </Typography>
        </Stack>
        <Stack>
          {offer.order_id ? (
            <CustomTooltip
              arrow
              color="success"
              placement="right"
              title={`Teklifiniz satınalma ekibimiz tarafından onaylanarak siparişe dönüştürülmüştür`}
            >
              <Chip label={`Sipariş #${offer.order_id}`} color="success" variant="contained" />
            </CustomTooltip>
          ) : offer.remaining_revision > 0 ? (
            <CustomTooltip
              arrow
              color="info"
              placement="right"
              title={`Teklifinizde düzenleme yapabilirsiniz. Kalan revizyon hakkınız: ${offer.remaining_revision}`}
            >
              <Chip label={`Revizyon Hakkı: ${offer.remaining_revision}`} color="info" variant="contained" />
            </CustomTooltip>
          ) : (
            <CustomTooltip
              arrow
              color="warning"
              placement="right"
              title={`Teklifiniz başarıyla sistemimize iletildi. Satınalma ekibi tarafından inceleniyor.`}
            >
              <Chip label="Onay Bekleniyor" color="warning" variant="contained" />
            </CustomTooltip>
          )}
        </Stack>
      </Stack>
    </ListItem>
  );
}

OfferItem.propTypes = {
  offer: PropTypes.shape({
    id: PropTypes.number.isRequired,
    created_at: PropTypes.string.isRequired,
    order_id: PropTypes.number // Optional order_id
  }).isRequired
};

export default OfferItem;
