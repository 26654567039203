import React from 'react';
import { Chip } from '@mui/material';

export const currencySymbol = (currencyCode) => {
  switch (currencyCode) {
    case 'TRY':
      return '₺';
    case 'USD':
      return '$';
    case 'EUR':
      return '€';
    default:
      return '?';
  }
};

const currencyName = (currencyCode) => {
  switch (currencyCode) {
    case 'TRY':
      return 'Türk Lirası';
    case 'USD':
      return 'Amerikan Doları';
    case 'EUR':
      return 'Euro';
    default:
      return '?';
  }
};

export const CurrencyChip = (currencyCode) => {
  switch (currencyCode) {
    case 'TRY':
      return <Chip label={currencySymbol(currencyCode)} color="primary" />;
    case 'USD':
      return <Chip label={currencySymbol(currencyCode)} color="success" />;
    case 'EUR':
      return <Chip label={currencySymbol(currencyCode)} color="info" />;
    default:
      return <Chip label={currencySymbol(currencyCode)} />;
  }
};

export const LongCurrencyChip = (currencyCode) => {
  const labelText = `${currencySymbol(currencyCode)} ${currencyName(currencyCode)}`;
  switch (currencyCode) {
    case 'TRY':
      return <Chip label={labelText} color="primary" />;
    case 'USD':
      return <Chip label={labelText} color="success" />;
    case 'EUR':
      return <Chip label={labelText} color="info" />;
    default:
      return <Chip label={labelText} />;
  }
};
