import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { useMediaQuery, Box, Container, Toolbar, Stack } from '@mui/material';

// project-imports
import Drawer from './Drawer';
import Header from './Header';
import Footer from './Footer';
import HorizontalBar from './Drawer/HorizontalBar';
import Breadcrumbs from 'components/@extended/Breadcrumbs';

import { DRAWER_WIDTH } from 'config';
import navigation from 'menu-items';
import useConfig from 'hooks/useConfig';
import { dispatch } from 'store';
import { openDrawer } from 'store/reducers/menu';
import { MenuOrientation } from 'config';
import IconButton from 'components/@extended/IconButton';
import { ArrowLeft2 } from 'iconsax-react';
import { motion } from 'framer-motion';
import CustomTooltip from 'components/@extended/Tooltip';

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const theme = useTheme();
  const downXL = useMediaQuery(theme.breakpoints.down('xl'));
  const downLG = useMediaQuery(theme.breakpoints.down('lg'));

  const { container, miniDrawer, menuOrientation } = useConfig();

  const isHorizontal = menuOrientation === MenuOrientation.HORIZONTAL && !downLG;

  // set media wise responsive drawer
  useEffect(() => {
    if (!miniDrawer) {
      dispatch(openDrawer(!downXL));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downXL]);

  const handleBackButtonClick = () => {
    history.back();
  };

  const [showBackButton, setShowBackButton] = useState(false);

  // Track mouse position to show/hide back button
  const handleMouseMove = (event) => {
    const thresholdY = 200; // Threshold in pixels from the top
    const thresholdX = 400; // Threshold in pixels from the left
    if (thresholdY / 2 < event.clientY && event.clientY < thresholdY && thresholdX / 4 < event.clientX && event.clientX < thresholdX) {
      setShowBackButton(true);
    } else {
      setShowBackButton(false);
    }
  };

  return (
    <Box sx={{ display: 'flex', width: '100%' }} onMouseMove={handleMouseMove}>
      <Header />
      {!isHorizontal ? <Drawer /> : <HorizontalBar />}

      <Box component="main" sx={{ width: `calc(100% - ${DRAWER_WIDTH}px)`, flexGrow: 1, p: { xs: 0, md: 3 } }}>
        <Toolbar sx={{ mt: isHorizontal ? 8 : 'inherit', mb: isHorizontal ? 2 : 'inherit' }} />

        <Container
          maxWidth={container ? 'xl' : false}
          sx={{
            xs: 0,
            ...(container && { px: { xs: 0, md: 2 } }),
            position: 'relative',
            minHeight: 'calc(100vh - 110px)',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <motion.div layout transition={{ duration: 0.2, ease: 'easeIn' }}>
            <Stack mb={2} direction="row" spacing={1} alignItems="center">
              {showBackButton && (
                <motion.div
                  key="back-button"
                  layout
                  initial={{ opacity: 0, rotateY: '-90deg', x: -20, scale: 0 }} // Starting position and opacity
                  animate={{ opacity: 1, rotateY: '0deg', x: 0, scale: 1 }} // Animate to visible
                  exit={{ opacity: 0, rotateY: '-90deg', x: -20, scale: 0 }} // Animate exit smoothly
                  transition={{ duration: 0.2, ease: 'easeIn' }} // Duration of animation
                >
                  <CustomTooltip title="Geri Git" placement="bottom" arrow>
                    <IconButton shape="rounded" variant="contained" color="secondary" onClick={handleBackButtonClick} size="large">
                      <ArrowLeft2 fontSize={'32'} />
                    </IconButton>
                  </CustomTooltip>
                </motion.div>
              )}

              <motion.div key="breadcrumbs" layout transition={{ duration: 0.2, ease: 'easeIn' }}>
                <Breadcrumbs navigation={navigation} title titleBottom card={false} divider={false} icons={true} />
              </motion.div>
            </Stack>
          </motion.div>
          <motion.div key="outlettt" layout transition={{ duration: 0.2, ease: 'easeIn' }}>
            <Outlet />
          </motion.div>
          <Footer />
        </Container>
      </Box>
    </Box>
  );
};

export default MainLayout;
