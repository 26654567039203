import { /* useQuery, */ useQueryClient, useMutation } from 'react-query';
import axios from 'utils/axios2';

const login = (credentials) => {
  return axios.post('/api/auth/login', credentials);
};

export const useLogin = () => {
  const queryClient = useQueryClient();

  return useMutation(login, {
    onSuccess: () => {
      queryClient.invalidateQueries(true);
    }
  });
};

const logout = () => {
  return axios.post('/api/auth/logout');
};

export const useLogout = () => {
  const queryClient = useQueryClient();

  return useMutation(logout, {
    onSuccess: () => {
      queryClient.invalidateQueries('user');
      queryClient.invalidateQueries('myself');
      queryClient.invalidateQueries('access-menu');
      queryClient.invalidateQueries('access-url');
      queryClient.invalidateQueries('notifications');
    }
  });
};
const sendOtp = (body) => {
  return axios.post('/api/otp/generate', body);
};

export const useSendOtp = () => {
  return useMutation(sendOtp);
};

const verifyOtp = (body) => {
  console.log('verifyOtp body', body);
  return axios.post('/api/otp/verify', body);
};

export const useVerifyOtp = () => {
  return useMutation(verifyOtp);
};
