import PropTypes from 'prop-types';

// material-ui
import { Typography, Stack, CardMedia } from '@mui/material';

// project-import
import { DropzopType } from 'config';

// assets
import { Camera } from 'iconsax-react';
/* import UploadCover from '/upload.svg'; */

// ==============================|| UPLOAD - PLACEHOLDER ||============================== //

export default function PlaceholderContent({ type }) {
  return (
    <>
      {type !== DropzopType.standard && (
        <Stack
          spacing={3}
          alignItems="center"
          justifyContent="center"
          direction={{ xs: 'column', md: 'row' }}
          sx={{ width: 1, textAlign: { xs: 'center', md: 'left' } }}
        >
          <CardMedia component="img" image={'/upload.svg'} sx={{ width: 100 }} />
          <Stack sx={{ p: 0 }} spacing={1}>
            <Typography variant="h5">Dosya sürükleyin veya seçin</Typography>

            <Typography color="secondary">
              Dosyaları buraya sürükleyip bırakın veya cihazdan&nbsp;
              <Typography component="span" color="primary" sx={{ textDecoration: 'underline' }}>
                seçin
              </Typography>
            </Typography>
          </Stack>
        </Stack>
      )}
      {type === DropzopType.standard && (
        <Stack alignItems="center" justifyContent="center" sx={{ height: 1 }}>
          <Camera style={{ fontSize: '32px' }} />
        </Stack>
      )}
    </>
  );
}

PlaceholderContent.propTypes = {
  type: PropTypes.string
};
