import { lazy } from 'react';
import Loadable from 'components/Loadable';
const MaintenanceError = Loadable(lazy(() => import('pages/maintenance/error/404')));
const AuthError = Loadable(lazy(() => import('pages/maintenance/error/401')));
const MaintenanceError500 = Loadable(lazy(() => import('pages/maintenance/error/500')));
const MaintenanceUnderConstruction = Loadable(lazy(() => import('pages/maintenance/under-construction/under-construction')));
const MaintenanceUnderConstruction2 = Loadable(lazy(() => import('pages/maintenance/under-construction/under-construction2')));
const MaintenanceComingSoon = Loadable(lazy(() => import('pages/maintenance/coming-soon/coming-soon')));

// ==============================|| SPECIAL ROUTES ||============================== //

const SpecialRoutes = {
  path: '/',
  children: [
    {
      path: '401',
      element: <AuthError />
    },
    {
      path: '404',
      element: <MaintenanceError />
    },
    {
      path: '500',
      element: <MaintenanceError500 />
    },
    {
      path: 'under-construction',
      element: <MaintenanceUnderConstruction />
    },
    {
      path: 'under-construction2',
      element: <MaintenanceUnderConstruction2 />
    },
    {
      path: 'coming-soon',
      element: <MaintenanceComingSoon />
    }
  ]
};

export default SpecialRoutes;
