import React from 'react';
import { Chip, LinearProgress, Stack, Typography } from '@mui/material';
import CustomTooltip from 'components/@extended/Tooltip';
import { useNavigate } from 'react-router-dom';

/* 	for (const product of products) {
			if (product.requested_id === null) {
				product.statusMesssage = req.t("buy_requests.productStatus.requestedIdMissing");
				product.status = "Onay Bekliyor";
			} else if (product.offered_id === null) {
				product.statusMesssage = req.t("buy_requests.productStatus.offeredIdMissing");
				product.status = "Teklif Bekliyor";
			} else if (product.ordered_id === null) {
				product.statusMesssage = req.t("buy_requests.productStatus.orderedIdMissing");
				product.status = "Sipariş Bekliyor";
			} else if (product.imported_amount === null) {
				product.statusMesssage = req.t("buy_requests.productStatus.importedAmountMissing");
				product.status = "Ürün Bekliyor";
			} else {
				if (product.amount == product.imported_amount) {
					product.statusMesssage = req.t("buy_requests.productStatus.complete");
					product.status = "Tamamlandı";
				} else {
					product.statusMesssage = req.t("buy_requests.productStatus.incomplete", {
						imported_amount: product.imported_amount,
						amount: product.amount - product.imported_amount,
						unit: product.product.unit,
					});
					product.status = "Kısmi Tamamlandı";
				}
			} */

const colors = {
  'Onay Bekliyor': 'error',
  'Teklif Bekliyor': 'warning',
  'Mukayese Ediliyor': 'info',
  Sipariş: 'success',
  Tedarik: 'default',
  Tamamlandı: 'success'
};

const ProductStatusCell = ({ product, ...props }) => {
  const navigate = useNavigate();
  const { status, status_message, order_id } = product;
  if (status == null || status_message == null) {
    return null;
  }
  return (
    <Stack {...props} fullWidth direction={'row'} spacing={1} alignItems={'center'} justifyContent={'flex-end'}>
      {status == 'Tedarik' ? (
        <CustomTooltip
          arrow
          color={colors[status]}
          title={
            <Stack p={1} spacing={1}>
              <Typography variant="h5">{'Tedarik'}</Typography>
              <Typography>{status_message}</Typography>

              <Chip
                onClick={() => {
                  navigate(`/orders/${order_id}`);
                }}
                label={<Typography>{`Sipariş #${order_id}`}</Typography>}
                color={colors['Sipariş']}
                variant="filled"
              />
            </Stack>
          }
        >
          <Chip
            label={
              <Stack>
                <Typography>{'Tedarik' || 'Varsayılan'}</Typography>
                <LinearProgress color="success" sx={{ bgcolor: 'black' }} variant="determinate" value={product?.supply_progress || 0} />
              </Stack>
            }
            color={colors[status]}
            variant="filled"
          />
        </CustomTooltip>
      ) : (
        <CustomTooltip arrow color={colors[status]} title={status_message || 'Varsayılan'}>
          {status == 'Sipariş' ? (
            <Chip
              onClick={() => {
                navigate(`/orders/${order_id}`);
              }}
              label={<Typography>{`Sipariş #${order_id}`}</Typography>}
              color={colors[status]}
              variant="filled"
            />
          ) : (
            <Chip label={<Typography>{status || 'Varsayılan'}</Typography>} color={colors[status]} variant="filled" />
          )}
        </CustomTooltip>
      )}
    </Stack>
  );
};

export default ProductStatusCell;
