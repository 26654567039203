import React from 'react';
import { FormattedRelativeTime } from 'react-intl';
import CustomTooltip from 'components/@extended/Tooltip';
import { Box } from '@mui/material';

const TimeagoCell = ({ value }) => {
  // Assuming `value` is already a timestamp in milliseconds
  const utcDate = new Date(value);

  // Get the local date and time strings
  const localDateString = utcDate.toLocaleDateString(undefined, {
    timeZone: 'Europe/Istanbul'
  });
  const localTimeString = utcDate.toLocaleTimeString(undefined, {
    timeZone: 'Europe/Istanbul'
  });

  // Calculate the time difference in seconds
  const now = new Date();
  const timeDifference = (utcDate.getTime() - now.getTime()) / 1000;

  return (
    <CustomTooltip arrow color={'primary'} title={`${localDateString} ${localTimeString}`}>
      <Box>
        <FormattedRelativeTime value={timeDifference} numeric="auto" updateIntervalInSeconds={1} />
      </Box>
    </CustomTooltip>
  );
};

export default TimeagoCell;
