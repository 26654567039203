import PropTypes from 'prop-types';
import { useState } from 'react';

// material-ui
import {
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
  Tooltip
} from '@mui/material';

// third-party
import { pdfjs } from 'react-pdf';
import { FormattedMessage } from 'react-intl';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

// project-imports
import AddDocument from './AddDocument';
import AlertDelete from 'sections/apps/alertDelete.js';
import IconButton from 'components/@extended/IconButton';
import { PopupTransition } from 'components/@extended/Transitions';
import FileViewer from 'react-file-viewer';

// assets
import { Edit, Trash } from 'iconsax-react';
import AlertClosing from 'components/alerts/AlertClosing';
import usePreventNavigation from 'hooks/usePreventNavigation';

// ==============================|| CUSTOMER - PREVIEW ||============================== //

function getLocalTimeInHoursFromTimestamp(timestamp) {
  const date = new Date(timestamp);
  const localDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
  const dayMonthYear = localDate.toLocaleDateString('tr-TR', { day: 'numeric', month: 'short', year: 'numeric' });
  const hours = localDate.getHours();
  const minutes = localDate.getMinutes();

  // Format hours and minutes with leading zeros if needed
  const formattedHours = hours < 10 ? `0${hours}` : hours;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  // Return as a string in "HH:MM" format
  return `${dayMonthYear}  |   ${formattedHours}:${formattedMinutes}`;
}
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export default function DocumentPreview({ doc, open, onClose, handleDelete }) {
  const [openAlert, setOpenAlert] = useState(false);

  const [add, setAdd] = useState(false);
  const [alertVisible, setAlertVisible] = useState(false);
  const [isChanged, setIsChanged] = useState(false);

  usePreventNavigation(isChanged);

  const handleAdd = () => {
    setAdd(!add);
  };

  const handleClose = () => {
    setOpenAlert(!openAlert);
    onClose();
  };

  const isImageFile = (url) => /\.(jpeg|jpg|webp|svg|gif|png)$/.test(url);
  const isIframeFile = (url) => /\.(pdf|txt)$/.test(url);

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={PopupTransition}
        keepMounted
        onClose={onClose}
        aria-describedby="alert-dialog-slide-description"
        fullScreen
      >
        <Box id="PopupPrint" sx={{ px: { xs: 2, sm: 3, md: 5 }, py: 1 }}>
          <DialogTitle sx={{ px: 0 }}>
            <Stack direction="row" alignItems="center" justifyContent="center" spacing={4}>
              <List sx={{ width: 1, p: 0 }}>
                <ListItem disablePadding>
                  <ListItemText
                    primary={<Typography variant="h5">{doc?.name}</Typography>}
                    secondary={<Typography color="secondary">{doc?.description}</Typography>}
                  />
                </ListItem>
              </List>
              <List sx={{ width: 1, p: 0 /* alignSelf: 'flex-start'  */ }}>
                <ListItem disablePadding>
                  <ListItemText
                    primary={
                      <Typography variant="h6">
                        <FormattedMessage id="creation-date" />:
                      </Typography>
                    }
                    secondary={<Typography color="secondary">{getLocalTimeInHoursFromTimestamp(doc?.timestamp)}</Typography>}
                  />
                </ListItem>
              </List>
              <Stack direction="row" alignItems="center" justifyContent="center" spacing={0.5}>
                <Tooltip title="Edit">
                  <IconButton color="secondary" onClick={handleAdd}>
                    <Edit />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete" onClick={handleClose}>
                  <IconButton color="error">
                    <Trash />
                  </IconButton>
                </Tooltip>
              </Stack>
            </Stack>
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} xl={12}>
                {isIframeFile(doc?.url) ? (
                  <iframe
                    src={process.env.REACT_APP_API_URL + doc?.url}
                    title="file"
                    style={{ width: '100%', height: '80vh', border: 'none', overflow: 'hidden' }}
                    scrolling="no"
                  />
                ) : isImageFile(doc?.url) ? (
                  <img
                    src={process.env.REACT_APP_API_URL + doc?.url}
                    alt={doc?.name}
                    style={{ maxWidth: '100%', maxHeight: '80vh', objectFit: 'contain', display: 'block', margin: 'auto' }}
                  />
                ) : (
                  doc?.is_folder == 0 && (
                    <div style={{ width: '100%', height: '80vh', overflow: 'hidden' }}>
                      <FileViewer fileType={doc?.url?.split('.').pop()} filePath={process.env.REACT_APP_API_URL + doc?.url} />
                    </div>
                  )
                )}
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
            <Button
              color="error"
              variant="contained"
              onClick={() => {
                onClose();
              }}
            >
              <FormattedMessage id="close" />
            </Button>
          </DialogActions>
        </Box>
      </Dialog>

      {/* edit doc dialog */}
      <Dialog
        maxWidth="sm"
        fullWidth
        TransitionComponent={PopupTransition}
        onClose={() => {
          if (isChanged) setAlertVisible(true);
          else handleAdd();
        }}
        open={add}
        sx={{ '& .MuiDialog-paper': { p: 0 } }}
      >
        <AddDocument itemToUpdate={doc} onCancel={handleAdd} setIsChanged={setIsChanged} />
      </Dialog>

      <AlertClosing
        open={alertVisible}
        onAgree={() => {
          setAlertVisible(false);
          setIsChanged(false);
          handleAdd();
        }}
        onClose={() => setAlertVisible(false)}
        highlight="Doküman Formu"
      />

      <AlertDelete
        title={doc?.name + ' (' + doc?.url + ')'}
        open={openAlert}
        handleClose={handleClose}
        handleDelete={handleDelete}
        des1="Emin misiniz?"
        des2="silinecektir."
      />
    </>
  );
}

DocumentPreview.propTypes = {
  doc: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  handleDelete: PropTypes.func
};
