import { useState, useEffect, useRef } from 'react';

function useRecentRoutes(limit = 3) {
  const [recentRoutes, setRecentRoutes] = useState([]);
  const savedRoutesRef = useRef({});

  useEffect(() => {
    const savedRoutes = JSON.parse(localStorage.getItem('recentRoutes')) || {};

    // Skip updates if nothing has changed
    if (JSON.stringify(savedRoutesRef.current) === JSON.stringify(savedRoutes)) {
      return;
    }

    savedRoutesRef.current = savedRoutes;

    // Sort and filter routes by last visit
    const sortedRoutes = Object.entries(savedRoutes)
      .filter(([path]) => path !== '/404') // Exclude 404 route
      .sort(([, a], [, b]) => new Date(b.lastVisit) - new Date(a.lastVisit))
      .slice(0, limit);

    setRecentRoutes(sortedRoutes);
  }, [limit]);

  return recentRoutes;
}

export default useRecentRoutes;
