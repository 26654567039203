import React from 'react';
import { Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const ApproveCell = ({ value, ...props }) => {
  const theme = useTheme();
  const color = value == 1 ? 'warning' : value == 2 ? 'success' : value == 3 ? 'info' : 'error';

  return (
    <Stack {...props} direction={'row'} spacing={1} alignItems={'center'}>
      <Typography variant="subtitle1" color={theme.palette[color].main}>
        {value == 0 || value == null ? 'Onaysız' : value + '. Onay'}
      </Typography>
    </Stack>
  );
};

export default ApproveCell;
