import { TextField, Autocomplete, ListItem, ListItemText, ListItemAvatar, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
import { useAsyncDebounce } from 'react-table';
import Avatar from 'components/@extended/Avatar';
import { useAutocompleteProjects } from 'hooks/project/useProjectsData';
import { getIn } from 'formik';
import ProjectCell from 'components/data/ProjectCell';

function ProjectSelect({ id, selectedProject, setSelectedProject, formik, onChange, label, disabled = false }) {
  const theme = useTheme();
  const downLG = useMediaQuery((theme) => theme.breakpoints.down('lg'));
  const [isFocused, setIsFocused] = useState(false);
  const [search, setSearch] = useState('');
  const { isLoading, data: projectsData } = useAutocompleteProjects({ search, isFocused });
  const projects = projectsData?.data || [];

  const onSearchWordChange = useAsyncDebounce((value) => {
    setSearch(value);
  }, 300);

  const handleProjectChange = (event, newValue) => {
    if (onChange) onChange(newValue);
    if (setSelectedProject) setSelectedProject(newValue);
    if (!newValue) {
      setSearch('');
    }
  };

  return !disabled ? (
    <Autocomplete
      sx={{ minWidth: downLG ? 200 : 250 }}
      loading={isLoading}
      loadingText="Yükleniyor..."
      id="project-select"
      value={selectedProject || null}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      options={projects}
      getOptionLabel={(option) => option.name || ''}
      onChange={handleProjectChange}
      onBlur={() => {
        setSearch('');
        setIsFocused(false);
      }}
      onFocus={() => setIsFocused(true)}
      renderInput={(params) => (
        <TextField
          {...params}
          data-cy="project-select-search"
          placeholder="Proje Ara"
          onChange={(e) => onSearchWordChange(e.target.value)}
          label={!label ? label : 'Proje'}
          sx={{ '& .MuiAutocomplete-input.Mui-disabled': { WebkitTextFillColor: theme.palette.text.primary } }}
          InputProps={{
            ...params.InputProps,
            startAdornment: selectedProject && (
              <ListItemAvatar>
                <Avatar size="sm" src={process.env.REACT_APP_API_URL + selectedProject.banner_url}></Avatar>
              </ListItemAvatar>
            )
          }}
          error={getIn(formik?.errors, id) && getIn(formik?.touched, id)}
          helperText={getIn(formik?.errors, id) && getIn(formik?.touched, id) && getIn(formik?.errors, id)}
        />
      )}
      renderOption={(props, option) => (
        <ListItem {...props}>
          <ListItemAvatar>
            <Avatar src={process.env.REACT_APP_API_URL + option.banner_url}></Avatar>
          </ListItemAvatar>
          <ListItemText primary={option.name} secondary={option.type} />
        </ListItem>
      )}
      noOptionsText="Proje bulunamadı"
    />
  ) : (
    <ProjectCell project={selectedProject} />
  );
}

export default ProjectSelect;
