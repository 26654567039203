import React from 'react';
import { ListItemText, Stack } from '@mui/material';
import Avatar from 'components/@extended/Avatar';

const UserCell = ({ user, avatarProps, xl }) => {
  if (xl) {
    return (
      <Stack direction={'row'} spacing={1} alignItems={'center'}>
        <Avatar size="xl" src={process.env.REACT_APP_API_URL + user?.avatar_url} {...avatarProps}></Avatar>
        <ListItemText primaryTypographyProps={{ variant: 'h4' }} primary={user?.name} secondary={user?.email} />
      </Stack>
    );
  }

  return (
    <Stack direction={'row'} spacing={1} alignItems={'center'}>
      <Avatar size="xs" src={process.env.REACT_APP_API_URL + user?.avatar_url} {...avatarProps}></Avatar>
      <ListItemText primary={user?.name} />
    </Stack>
  );
};

export default UserCell;
