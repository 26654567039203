// material-ui
import { TextField, Autocomplete, Chip, ListItem, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { companyColor } from './CompanySelect';

const options = ['Ana Firma', 'Ortaklık', 'Taşeron', 'Tedarikçi', 'Tşrn-Tdrk', 'İdari'];

function CompanyTypeSelect({ selectedCompanyType, setSelectedCompanyType, onChange }) {
  const theme = useTheme();

  return (
    <Autocomplete
      id="company-type-select"
      value={selectedCompanyType || ''}
      options={options}
      freeSolo
      onChange={(event, newValue) => {
        if (onChange) onChange();
        setSelectedCompanyType(newValue);
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            placeholder="Firma Tipi Seç"
            label=""
            sx={{ '& .MuiAutocomplete-input.Mui-disabled': { WebkitTextFillColor: theme.palette.text.primary } }}
          />
        );
      }}
      renderOption={(props, option) => (
        <ListItem {...props}>
          <Chip label={<Typography>{option}</Typography>} color={companyColor(option) || 'default'} />
        </ListItem>
      )}
    />
  );
}

export default CompanyTypeSelect;
