import React from 'react';
import { Stack, Typography } from '@mui/material';
import Avatar from 'components/@extended/Avatar';

const ChiefCell = ({ value, avatarProps }) => {
  return (
    <Stack direction={'row'} spacing={2} alignItems={'center'}>
      <Avatar size="md" {...avatarProps} />
      <Stack spacing={0.5}>
        <Typography variant="subtitle1" fontWeight="bold">
          {value?.chief_name}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {value?.chief_email} | +{value?.chief_phone}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default ChiefCell;
