import { useState, useEffect } from 'react';

// Custom hook: useSelectedProject
const useSelectedProject = () => {
  const [selectedProject, setSelectedProject] = useState(null);

  useEffect(() => {
    // Function to load the selected project from session storage
    const loadSelectedProject = () => {
      const savedProject = sessionStorage.getItem('selectedProject');
      if (savedProject) {
        setSelectedProject(JSON.parse(savedProject));
      } else {
        setSelectedProject(null);
      }
    };

    // Load the selected project when the component mounts
    loadSelectedProject();

    // Add an event listener for storage changes
    window.addEventListener('storage', loadSelectedProject);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('storage', loadSelectedProject);
    };
  }, []);

  return selectedProject;
};

export default useSelectedProject;
