import { lazy } from 'react';

// project-imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import UrlGuard from 'utils/route-guard/PermGuard';
import EditOffer from 'pages/stock-management/offer/edit-offer';
import QuotationDetail from 'pages/company/quotations/quotation-detail';
import CompanyQuotations from 'pages/company/quotations/company-quotations';

// default page
const Home = Loadable(lazy(() => import('pages/home/Home')));

// apps
const Profile = Loadable(lazy(() => import('pages/apps/profile')));
const Chat = Loadable(lazy(() => import('pages/apps/chat')));
const Notifications = Loadable(lazy(() => import('pages/apps/notifications')));

// project
const AllProjects = Loadable(lazy(() => import('pages/project/all-projects')));
const ProjectDetail = Loadable(lazy(() => import('pages/project/project-detail')));
const AllDocuments = Loadable(lazy(() => import('pages/project/document/all-documents')));
const AllCompanies = Loadable(lazy(() => import('pages/company/all-companies')));
const CompanyDetail = Loadable(lazy(() => import('pages/company/company-detail')));
const AllContracts = Loadable(lazy(() => import('pages/contracts/all-contracts')));
const ContractDetail = Loadable(lazy(() => import('pages/contracts/contract-detail')));
const DailyReportCalendar = Loadable(lazy(() => import('pages/daily-report/DailyReportCalendar')));
const DailyReportDetail = Loadable(lazy(() => import('pages/daily-report/DailyReportDetail')));
const ProcessPayments = Loadable(lazy(() => import('pages/process-payment/process-payments')));
const ProcessPaymentsDetail = Loadable(lazy(() => import('pages/process-payment/process-payment-detail')));
const ProcessPaymentTemplates = Loadable(lazy(() => import('pages/process-payment/process-payment-templates')));
const WorkTree = Loadable(lazy(() => import('pages/work/work-tree')));

// finance management
const Vaults = Loadable(lazy(() => import('pages/vault/vaults')));
const VaultDetail = Loadable(lazy(() => import('pages/vault/vaultDetail')));

// sos
const Gayrimenkul = Loadable(lazy(() => import('pages/sos/gayrimenkul')));
const Stoklar = Loadable(lazy(() => import('pages/sos/stoklar')));
const Odemeler = Loadable(lazy(() => import('pages/sos/odemeler')));

// stock management
const CreateBuyRequest = Loadable(lazy(() => import('pages/stock-management/buying/create-buy-request')));
const BuyRequests = Loadable(lazy(() => import('pages/stock-management/buying/buy-requests')));
const BuyRequestDetail = Loadable(lazy(() => import('pages/stock-management/buying/buy-request-detail')));
const AllProducts = Loadable(lazy(() => import('pages/stock-management/product/all-products')));
const RequestedProducts = Loadable(lazy(() => import('pages/stock-management/requested-product/requested-products')));
const Storages = Loadable(lazy(() => import('pages/stock-management/storage/storages')));
const Stocks = Loadable(lazy(() => import('pages/stock-management/storage/stocks')));
const CreateOffer = Loadable(lazy(() => import('pages/stock-management/offer/create-offer')));
const Orders = Loadable(lazy(() => import('pages/stock-management/order/orders')));
const OrderDetail = Loadable(lazy(() => import('pages/stock-management/order/order-detail')));
const Intakes = Loadable(lazy(() => import('pages/stock-management/intake/intakes')));
const Offers = Loadable(lazy(() => import('pages/stock-management/offer/offers')));
const OfferDetail = Loadable(lazy(() => import('pages/stock-management/offer/offer-detail')));

// administration
const AllUsers = Loadable(lazy(() => import('pages/user/all-users')));
const Roles = Loadable(lazy(() => import('pages/roles/roles')));
const Personnels = Loadable(lazy(() => import('pages/personnel/personnels')));
const LogsCharts = Loadable(lazy(() => import('pages/logging/logCharts')));

// developer
const Access = Loadable(lazy(() => import('pages/access/access')));
const Logs = Loadable(lazy(() => import('pages/logging/logs')));

// other pages
const Account = Loadable(lazy(() => import('pages/account/account')));
const WidgetStatistics = Loadable(lazy(() => import('pages/extra-pages/statistics')));
/* const AllTransactions = Loadable(lazy(() => import('pages/vault/all-transactions'))); */
const Currents = Loadable(lazy(() => import('pages/vault/currents')));

// ==============================|| MAIN ROUTES ||============================== //

const MainRoutes = {
  path: '/',
  element: (
    <AuthGuard>
      <UrlGuard>
        <MainLayout />
      </UrlGuard>
    </AuthGuard>
  ),

  children: [
    {
      path: 'stats',
      element: <WidgetStatistics />
    },
    {
      path: 'home',
      element: <Home />
    },
    {
      path: 'projects',
      element: <AllProjects />
    },
    {
      path: 'projects/:project_id',
      element: <ProjectDetail />
    },
    {
      path: 'daily-reports',
      element: <DailyReportCalendar />
    },
    {
      path: 'daily-reports/:dr_id',
      element: <DailyReportDetail />
    },
    {
      path: 'work-progress',
      element: <WorkTree />
    },
    {
      path: 'contracts',
      element: <AllContracts />
    },
    {
      path: 'contracts/:contract_id',
      element: <ContractDetail />
    },
    {
      path: 'all-documents',
      element: <AllDocuments />
    },
    {
      path: 'vaults',
      element: <Vaults />
    },
    {
      path: 'vaults/:vault_id',
      element: <VaultDetail />
    },
    {
      path: 'currents',
      element: <Currents />
    },

    {
      path: 'companies',
      element: <AllCompanies />
    },
    {
      path: 'companies/:company_id',
      element: <CompanyDetail />
    },
    { path: 'companies/:company_id/info', element: <CompanyDetail /> },
    { path: 'companies/:company_id/contacts', element: <CompanyDetail /> },
    { path: 'companies/:company_id/quotations', element: <CompanyDetail /> },
    { path: 'companies/:company_id/quotations/:quotation_id', element: <QuotationDetail /> },

    {
      path: 'realty',
      element: <Gayrimenkul />
    },
    {
      path: 'reports',
      element: <Stoklar />
    },
    {
      path: 'payments',
      element: <Odemeler />
    },
    {
      path: 'process-payments',
      element: <ProcessPayments />
    },
    {
      path: 'process-payments/templates',
      element: <ProcessPaymentTemplates />
    },
    {
      path: 'process-payments/:id',
      element: <ProcessPaymentsDetail />
    },

    {
      path: 'buy-requests',
      element: <BuyRequests />
    },
    {
      path: 'create-buy-request',
      element: <CreateBuyRequest />
    },
    {
      path: 'buy-requests/:id',
      element: <BuyRequestDetail />
    },
    {
      path: 'buy-requests/:id/edit',
      element: <CreateBuyRequest />
    },

    {
      path: 'all-products',
      element: <AllProducts />
    },
    {
      path: 'requested-products',
      element: <RequestedProducts />
    },
    {
      path: 'quotations',
      element: <CompanyQuotations />
    },
    {
      path: 'quotations/:quotation_id',
      element: <QuotationDetail />
    },
    {
      path: 'offers',
      element: <Offers />
    },
    {
      path: 'offers/:id',
      element: <OfferDetail />
    },
    {
      path: 'offers/:id/edit/:offer_nanoid',
      element: <EditOffer />
    },

    {
      path: 'create-offer/:offer_nanoid',
      element: <CreateOffer />
    },
    {
      path: 'orders',
      element: <Orders />
    },
    {
      path: 'orders/:id',
      element: <OrderDetail />
    },
    {
      path: 'intakes',
      element: <Intakes />
    },
    {
      path: 'storages',
      element: <Storages />
    },
    {
      path: 'stocks',
      element: <Stocks />
    },

    {
      path: 'all-users',
      element: <AllUsers />
    },

    {
      path: 'access',
      element: <Access />
    },

    {
      path: 'roles',
      element: <Roles />
    },
    {
      path: 'personnels',
      element: <Personnels />
    },

    {
      path: 'logcharts',
      element: <LogsCharts />
    },
    {
      path: 'loglists',
      element: <Logs />
    },
    {
      path: 'profile',
      element: <Profile />
    },
    {
      path: 'account',
      element: <Account />
    },
    {
      path: 'chat',
      element: <Chat />
    },
    {
      path: 'notifications',
      element: <Notifications />
    }
  ]
};

export default MainRoutes;
