import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// project-imports
/* import { enqueueSnackbar } from 'notistack'; */
import { useUrlAccess } from 'hooks/access/useAccessData';

// ==============================|| AUTH GUARD ||============================== //

/* const urlAccesses = {
  '/buy-requests': true,
  '/create-buy-request': false,
  '/process-payments': false
};
 */

const UrlGuard = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { data: urlAccessData } = useUrlAccess();

  console.log('urlAccessData', urlAccessData);
  const urlAccess = urlAccessData?.data || {};

  const hasAccess = (url) => {
    // if urlAcces is not defined, then allow access

    if (urlAccess[url] == undefined) {
      return true;
    }

    if (urlAccess[url] == true) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    const pathname = location.pathname;

    if (!hasAccess(pathname)) {
      /*     enqueueSnackbar('Bu sayfaya erişiminiz yok', { variant: 'error' }); */
      navigate('/401');
    }
  }, [navigate, location, urlAccessData]);

  return children;
};

UrlGuard.propTypes = {
  children: PropTypes.node
};

export default UrlGuard;
