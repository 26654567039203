// material-ui
import { CardContent, CircularProgress, Stack } from '@mui/material';

export default function LoadingCardContent() {
  return (
    <CardContent>
      <Stack spacing={4} justifyContent="center" alignItems="center">
        <CircularProgress size={60} />
      </Stack>
    </CardContent>
  );
}
