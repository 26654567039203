import { useEffect } from 'react';
import { useReportError } from 'hooks/error/useErrorsData';

const ErrorReporter = () => {
  const { mutate: reportError } = useReportError();
  const errorCache = new Map();

  const shouldReportError = (errorKey) => {
    const currentTime = Date.now();
    const cacheTimeout = 60000; // Cache timeout in milliseconds (e.g., 1 minute)
    console.log('errorCache', errorCache);
    if (errorCache.has(errorKey)) {
      const lastReportTime = errorCache.get(errorKey);
      if (currentTime - lastReportTime < cacheTimeout) {
        console.log('--> errorCache contains errorkey: <<<', errorKey, '>>> within cache timeout');
        // If the error was reported within the cache timeout, skip reporting
        return false;
      } else {
        console.log('--> errorCache contains errorkey', errorKey, ' but outside cache timeout');
      }
    }

    // Update the cache with the current time for this error
    errorCache.set(errorKey, currentTime);
    return true;
  };

  useEffect(() => {
    window.onerror = function (message, source, lineno, colno, error) {
      const errorKey = `${message}`;
      if (shouldReportError(errorKey)) {
        const errorData = {
          message,
          source,
          lineno,
          colno,
          error: error?.stack || error?.message || 'No error stack/message',
          client_url: window.location.href
        };
        reportError(errorData);
      }
    };

    const handleUnhandledRejection = (event) => {
      const errorKey = `${event.reason?.message || 'Unhandled promise rejection'}_${event.reason?.stack || 'No stack trace'}`;
      if (shouldReportError(errorKey)) {
        const errorData = {
          message: event.reason?.message || 'Unhandled promise rejection',
          stack: event.reason?.stack || 'No stack trace',
          client_url: window.location.href
        };
        reportError(errorData);
      }
    };

    window.addEventListener('unhandledrejection', handleUnhandledRejection);

    return () => {
      window.onerror = null;
      window.removeEventListener('unhandledrejection', handleUnhandledRejection);
    };
  }, [reportError]);

  return null;
};

export default ErrorReporter;
