import { Skeleton } from '@mui/material';
import { Box, Stack, Grid, AvatarGroup, Tooltip, Badge, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const MovementPanelSkeleton = () => {
  const theme = useTheme();

  if (!open) return null;

  return (
    <>
      <Box sx={{ p: 3, pb: 1, pt: 1, bgcolor: theme.palette.secondary[200] }}>
        <Stack direction="row" alignItems="center" justifyContent={'space-between'}>
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <Skeleton variant="rectangular" width={32} height={32} />
            <Skeleton variant="text" width={200} />
          </Stack>
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <Skeleton variant="rounded" width={32} height={32} />
            <Skeleton variant="rounded" width={32} height={32} />
            <Skeleton variant="rounded" width={32} height={32} />
            <Skeleton variant="rounded" width={32} height={32} />
            <Skeleton variant="rounded" width={32} height={32} />
          </Stack>
        </Stack>
      </Box>

      <Box sx={{ p: 1, pl: 3, rowGap: 1 }}>
        <Stack direction="row" alignItems="center" columnGap={4}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Skeleton variant="circular" width={32} height={32} />
            <Skeleton variant="text" width={100} />
          </Stack>
          <Grid container wrap="nowrap" alignItems="center" spacing={1}>
            <Grid item>
              <Skeleton variant="circular" width={40} height={40} />
            </Grid>
            <Grid item xs zeroMinWidth>
              <Skeleton variant="text" width={150} />
              <Skeleton variant="text" width={100} />
            </Grid>
          </Grid>
        </Stack>
      </Box>

      <Box sx={{ p: 1, pl: 3 }}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Skeleton variant="circular" width={32} height={32} />
          <Skeleton variant="text" width={100} />
          <AvatarGroup max={12} spacing="medium">
            {Array.from(new Array(6)).map((_, index) => (
              <Tooltip key={index} title="">
                <Badge overlap="circular">
                  <Skeleton variant="circular" width={40} height={40} />
                </Badge>
              </Tooltip>
            ))}
          </AvatarGroup>
        </Stack>
      </Box>

      <Box sx={{ p: 1, pl: 3 }}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Skeleton variant="circular" width={32} height={32} />
          <Skeleton variant="text" width={100} />
          <AvatarGroup max={12} spacing="medium">
            {Array.from(new Array(4)).map((_, index) => (
              <Tooltip key={index} title="">
                <Badge overlap="circular">
                  <Skeleton variant="circular" width={40} height={40} />
                </Badge>
              </Tooltip>
            ))}
          </AvatarGroup>
        </Stack>
      </Box>
      <Box sx={{ p: 1, pl: 3 }}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Skeleton variant="circular" width={32} height={32} />
          <Skeleton variant="text" width={100} />
        </Stack>
      </Box>
      <Box sx={{ p: 1, pl: 3 }}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Skeleton variant="circular" width={32} height={32} />
          <Skeleton variant="text" width={100} />
        </Stack>
      </Box>

      <Box sx={{ p: 1, pl: 3 }}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Skeleton variant="circular" width={32} height={32} />
          <Skeleton variant="text" width={200} />
        </Stack>
        <Stack direction="column" spacing={1} mt={2}>
          {Array.from(new Array(1)).map((_, index) => (
            <Skeleton key={index} variant="rounded" width="100%" height={120}>
              <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                <Skeleton variant="text" width="100%" />
                <Skeleton variant="text" width="100%" />
              </Box>
            </Skeleton>
          ))}
        </Stack>
        <Stack marginTop={1}>
          <Button disabled variant="contained">
            <Skeleton variant="text" width={100} />
          </Button>
        </Stack>
      </Box>
    </>
  );
};

export default MovementPanelSkeleton;
