import { useQuery, useMutation, useQueryClient } from 'react-query';
import axios from 'utils/axios2';

const fetchAllProjects = () => {
  return axios.get('/api/projects');
};

export const useProjectsData = () => {
  return useQuery('projects', fetchAllProjects, { keepPreviousData: true, staleTime: 1000 * 60, cacheTime: 1000 * 60 });
};

const fetchProject = async (project_id) => {
  const { data } = await axios.get(`/api/projects/${project_id}`);
  return data;
};

export const useProjectData = (project_id) => {
  return useQuery(['projects', project_id], () => fetchProject(project_id), {
    enabled: !!project_id,
    staleTime: 1000 * 60,
    cacheTime: 1000 * 60,
    keepPreviousData: true
  });
};

const fetchAutocompleteProjects = async (params = {}) => {
  const queryParams = new URLSearchParams(
    Object.entries(params).reduce((acc, [key, value]) => {
      if (value != null) {
        acc[key] = value;
      }
      return acc;
    }, {})
  ).toString();

  const { data } = await axios.get(`/api/projects/autocomplete${queryParams ? `?${queryParams}` : ''}`);
  return data;
};

export const useAutocompleteProjects = (params) => {
  return useQuery(['projects', 'autocomplete', params], () => fetchAutocompleteProjects(params), {
    enabled: params?.isFocused,
    staleTime: 3 * 60 * 1000,
    cacheTime: 3 * 60 * 1000,
    keepPreviousData: true
  });
};

const addProject = (project) => {
  return axios.post('/api/projects', project);
};

export const useAddProject = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation(addProject, {
    onSuccess: () => {
      queryClient.invalidateQueries('projects');
    }
  });
  return mutation;
};

async function updateProject({ payload }) {
  const { id, ...data } = payload;
  const { data: response } = await axios.put(`/api/projects/${id}`, data);
  return response;
}

export const useUpdateProject = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation(updateProject, {
    onSuccess: () => {
      queryClient.invalidateQueries('projects');
    }
  });
  return mutation;
};

const deleteProject = async (id) => {
  const { data } = await axios.delete(`/api/projects/${id}`);
  return data;
};

export const useDeleteProject = () => {
  const queryClient = useQueryClient();

  return useMutation(deleteProject, {
    onSuccess: () => {
      queryClient.invalidateQueries('projects');
    }
  });
};

const uploadForBanner = (body) => {
  const formData = new FormData();
  formData.append('id', body.id);
  formData.append('banner', body.file);

  return axios.post('/api/projects/upload_banner', formData);
};

export const useUploadForBanner = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(uploadForBanner, {
    onSuccess: () => {
      queryClient.invalidateQueries(['projects']);
    }
  });
  return mutation;
};
