// material-ui
import { Stack, Table, TableContainer, TableCell, TableHead, TableRow, TableBody, Typography, Chip } from '@mui/material';

// project-imports
import MainCard from 'components/MainCard';
import ProductImageCell from 'components/data/ProductImageCell';
import ProductStatusCell from 'components/data/ProductStatusCell';
import UrgencyCell from 'components/data/UrgencyCell';
import { FormattedDate } from 'react-intl';

// third-party

// assets

// ==============================|| FORM VALIDATION - INSTANT FEEDBACK  ||============================== //

const BuyRequestProductsTable = ({ products, compact }) => {
  return (
    <MainCard
      content={false}
      title={
        compact ? null : (
          <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
            <Typography variant="h5">{`İstenilen Ürünler:  ${products.length}`}</Typography>
          </Stack>
        )
      }
    >
      <TableContainer>
        <Table size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Sıra No</TableCell>
              <TableCell align="left"></TableCell>
              <TableCell align="right">Ürün</TableCell>
              <TableCell align="right">Kategori</TableCell>
              <TableCell align="right">Miktar</TableCell>
              <TableCell align="right">Birim</TableCell>
              <TableCell align="right">Marka</TableCell>
              <TableCell align="right">Model</TableCell>
              <TableCell align="right">Aciliyet</TableCell>
              <TableCell align="right">Gereklilik Tarihi</TableCell>
              <TableCell align="right">Not</TableCell>
              <TableCell align="right">Durum</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products.map((row, index) => (
              <TableRow hover key={row.id}>
                <TableCell data-cy={`brp-index-${index}`} align="left">
                  {index + 1}
                </TableCell>
                <TableCell data-cy={`brp-image-${index}`} align="left">
                  <ProductImageCell image_url={row?.product?.image_url} />
                </TableCell>
                <TableCell data-cy={`brp-name-${index}`} align="right">
                  {row?.product?.name}
                </TableCell>
                <TableCell data-cy={`brp-type-${index}`} align="right">
                  <Chip label={row?.product?.type} color="primary" />
                </TableCell>
                <TableCell data-cy={`brp-amount-${index}`} align="right">
                  {row.amount}
                </TableCell>
                <TableCell data-cy={`brp-unit-${index}`} align="right">
                  {row?.product?.unit}
                </TableCell>
                <TableCell data-cy={`brp-brand-${index}`} align="right">
                  {row?.brand}
                </TableCell>
                <TableCell data-cy={`brp-model-${index}`} align="right">
                  {row?.model}
                </TableCell>
                <TableCell data-cy={`brp-urgency-${index}`} align="right">
                  <UrgencyCell value={row?.urgency}></UrgencyCell>
                </TableCell>
                <TableCell data-cy={`brp-deadline-${index}`} align="right">
                  <FormattedDate value={row?.deadline} year="numeric" month="2-digit" day="2-digit" />
                </TableCell>
                <TableCell data-cy={`brp-note-${index}`} align="right">
                  {row?.note}
                </TableCell>
                <TableCell data-cy={`brp-status-${index}`} align="right">
                  <ProductStatusCell data-cy={`product-status-${index}`} product={row} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </MainCard>
  );
};

export default BuyRequestProductsTable;
