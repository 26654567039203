import { useQuery, useMutation, useQueryClient } from 'react-query';
import axios from 'utils/axios2';

const fetchCompany = async (id) => {
  const { data } = await axios.get(`/api/companies/${id}`);
  return data;
};

export const useCompany = (id) => {
  return useQuery(['companies', id], () => fetchCompany(id), {
    cacheTime: 1 * 60 * 1000,
    staleTime: 1 * 60 * 1000
  });
};

const fetchCompanies = async (params = {}) => {
  const queryParams = new URLSearchParams(
    Object.entries(params).reduce((acc, [key, value]) => {
      if (value != null) {
        acc[key] = value;
      }
      return acc;
    }, {})
  ).toString();

  const { data } = await axios.get(`/api/companies${queryParams ? `?${queryParams}` : ''}`);
  return data;
};

export const useCompaniesData = (params) => {
  return useQuery(['companies', params], () => fetchCompanies(params), {
    cacheTime: 2 * 60 * 1000,
    staleTime: 2 * 60 * 1000,
    keepPreviousData: true
  });
};

const fetchAutoCompleteCompanies = async (params = {}) => {
  const queryParams = new URLSearchParams(
    Object.entries(params).reduce((acc, [key, value]) => {
      if (value != null) {
        acc[key] = value;
      }
      return acc;
    }, {})
  ).toString();

  const { data } = await axios.get(`/api/companies/autocomplete${queryParams ? `?${queryParams}` : ''}`);
  return data;
};

export const useAutoCompleteCompanies = (params) => {
  return useQuery(['companies', 'autocomplete', params], () => fetchAutoCompleteCompanies(params), {
    enabled: params?.isFocused,
    cacheTime: 2 * 60 * 1000,
    staleTime: 2 * 60 * 1000,
    keepPreviousData: true
  });
};

const addCompany = async (company) => {
  return axios.post('/api/companies', company);
};

export const useAddCompany = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: addCompany,
    onSuccess: () => {
      queryClient.invalidateQueries(['companies']);
      queryClient.invalidateQueries(['companies']);
    }
  });
  return mutation;
};

const updateCompany = async (company) => {
  return await axios.put(`/api/companies/${company.id}`, company);
};

export const useUpdateCompany = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: updateCompany,
    onSuccess: () => {
      queryClient.invalidateQueries(['companies']);
      queryClient.invalidateQueries(['companies']);
    }
  });
  return mutation;
};

const deleteCompany = async (id) => {
  return await axios.delete(`/api/companies/${id}`);
};

export const useDeleteCompany = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation(deleteCompany, {
    onSuccess: () => {
      queryClient.invalidateQueries(['companies']);
      queryClient.invalidateQueries(['companies']);
    }
  });
  return mutation;
};

const uploadCompanyLogo = ({ body, onProgress }) => {
  const formData = new FormData();
  formData.append('logo', body.image);

  return axios.post(`/api/companies/${body.company_id}/upload_logo`, formData, {
    onUploadProgress: (progressEvent) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      onProgress(percentCompleted);
    }
  });
};

export const useUploadCompanyLogo = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation(uploadCompanyLogo, {
    onSuccess: () => {
      queryClient.invalidateQueries(['companies']);
      queryClient.invalidateQueries(['companies']);
    }
  });
  return mutation;
};
