import { useEffect, useState } from 'react';

// project imports
import ProjectSelect from 'components/select-boxes/ProjectSelect';
import { Box } from '@mui/material';

// ==============================|| HEADER CONTENT - SEARCH ||============================== //

const MainProject = () => {
  const [selectedProject, setSelectedProject] = useState(null);

  useEffect(() => {
    // Function to load the selected project from session storage
    const loadSelectedProject = () => {
      const savedProject = sessionStorage.getItem('selectedProject');
      if (savedProject) {
        setSelectedProject(JSON.parse(savedProject));
      } else {
        setSelectedProject(null);
      }
    };

    // Load the selected project when the component mounts
    loadSelectedProject();

    // Add an event listener for storage changes
    window.addEventListener('storage', loadSelectedProject);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('storage', loadSelectedProject);
    };
  }, []);

  const handleProjectChange = (newProject) => {
    console.log('newProject', newProject);
    setSelectedProject(newProject);
    // Save the selected project to session storage
    if (newProject) {
      sessionStorage.setItem('selectedProject', JSON.stringify(newProject));
    } else {
      sessionStorage.removeItem('selectedProject');
    }
    window.dispatchEvent(new Event('storage')); // Dispatch custom event to simulate storage event
  };

  return (
    <Box sx={{ ml: { xs: 0, md: 2 } }}>
      <ProjectSelect selectedProject={selectedProject} setSelectedProject={handleProjectChange} />
    </Box>
  );
};

export default MainProject;
