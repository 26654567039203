import React from 'react';
import { alpha, useTheme } from '@mui/material/styles';
import { AppBar, Box, Container, Stack, Toolbar } from '@mui/material';
import IconButton from 'components/@extended/IconButton';
import { HambergerMenu, Printer, Edit } from 'iconsax-react';
import { ElevationScroll } from 'layout/CommonLayout/Header';
import SupplierDrawer from './SupplierDrawer';
import CustomTooltip from 'components/@extended/Tooltip';
import DiscoverButton from 'components/DiscoverButton';
import AppIcon from 'components/logo/AppIcon';
import OfferPdfGenerator from './OfferPdfGenerator';
import { useNavigate } from 'react-router';
import { ThemeMode } from 'config';
const OfferHeader = ({ offerForm, existingOffer, isSupplierDrawerOpen, handleSupplierDrawerOpen }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const matchDownMd = theme.breakpoints.down('md');

  const exists = existingOffer?.id != null;

  return (
    <ElevationScroll>
      <AppBar
        sx={{
          position: 'sticky',
          bgcolor: alpha(theme.palette.background.default, 0.1),
          backdropFilter: 'blur(8px)',
          color: theme.palette.text.primary,
          boxShadow: 'none'
        }}
      >
        <Container maxWidth="none" disableGutters={matchDownMd}>
          <Toolbar
            sx={{
              py: 1,
              bgcolor:
                theme.palette.mode === ThemeMode.DARK
                  ? alpha(theme.palette.background.default, 1)
                  : alpha(theme.palette.background.default, 0.1)
            }}
          >
            <Stack spacing={1} direction="row" sx={{ flexGrow: 1 }} alignItems="center">
              <IconButton
                onClick={(e) => {
                  e.preventDefault();
                  handleSupplierDrawerOpen();
                }}
                color="secondary"
                size="large"
              >
                <HambergerMenu />
              </IconButton>
              <Box style={{ alignSelf: 'center' }}>
                <AppIcon width={40} />
              </Box>
            </Stack>
            <Stack
              direction="row"
              sx={{
                '& .header-link': { fontWeight: 500, '&:hover': { color: theme.palette.primary.main } }
              }}
              spacing={3}
            >
              <SupplierDrawer
                data={offerForm?.data}
                isSupplierDrawerOpen={isSupplierDrawerOpen}
                handleSupplierDrawerOpen={handleSupplierDrawerOpen}
              />

              {exists && (
                <Stack direction="row" justifyContent="flex-end">
                  {exists == '31' && (
                    <CustomTooltip disabled={existingOffer?.id == null} arrow color="default" title="PDF Çıktı Al">
                      <IconButton onClick={() => OfferPdfGenerator(existingOffer)} color="secondary">
                        <Printer />
                      </IconButton>
                    </CustomTooltip>
                  )}
                  {exists == '31' && (
                    <CustomTooltip arrow color="default" title="Teklifi Düzenle">
                      <IconButton onClick={() => navigate(`/edit-offer/${existingOffer?.nano_id}`)} color="secondary">
                        <Edit />
                      </IconButton>
                    </CustomTooltip>
                  )}
                  <DiscoverButton table_name={'offers'} id={existingOffer?.id} nano_id={existingOffer?.nano_id} />
                </Stack>
              )}
            </Stack>
          </Toolbar>
        </Container>
      </AppBar>
    </ElevationScroll>
  );
};

export default OfferHeader;
