// material-ui
import { TextField, Autocomplete, Chip, ListItem } from '@mui/material';
import { useTheme } from '@mui/material/styles';

/* import { ShieldTick, ShieldCross } from 'iconsax-react';
 */
// ==============================|| ACCOUNT PROFILE - BASIC ||============================== //

const options = [
  { name: 'GET', color: 'success' },
  { name: 'POST', color: 'warning' },
  { name: 'PUT', color: 'info' },
  { name: 'DELETE', color: 'error' }
];

function HTTPMethodSelect({ selectedMethod, setSelectedMethod, onChange }) {
  const theme = useTheme();
  return (
    <Autocomplete
      id="method--select"
      value={selectedMethod || null}
      options={options}
      getOptionLabel={(option) => option.name}
      onChange={(event, newValue) => {
        onChange != undefined ? onChange() : null;
        setSelectedMethod(newValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Method Ara"
          label=""
          sx={{ '& .MuiAutocomplete-input.Mui-disabled': { WebkitTextFillColor: theme.palette.text.primary } }}
        />
      )}
      renderOption={(props, option) => (
        <ListItem {...props}>
          <Chip label={option?.name || 'null'} color={option?.color || 'default'} />
        </ListItem>
      )}
      /* noOptionsText="Proje bulunamadı" */
    />
  );
}

export default HTTPMethodSelect;
