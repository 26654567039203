// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Button, Grid, Stack, Typography, useMediaQuery } from '@mui/material';

// assets
import error401 from 'assets/images/maintenance/img-error-500.svg';

// ==============================|| ERROR 500 ||============================== //

function OfferExists() {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <Grid container direction="column" alignItems="center" justifyContent="center" sx={{ minHeight: '100vh' }} spacing={3}>
        <Grid item xs={12}>
          <Box sx={{ width: 325 }}>
            <img src={error401} alt="error 500" style={{ height: '100%', width: '100%' }} />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Stack justifyContent="center" alignItems="center">
            <Typography align="center" variant={matchDownSM ? 'h2' : 'h1'}>
              Teklif Verilmiş
            </Typography>
            <Typography color="textSecondary" variant="body2" align="center" sx={{ width: { xs: '73%', sm: '70%' }, mt: 1 }}>
              Bu sayfanın kullanım hakkı teklif oluşturma işlemi tamamlandığı için sona ermiştir. Lütfen yeni bir teklif oluşturmak için
              yeni bir teklif formu bağlantısı talep edin.
            </Typography>
            <Button
              onClick={() => {
                // close the tab
              }}
              variant="contained"
              sx={{ textTransform: 'none', mt: 4 }}
            >
              Tamam
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}

export default OfferExists;
