import { useQuery, useMutation, useQueryClient } from 'react-query';
import axios from 'utils/axios2';

const fetchQuotation = async (id) => {
  const { data } = await axios.get(`/api/quotations/${id}`);
  return data;
};

export const useQuotation = (id) => {
  return useQuery(['quotations', id], () => fetchQuotation(id), {
    cacheTime: 1 * 60 * 1000,
    staleTime: 1 * 60 * 1000
  });
};

const fetchCompanyQuotations = async (params = {}) => {
  const queryParams = new URLSearchParams(
    Object.entries(params).reduce((acc, [key, value]) => {
      if (value != null) {
        acc[key] = value;
      }
      return acc;
    }, {})
  ).toString();

  const { data } = await axios.get(`/api/quotations${queryParams ? `?${queryParams}` : ''}`);
  return data;
};

export const useCompanyQuotations = (params) => {
  return useQuery(['quotations', params], () => fetchCompanyQuotations(params), {
    cacheTime: 1 * 60 * 1000,
    staleTime: 1 * 60 * 1000,
    keepPreviousData: true
  });
};

const addCompanyQuotation = async (quotation) => {
  const { data } = await axios.post(`/api/quotations`, quotation);
  return data;
};

export const useAddCompanyQuotation = () => {
  const queryClient = useQueryClient();

  return useMutation(addCompanyQuotation, {
    onSuccess: () => {
      queryClient.invalidateQueries('companies');
      queryClient.invalidateQueries('quotations');
    }
  });
};

const updateCompanyQuotation = async (quotation) => {
  const { data } = await axios.put(`/api/quotations/${quotation.id}`, quotation);
  return data;
};

export const useUpdateCompanyQuotation = () => {
  const queryClient = useQueryClient();

  return useMutation(updateCompanyQuotation, {
    onSuccess: () => {
      queryClient.invalidateQueries('companies');
      queryClient.invalidateQueries('quotations');
    }
  });
};

const deleteCompanyQuotation = async (id) => {
  const { data } = await axios.delete(`/api/quotations/${id}`);
  return data;
};

export const useDeleteCompanyQuotation = () => {
  const queryClient = useQueryClient();

  return useMutation(deleteCompanyQuotation, {
    onSuccess: () => {
      queryClient.invalidateQueries('companies');
      queryClient.invalidateQueries('quotations');
    }
  });
};

const fetchCompanyQuotationAutocomplete = async (params = {}) => {
  const queryParams = new URLSearchParams(
    Object.entries(params).reduce((acc, [key, value]) => {
      if (value != null) {
        acc[key] = value;
      }
      return acc;
    }, {})
  ).toString();

  const { data } = await axios.get(`/api/quotations/autocomplete${queryParams ? `?${queryParams}` : ''}`);
  return data;
};

export const useCompanyQuotationAutocomplete = (params) => {
  return useQuery(['quotations', 'autocomplete', params], () => fetchCompanyQuotationAutocomplete(params), {
    cacheTime: 1 * 60 * 1000,
    staleTime: 1 * 60 * 1000
  });
};

export default useCompanyQuotations;
