import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { Drawer, Stack, useMediaQuery } from '@mui/material';
import FilterDrawerContent from './FilterDrawerContent';
import MainCard from 'components/MainCard';
import SimpleBar from 'components/third-party/SimpleBar';
import { HEADER_HEIGHT } from 'config';
import { ThemeMode } from 'config';
import { tableNames } from 'data/tableNames';
import IconButton from 'components/@extended/IconButton';
import CustomTooltip from 'components/@extended/Tooltip';
import useSavedFilters from 'hooks/sessionStorage/useSavedFilters';

// assets
import { FilterSearch, Add, SaveAdd } from 'iconsax-react';
import { enqueueSnackbar } from 'notistack';

function FilterDrawer({ table_name, filters, setFilters, handleDrawerOpen, openFilterDrawer }) {
  const theme = useTheme();
  const downLG = useMediaQuery(theme.breakpoints.down('lg'));
  const drawerBG = theme.palette.mode === ThemeMode.DARK ? 'dark.main' : 'white';
  const savedFilters = useSavedFilters();

  return (
    <Drawer
      sx={{
        flexShrink: 0,
        zIndex: 1200,
        '& .MuiDrawer-paper': {
          width: downLG ? 320 : 360,
          boxSizing: 'border-box',
          position: 'relative',
          boxShadow: 'none'
        }
      }}
      variant="temporary"
      anchor="left"
      open={openFilterDrawer}
      onClose={handleDrawerOpen}
      ModalProps={{ keepMounted: true }}
    >
      <MainCard
        title={
          <Stack spacing={1} direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
            <Stack direction={'row'} alignItems={'center'} spacing={1}>
              <FilterSearch variant="Linear" color={theme.palette.secondary.main} />
              <span>{tableNames[table_name]}</span>
            </Stack>
            <Stack direction={'row'} alignItems={'center'} spacing={1}>
              {Object.keys(filters)?.length > 0 && (
                <CustomTooltip title="Filtreyi Kaydet" arrow>
                  <IconButton
                    onClick={(e) => {
                      e.preventDefault();
                      console.log('clicked save filters', savedFilters);
                      if (Object.keys(filters)?.length === 0) {
                        enqueueSnackbar('Kaydetmek için filtreyi düzenleyin', { variant: 'warning' });
                        return;
                      } else if (
                        // if the filter already exists
                        savedFilters &&
                        savedFilters[table_name] &&
                        savedFilters[table_name].some((f) => JSON.stringify(f.filters) === JSON.stringify(filters))
                      ) {
                        enqueueSnackbar('Benzer bir filtre zaten var', { variant: 'warning' });
                      } else {
                        const uuid4 = crypto.randomUUID();
                        const newName = `Yeni Filtre (${savedFilters[table_name]?.length || 0 + 1})`;
                        sessionStorage.setItem(
                          'savedFilters',
                          JSON.stringify({
                            ...savedFilters,
                            [table_name]: [
                              ...(savedFilters[table_name] || []),
                              {
                                id: uuid4,
                                name: newName,
                                created_at: new Date(),
                                filters: filters
                              }
                            ]
                          })
                        );
                        window.dispatchEvent(new Event('storage'));
                        enqueueSnackbar(`${newName} başarıyla kaydedildi`, { variant: 'success' });
                      }
                    }}
                    color="warning"
                    sx={{ ml: 'auto' }}
                  >
                    <SaveAdd variant="Bulk" />
                  </IconButton>
                </CustomTooltip>
              )}

              <IconButton
                onClick={(e) => {
                  e.preventDefault();
                  handleDrawerOpen();
                }}
                color="secondary"
                sx={{ ml: 'auto' }}
              >
                <Add style={{ transform: 'rotate(45deg)' }} />
              </IconButton>
            </Stack>
          </Stack>
        }
        sx={{
          bgcolor: drawerBG,
          borderRadius: '4px 0 0 4px',
          borderRight: 'none'
        }}
        border
      >
        <SimpleBar sx={{ height: `calc(100vh - ${HEADER_HEIGHT}px)` }}>
          <FilterDrawerContent table_name={table_name} filters={filters} setFilters={setFilters} />
        </SimpleBar>
      </MainCard>
    </Drawer>
  );
}

FilterDrawer.propTypes = {
  filter: PropTypes.object,
  handleDrawerOpen: PropTypes.func,
  openFilterDrawer: PropTypes.bool,
  setFilter: PropTypes.func,
  setLoading: PropTypes.func
};

export default FilterDrawer;
