import { useQuery, useMutation, useQueryClient } from 'react-query';
import axios from 'utils/axios2';

const fetchMe = async () => {
  const { data } = await axios.get('/api/users/myself');
  return data;
};
export const useMe = () => {
  return useQuery('myself', fetchMe, {
    cacheTime: 1 * 60 * 1000,
    staleTime: 1 * 60 * 1000
  });
};

const fetchUser = async (id) => {
  const { data } = await axios.get(`/api/users/${id}`);
  return data;
};
export const useUserData = (id) => {
  console.log('id----', id);
  return useQuery(['user', id], () => fetchUser(id));
};

const fetchUsers = async (params = {}) => {
  const queryParams = new URLSearchParams(
    Object.entries(params).reduce((acc, [key, value]) => {
      if (value != null) {
        acc[key] = value;
      }
      return acc;
    }, {})
  ).toString();

  const { data } = await axios.get(`/api/users${queryParams ? `?${queryParams}` : ''}`);
  return data;
};
export const useUsersData = (params) => {
  return useQuery(['all-users', params], () => fetchUsers(params), {
    staleTime: 60 * 1000,
    cacheTime: 60 * 1000,
    keepPreviousData: true
  });
};

const fetchAutocompleteUsers = async (params = {}) => {
  const queryParams = new URLSearchParams(
    Object.entries(params).reduce((acc, [key, value]) => {
      if (value != null) {
        acc[key] = value;
      }
      return acc;
    }, {})
  ).toString();

  const { data } = await axios.get(`/api/users/autocomplete${queryParams ? `?${queryParams}` : ''}`);
  return data;
};

export const useAutocompleteUsers = (params) => {
  return useQuery(['autocomplete-users', params], () => fetchAutocompleteUsers(params), {
    enabled: params?.isFocused,
    staleTime: 60 * 1000,
    cacheTime: 60 * 1000
  });
};

const addUser = (user) => {
  console.log('addUser----');
  console.log('useMutation(addUser)----', addUser);
  return axios.post('/api/users', user);
};
export const useAddUser = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation(addUser, {
    onSuccess: () => {
      queryClient.invalidateQueries('all-users');
    }
  });
  return mutation;
};

const uploadAvatar = ({ body, onProgress }) => {
  const formData = new FormData();
  formData.append('avatar', body.avatar);

  console.log('avatar----', formData);
  return axios.post('/api/users/upload_avatar', formData, {
    onUploadProgress: (progressEvent) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      onProgress(percentCompleted);
    }
  });
};

export const useUploadAvatar = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation(uploadAvatar, {
    onSuccess: () => {
      queryClient.invalidateQueries(['myself']);
      queryClient.invalidateQueries(['users']);
    }
  });
  return mutation;
};

const updateUser = async (user) => {
  return await axios.put(`/api/users/${user.id}`, user);
};

export const useUpdateUser = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation(updateUser, {
    onSuccess: () => {
      queryClient.invalidateQueries('myself');
      queryClient.invalidateQueries('all-users');
      queryClient.invalidateQueries(['roles']);
    }
  });
  return mutation;
};

const deleteUser = async (id) => {
  return await axios.delete(`/api/users/${id}`);
};

export const useDeleteUser = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation(deleteUser, {
    onSuccess: () => {
      queryClient.invalidateQueries('all-users');
    }
  });
  return mutation;
};
