import { Stack } from '@mui/material';
import CreateOffer from 'pages/stock-management/offer/create-offer';
import OfferExists from 'pages/maintenance/error/offer-exists';
import EditOffer from 'pages/stock-management/offer/edit-offer';
import EnterOfferNanoId from 'pages/stock-management/offer/enter-offer-nanoid';

// ==============================|| SUPPLIER ROUTES ||============================== //

const SupplierRoutes = {
  path: '/',
  children: [
    {
      path: 'fill-offer/:offer_nanoid',
      element: (
        <Stack padding={4}>
          <CreateOffer />
        </Stack>
      )
    },
    {
      path: 'fill-offer',
      element: (
        <Stack padding={4}>
          <EnterOfferNanoId />
        </Stack>
      )
    },
    {
      path: 'teklif/:offer_nanoid',
      element: (
        <Stack padding={4}>
          <CreateOffer />
        </Stack>
      )
    },
    {
      path: 'teklif',
      element: (
        <Stack padding={4}>
          <EnterOfferNanoId />
        </Stack>
      )
    },
    {
      path: 'edit-offer/:offer_nanoid',
      element: (
        <Stack padding={4}>
          <EditOffer />
        </Stack>
      )
    },
    {
      path: 'offer-exists',
      element: <OfferExists />
    }
  ]
};

export default SupplierRoutes;
