// material-ui
import { TextField, Autocomplete, Chip, ListItem } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { paymentTypes } from 'data/paymentTypes';
import { getIn } from 'formik';

function PaymentTypeSelect({ selectedPaymentType, setSelectedPaymentType, onChange, formik, id, ...props }) {
  const theme = useTheme();
  return (
    <Autocomplete
      id="payment-type-select"
      value={selectedPaymentType || null}
      options={paymentTypes}
      getOptionLabel={(option) => option.name || option}
      onChange={(event, newValue) => {
        onChange != undefined ? onChange() : null;
        setSelectedPaymentType && setSelectedPaymentType(newValue);
      }}
      {...props}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Ödeme Tipi Ara"
          label=""
          error={getIn(formik?.errors, id) && getIn(formik?.touched, id)}
          helperText={getIn(formik?.errors, id) && getIn(formik?.touched, id) && getIn(formik?.errors, id)}
          sx={{ '& .MuiAutocomplete-input.Mui-disabled': { WebkitTextFillColor: theme.palette.text.primary } }}
        />
      )}
      renderOption={(props, option) => (
        <ListItem {...props}>
          <Chip label={option?.name || 'null'} color={option?.color || 'default'} />
        </ListItem>
      )}
    />
  );
}

export default PaymentTypeSelect;
