import { useQuery, useMutation, useQueryClient } from 'react-query';
import axios from 'utils/axios2';

const fetchMenuAccess = async () => {
  const { data } = await axios.get('/api/access/menu');
  return data;
};

export const useMenuAccess = () => {
  return useQuery('access-menu', fetchMenuAccess, {
    staleTime: 1 * 60 * 1000,
    cacheTime: 1 * 60 * 1000,
    keepPreviousData: true
  });
};

const fetchUrlAccess = async () => {
  const { data } = await axios.get('/api/access/url');
  return data;
};

export const useUrlAccess = () => {
  return useQuery('access-url', fetchUrlAccess, {
    staleTime: 5 * 60 * 1000,
    cacheTime: 1 * 60 * 1000,
    keepPreviousData: true
  });
};

const fetchAccessInfo = async (params = {}) => {
  const queryParams = new URLSearchParams(
    Object.entries(params).reduce((acc, [key, value]) => {
      if (value != null) {
        acc[key] = value;
      }
      return acc;
    }, {})
  ).toString();
  const { data } = await axios.get(`/api/access/info${queryParams ? `?${queryParams}` : ''}`);
  return data;
};

export const useAccessInfo = (params) => {
  return useQuery(['access-info', params], () => fetchAccessInfo(params), {
    enabled: params.id != null || params.key != null,

    staleTime: 1 * 60 * 1000
  });
};

const fetchAccess = async (params = {}) => {
  const queryParams = new URLSearchParams(
    Object.entries(params).reduce((acc, [key, value]) => {
      if (value != null) {
        acc[key] = value;
      }
      return acc;
    }, {})
  ).toString();

  const { data } = await axios.get(`/api/access${queryParams ? `?${queryParams}` : ''}`);
  return data;
};

export const useAccessData = (params) => {
  return useQuery(['access', params], () => fetchAccess(params), {
    staleTime: 1 * 60 * 1000
  });
};

const createAccess = async (payload) => {
  const { data } = await axios.post('/api/access', payload);
  return data;
};

export const useAddAccess = () => {
  const queryClient = useQueryClient();
  return useMutation(createAccess, {
    onSuccess: () => {
      queryClient.invalidateQueries('access');
      queryClient.invalidateQueries('access-info');
    }
  });
};

const updateAccess = async (payload) => {
  const { data } = await axios.put(`/api/access/${payload.id}`, payload);
  return data;
};

export const useUpdateAccess = () => {
  const queryClient = useQueryClient();
  return useMutation(updateAccess, {
    onSuccess: () => {
      queryClient.invalidateQueries('access');
      queryClient.invalidateQueries('access-info');
    }
  });
};

const deleteAccess = async (id) => {
  return await axios.delete(`/api/access/${id}`);
};

export const useDeleteAccess = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteAccess, {
    onSuccess: () => {
      queryClient.invalidateQueries('access');
    }
  });
};
