import { useQuery, useMutation, useQueryClient } from 'react-query';
import axios from 'utils/axios2';

// Function to fetch a single item by ID
const fetchProduct = async (id) => {
  const { data } = await axios.get(`/api/products/${id}`);
  return data;
};
export const useProductData = (id) => {
  return useQuery(['products', id], () => fetchProduct(id), {
    cacheTime: 2 * 1000,
    staleTime: 2 * 1000,
    keepPreviousData: true
  });
};

// Function to fetch with optional query parameters
const fetchProducts = async (params = {}) => {
  // Construct query parameters string, filtering out undefined or null values
  const queryParams = new URLSearchParams(
    Object.entries(params).reduce((acc, [key, value]) => {
      if (value != null) {
        // Check for null or undefined
        acc[key] = value;
      }
      return acc;
    }, {})
  ).toString();

  // Call the API with the constructed query string
  const { data } = await axios.get(`/api/products${queryParams ? `?${queryParams}` : ''}`);
  return data;
};

export const useProductsData = (params) => {
  return useQuery(['products', params], () => fetchProducts(params), {
    staleTime: 1000 * 60 * 1,
    cacheTime: 1000 * 60 * 1
  });
};

const fetchAutocompleteProducts = async (params = {}) => {
  const queryParams = new URLSearchParams(
    Object.entries(params).reduce((acc, [key, value]) => {
      if (value != null) {
        acc[key] = value;
      }
      return acc;
    }, {})
  ).toString();

  const { data } = await axios.get(`/api/products/autocomplete${queryParams ? `?${queryParams}` : ''}`);
  return data;
};

export const useAutocompleteProducts = (params) => {
  return useQuery(['products', 'autocomplete', params], () => fetchAutocompleteProducts(params), {
    enabled: params?.isFocused,
    staleTime: 60 * 1000,
    cacheTime: 60 * 1000,
    keepPreviousData: true
  });
};

const fetchAutocompleteProductTypes = async (params = {}) => {
  const queryParams = new URLSearchParams(
    Object.entries(params).reduce((acc, [key, value]) => {
      if (value != null) {
        acc[key] = value;
      }

      return acc;
    }, {})
  ).toString();

  const { data } = await axios.get(`/api/products/types/autocomplete${queryParams ? `?${queryParams}` : ''}`);
  return data;
};

export const useAutocompleteProductTypes = (params) => {
  return useQuery(['products', 'autocomplete', 'types', params], () => fetchAutocompleteProductTypes(params), {
    enabled: params?.isFocused,
    staleTime: 60 * 1000,
    cacheTime: 60 * 1000,
    keepPreviousData: true
  });
};

const fetchAutocompleteProductNames = async (params = {}) => {
  const queryParams = new URLSearchParams(
    Object.entries(params).reduce((acc, [key, value]) => {
      if (value != null) {
        acc[key] = value;
      }
      return acc;
    }, {})
  ).toString();

  const { data } = await axios.get(`/api/products/names/autocomplete${queryParams ? `?${queryParams}` : ''}`);
  return data;
};

export const useAutocompleteProductNames = (params) => {
  return useQuery(['products', 'autocomplete', 'names', params], () => fetchAutocompleteProductNames(params), {
    enabled: params?.isFocused,
    staleTime: 60 * 1000,
    cacheTime: 60 * 1000,
    keepPreviousData: true
  });
};

const addProduct = async (product) => {
  return await axios.post('/api/products', product);
};

export const useAddProduct = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: addProduct,
    onSuccess: () => {
      queryClient.invalidateQueries(['products']);
    }
  });
  return mutation;
};

const bulkAddProducts = async (products) => {
  return await axios.post('/api/products/bulk', products);
};

export const useBulkAddProducts = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: bulkAddProducts,
    onSuccess: () => {
      queryClient.invalidateQueries(['products']);
    }
  });
  return mutation;
};

const updateProduct = async (product) => {
  return await axios.put(`/api/products/${product.id}`, product);
};

export const useUpdateProduct = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: updateProduct,
    onSuccess: () => {
      queryClient.invalidateQueries(['products']);
    }
  });
  return mutation;
};

const deleteProduct = async (id) => {
  return await axios.delete(`/api/products/${id}`);
};

export const useDeleteProduct = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation(deleteProduct, {
    onSuccess: () => {
      queryClient.invalidateQueries(['products']);
    }
  });
  return mutation;
};

const uploadProductImage = ({ body, onProgress }) => {
  const formData = new FormData();
  formData.append('image', body.image);

  console.log('product-image----', formData);
  return axios.post(`/api/products/${body.product_id}/upload_image`, formData, {
    onUploadProgress: (progressEvent) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      onProgress(percentCompleted);
    }
  });
};

export const useUploadProductImage = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation(uploadProductImage, {
    onSuccess: () => {
      queryClient.invalidateQueries(['products']);
    }
  });
  return mutation;
};
