// material-ui
import { TextField, Autocomplete, Chip, ListItem } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import useSavedFilters from 'hooks/sessionStorage/useSavedFilters';

function FilterSelect({ table_name, selectedFilters, setSelectedFilters, onChange }) {
  const theme = useTheme();
  const savedFilters = useSavedFilters();
  const options = savedFilters?.[table_name] || [];
  return (
    <Autocomplete
      id="filter-select"
      fullWidth
      value={selectedFilters || null}
      options={options}
      noOptionsText="Henüz kaydedilmiş bir filtreniz yok"
      getOptionLabel={(option) => option?.name}
      onChange={(event, newValue) => {
        onChange != undefined ? onChange() : null;
        setSelectedFilters && setSelectedFilters(newValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Kaydedilmiş Filtre Ara"
          label=""
          sx={{ '& .MuiAutocomplete-input.Mui-disabled': { WebkitTextFillColor: theme.palette.text.primary } }}
        />
      )}
      renderOption={(props, option) => (
        <ListItem {...props} divider={option?.default}>
          <Chip color={option?.default ? 'info' : 'secondary'} label={option?.name || 'null'} /* color={option?.color || 'default'}  */ />
        </ListItem>
      )}
    />
  );
}

export default FilterSelect;
