import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import {
  Drawer,
  Stack,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  CardMedia,
  CardContent
} from '@mui/material';
import { useState } from 'react';
import { Add, Copyright, ArrowDown2, ArrowRight2, Note, Briefcase } from 'iconsax-react';

import MainCard from 'components/MainCard';
import SimpleBar from 'components/third-party/SimpleBar';
import RequestItem from './RequestItem';
import OfferItem from './OfferItem';
import { HEADER_HEIGHT } from 'config';
import { ThemeMode } from 'config';
import IconButton from 'components/@extended/IconButton';

function SupplierDrawer({ data, handleSupplierDrawerOpen, isSupplierDrawerOpen }) {
  const theme = useTheme();
  const [open, setOpen] = useState('');
  const drawerBG = theme.palette.mode === ThemeMode.DARK ? 'dark.main' : 'white';

  const { requests, offers } = data?.drawer_data || { requests: [], offers: [] };

  const handleClick = (section) => {
    setOpen(open === section ? '' : section);
  };

  return (
    <Drawer
      sx={{
        flexShrink: 0,
        zIndex: 1200,
        '& .MuiDrawer-paper': {
          width: 360,
          boxSizing: 'border-box',
          position: 'relative',
          boxShadow: 'none'
        }
      }}
      variant="temporary"
      anchor="left"
      open={isSupplierDrawerOpen}
      onClose={handleSupplierDrawerOpen}
      ModalProps={{ keepMounted: true }}
    >
      <MainCard
        sx={{
          bgcolor: drawerBG,
          borderRadius: '4px 0 0 4px',
          borderRight: 'none'
        }}
        border
        content={false}
        title={
          <Stack spacing={1} direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
            <Stack direction={'row'} alignItems={'center'} spacing={1}>
              <Copyright variant="Linear" color={theme.palette.secondary.main} />
              <Typography variant="h5">{data?.supplier?.name || 'Tedarikçi'}</Typography>
            </Stack>
            <IconButton
              onClick={(e) => {
                e.preventDefault();
                handleSupplierDrawerOpen();
              }}
              color="secondary"
              sx={{ ml: 'auto' }}
            >
              <Add style={{ transform: 'rotate(45deg)' }} />
            </IconButton>
          </Stack>
        }
      >
        {data?.supplier?.logo_url && (
          <CardMedia component="img" image={`${process.env.REACT_APP_API_URL}${data?.supplier?.logo_url}`} sx={{ height: 200 }} />
        )}
        <CardContent>
          <SimpleBar sx={{ height: `calc(100vh - ${HEADER_HEIGHT}px)` }}>
            <List>
              {/* Requests Section */}
              <ListItem disablePadding>
                <ListItemButton selected={open === 'requests'} onClick={() => handleClick('requests')}>
                  <ListItemIcon>
                    <Note />
                  </ListItemIcon>
                  <ListItemText primary={`Talepler (${requests.length})`} />
                  {open === 'requests' ? <ArrowDown2 style={{ fontSize: '0.75rem' }} /> : <ArrowRight2 style={{ fontSize: '0.75rem' }} />}
                </ListItemButton>
              </ListItem>
              <Collapse in={open === 'requests'} timeout="auto" unmountOnExit>
                <List>
                  {requests.length > 0 ? (
                    requests.map((request, index) => <RequestItem key={`request-${index}`} request={request} />)
                  ) : (
                    <ListItem sx={{ pl: 4 }}>
                      <Typography sx={{ color: 'text.secondary' }}>Talep bulunmamaktadır.</Typography>
                    </ListItem>
                  )}
                </List>
              </Collapse>

              {/* Offers Section */}
              <ListItem disablePadding>
                <ListItemButton selected={open === 'offers'} onClick={() => handleClick('offers')}>
                  <ListItemIcon>
                    <Briefcase />
                  </ListItemIcon>
                  <ListItemText primary={`Teklifler (${offers.length})`} />
                  {open === 'offers' ? <ArrowDown2 style={{ fontSize: '0.75rem' }} /> : <ArrowRight2 style={{ fontSize: '0.75rem' }} />}
                </ListItemButton>
              </ListItem>
              <Collapse in={open === 'offers'} timeout="auto" unmountOnExit>
                <List>
                  {offers.length > 0 ? (
                    offers.map((offer, index) => <OfferItem key={`offer-${index}`} offer={offer} />)
                  ) : (
                    <ListItem sx={{ pl: 4 }}>
                      <Typography sx={{ color: 'text.secondary' }}>Teklif bulunmamaktadır.</Typography>
                    </ListItem>
                  )}
                </List>
              </Collapse>
            </List>
          </SimpleBar>
        </CardContent>
      </MainCard>
    </Drawer>
  );
}

SupplierDrawer.propTypes = {
  data: PropTypes.object,
  handleSupplierDrawerOpen: PropTypes.func,
  isSupplierDrawerOpen: PropTypes.bool
};

export default SupplierDrawer;
