import { useMutation, useQueryClient } from 'react-query';
import axios from 'utils/axios2';

const addApprove = (approve) => {
  return axios.post('/api/approves', approve);
};

export const useAddApprove = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: addApprove,
    onSuccess: (data, variables) => {
      const { table_name, object_id } = variables;

      queryClient.invalidateQueries(['movement_panel']);

      if (table_name) {
        console.log('Approving and Invalidating table:', table_name, 'with object ID:', object_id);

        // Invalidate the specific table and object id if it exists
        queryClient.invalidateQueries(table_name);
      }
    }
  });

  return mutation;
};
