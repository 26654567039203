import jsPDF from 'jspdf';
import 'jspdf-autotable';
import 'assets/fonts/Roboto-Light-normal';
import 'assets/fonts/Roboto-Bold-normal';
import 'assets/fonts/Roboto-Regular-normal';
import 'assets/fonts/Roboto-Thin-normal';
import 'assets/fonts/Roboto-BoldItalic-normal';
import { formatNumber } from 'utils/utkuFunctions';
import { format } from 'date-fns';
import { currencySymbol } from 'utils/currencyFunction';
import { htmlToText } from 'html-to-text';
import { max } from 'mathjs';

const renderLongText = (pdf, html, x, y, maxLineWidth, lineHeight) => {
  const text = htmlToText(html, {
    wordwrap: false // Prevent line breaks in converted text
  });

  var formattedText = text.replace(/\*/g, '•');

  var fontSize = pdf.internal.getFontSize();
  var textWidth = (pdf.getStringUnitWidth(formattedText) * fontSize) / pdf.internal.scaleFactor;

  if (textWidth > maxLineWidth) {
    var lines = pdf.splitTextToSize(formattedText, maxLineWidth);
    lines.forEach((lineText) => {
      pdf.text(lineText, x, y);
      y += lineHeight;
    });
    return y;
  } else {
    pdf.text(formattedText, x, y);
    return y + lineHeight;
  }
};

const OfferPdfGenerator = (offer) => {
  console.log(offer);
  const lineHeight = 7;

  const pdf = new jsPDF();

  pdf.setProperties({
    title: 'TEKLİF FORMU'
  });

  const titleStartLine = offer.supplier?.logo_url ? 42 : 12;

  pdf.setFontSize(14);
  pdf.setFont('Roboto-Bold');
  var text = `TEKLİF FORMU`;
  pdf.text(text, pdf.internal.pageSize.width / 2, titleStartLine, { align: 'center' });
  pdf.setFontSize(10);

  var line = titleStartLine + 11;

  line += lineHeight;

  pdf.setLineWidth(0.1);
  pdf.setDrawColor(200, 200, 200);

  pdf.setFontSize(10);
  pdf.setFont('Roboto-Bold');
  pdf.text('Firma Adresi', 13, line);
  pdf.text(':', 40, line);
  const maxSupplierInfoLineWidth = (pdf.internal.pageSize.width * 1) / 3 - 13 - 13;
  pdf.setFont('Roboto-Light');
  line = renderLongText(pdf, `${offer.supplier.address}`, 45, line, maxSupplierInfoLineWidth, lineHeight);

  pdf.setFont('Roboto-Bold');
  pdf.text('Telefon', 13, line);
  pdf.text(':', 40, line);
  pdf.setFont('Roboto-Light');
  pdf.text(`${offer.supplier.phone}`, 45, line);

  line += lineHeight;

  pdf.setFont('Roboto-Bold');
  pdf.text('Vergi Dairesi', 13, line);
  pdf.text(':', 40, line);
  pdf.setFont('Roboto-Light');
  pdf.text(`${offer.supplier.tax_office}`, 45, line);

  line += lineHeight;

  pdf.setFont('Roboto-Bold');
  pdf.text('Vergi Numarası', 13, line);
  pdf.text(':', 40, line);
  pdf.setFont('Roboto-Light');
  pdf.text(`${offer.supplier.vat_number}`, 45, line);

  line += lineHeight;

  const mappedOfferedProducts = offer.offered_products.map((item, index) => [
    (index + 1).toString(),
    `${item.product.name} ${item.offered_brand} ${item.offered_model}`,
    format(new Date(item.termin_date), 'dd.MM.yyyy').toString(),
    formatNumber(item.offered_amount).toString(),
    item.product?.unit?.toString(),
    formatNumber(item.price).toString(),
    item.currency_code?.toString(),
    `%${item.vat}`,
    `${formatNumber(item.price * item.offered_amount)}`
  ]);
  const barterHeaderStyles = {
    fillColor: [255],
    textColor: [0],
    fontFamily: 'Roboto-Bold',
    fontStyle: 'bold'
  };
  const columnWidths = [10, 50, 25, 20, 15, 20, 15, 15];
  const offeredProductHeaders = [
    'Sıra',
    'Malzeme Adı',
    'Teslimat Tarihi',
    'Miktar',
    'Birim',
    'Birim Fiyat',
    'Para Birimi',
    'KDV',
    'Satır Tutarı'
  ];
  pdf.autoTable({
    margin: { left: 10, right: 10 },
    head: [offeredProductHeaders],
    body: mappedOfferedProducts,
    startY: line, // Adjust the Y position as needed
    headStyles: {
      fillColor: barterHeaderStyles.fillColor,
      textColor: barterHeaderStyles.textColor,
      fontStyle: barterHeaderStyles.fontStyle,
      fontSize: 8,
      font: 'Roboto-Bold',
      halign: 'center',
      lineWidth: 0.3,
      valign: 'middle'
    },
    columnStyles: {
      0: { cellWidth: columnWidths[0] },
      1: { cellWidth: columnWidths[1], halign: 'left' },
      2: { cellWidth: columnWidths[2] },
      3: { cellWidth: columnWidths[3] },
      4: { cellWidth: columnWidths[4] },
      5: { cellWidth: columnWidths[5] },
      6: { cellWidth: columnWidths[6] },
      7: { cellWidth: columnWidths[7] },
      8: { cellWidth: columnWidths[8], halign: 'right', headStyles: { halign: 'right' } }
    },
    bodyStyles: {
      fontSize: 8,
      font: 'Roboto-Light',
      cellPadding: { top: 1, right: 2, bottom: 1, left: 2 },
      textColor: [0, 0, 0],
      rowPageBreak: 'avoid',
      halign: 'center',
      valign: 'middle',
      lineWidth: 0.3
    }
  });
  line = pdf.lastAutoTable.finalY;

  const totalPriceLineHeight = 5;

  line += totalPriceLineHeight;
  pdf.setFontSize(10);
  pdf.setFont('Roboto-Bold');
  pdf.text(`${currencySymbol('TRY')} ${formatNumber(offer.total_price)}`, (pdf.internal.pageSize.width * 4) / 5, line);
  line += totalPriceLineHeight - 2;
  pdf.setFontSize(8);
  pdf.setFont('Roboto-Light');
  pdf.text(`Toplam Mal Hizmet Bedeli`, (pdf.internal.pageSize.width * 4) / 5, line);

  line += totalPriceLineHeight;
  pdf.setFontSize(10);
  pdf.setFont('Roboto-Bold');
  pdf.text(`${currencySymbol('TRY')} ${formatNumber(offer.total_discount)}`, (pdf.internal.pageSize.width * 4) / 5, line);
  line += totalPriceLineHeight - 2;
  pdf.setFontSize(8);
  pdf.setFont('Roboto-Light');
  pdf.text(`Toplam İskonto`, (pdf.internal.pageSize.width * 4) / 5, line);

  line += totalPriceLineHeight;
  pdf.setFontSize(10);
  pdf.setFont('Roboto-Bold');
  pdf.text(`${currencySymbol('TRY')} ${formatNumber(offer.total_vat)}`, (pdf.internal.pageSize.width * 4) / 5, line);
  line += totalPriceLineHeight - 2;
  pdf.setFontSize(8);
  pdf.setFont('Roboto-Light');
  pdf.text(`Toplam KDV`, (pdf.internal.pageSize.width * 4) / 5, line);

  line += totalPriceLineHeight;
  pdf.setFontSize(10);
  pdf.setFont('Roboto-Bold');
  pdf.text(`${currencySymbol('TRY')} ${formatNumber(offer.total_tevkifat)}`, (pdf.internal.pageSize.width * 4) / 5, line);
  line += totalPriceLineHeight - 2;
  pdf.setFontSize(8);
  pdf.setFont('Roboto-Light');
  pdf.text(`Toplam Tevkifat`, (pdf.internal.pageSize.width * 4) / 5, line);

  line += totalPriceLineHeight;
  pdf.setFontSize(10);
  pdf.setFont('Roboto-Bold');
  pdf.text(`${currencySymbol('TRY')} ${formatNumber(offer.grand_total)}`, (pdf.internal.pageSize.width * 4) / 5, line);
  line += totalPriceLineHeight - 2;
  pdf.setFontSize(8);
  pdf.setFont('Roboto-Light');
  pdf.text(`Vergiler Dahil Toplam Tutar`, (pdf.internal.pageSize.width * 4) / 5, line);

  line += totalPriceLineHeight;
  pdf.setFontSize(10);
  pdf.setFont('Roboto-Bold');
  pdf.text(`${currencySymbol('TRY')} ${formatNumber(offer.net_total)}`, (pdf.internal.pageSize.width * 4) / 5, line);
  line += totalPriceLineHeight - 2;
  pdf.setFontSize(8);
  pdf.setFont('Roboto-Light');
  pdf.text(`Net Ödenecek Tutar`, (pdf.internal.pageSize.width * 4) / 5, line);

  const lineAfterPrice = line;

  line = pdf.lastAutoTable.finalY;
  line += lineHeight;
  pdf.setFontSize(10);
  pdf.setFont('Roboto-Bold');
  pdf.text('ÖDEME KOŞULLARI: ', 13, line);
  line += lineHeight;
  const maxConditionsLineWidth = (pdf.internal.pageSize.width * 4) / 5 - 13 - 13;
  pdf.setFont('Roboto-Light');
  line = renderLongText(pdf, `${offer.conditions}`, 13, line, maxConditionsLineWidth, lineHeight);

  line = max(line, lineAfterPrice);

  line += lineHeight * 2;
  pdf.setFontSize(10);
  pdf.setFont('Roboto-Bold');
  pdf.text('ONAYLAYAN', pdf.internal.pageSize.width / 2, line, { align: 'center' });

  pdf.setFont('Roboto-Light');
  pdf.text(`Tarih: ${format(new Date(), 'dd.MM.yyyy')}`, (pdf.internal.pageSize.width * 4) / 5, line);

  if (offer.supplier?.logo_url) {
    const img = new Image();
    img.src = `${process.env.REACT_APP_API_URL + offer.supplier?.logo_url}`;

    img.onerror = (error) => {
      console.log('Image failed to load', error);
    };

    img.onload = () => {
      // Add the base64 image to the PDF
      const maxWidth = (pdf.internal.pageSize.width * 2) / 7;
      const maxHeight = 40;
      let imgWidth = img.width;
      let imgHeight = img.height;

      // If the width exceeds the max width, scale down while maintaining aspect ratio
      if (imgWidth > maxWidth || imgHeight > maxHeight) {
        const widthRatio = maxWidth / imgWidth;
        const heightRatio = maxHeight / imgHeight;

        // Use the smaller ratio to maintain aspect ratio and fit within the max dimensions
        const scaleFactor = Math.min(widthRatio, heightRatio);

        imgWidth = imgWidth * scaleFactor;
        imgHeight = imgHeight * scaleFactor;
      }

      pdf.addImage(img, 'PNG', 13, 1, imgWidth, imgHeight); // X=10, Y=10, width=50, height=20
      window.open(pdf.output('bloburi'), '_blank');
    };
  } else {
    window.open(pdf.output('bloburi'), '_blank');
  }
};

export default OfferPdfGenerator;
