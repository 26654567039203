import { Button, Grid, InputLabel, Stack, TextField } from '@mui/material';

// project-imports
import MainCard from 'components/MainCard';
import AnimateButton from 'components/@extended/AnimateButton';

// third-party
import { useFormik } from 'formik';
import * as yup from 'yup';
import { FormattedMessage } from 'react-intl';
import useSavedFilters from 'hooks/sessionStorage/useSavedFilters';
import { enqueueSnackbar } from 'notistack';

// assets

const validationSchema = yup.object({
  name: yup.string().required('Filtreyi adı gereklidir')
});

// ==============================|| FORM VALIDATION - INSTANT FEEDBACK  ||============================== //

const FilterCreation = ({ itemToUpdate, table_name, setCreationVisible }) => {
  const savedFilters = useSavedFilters();
  const formik = useFormik({
    initialValues: {
      name: itemToUpdate?.name || ''
    },
    validationSchema,
    onSubmit: () => {
      console.log('formik.values', formik.values);
      const payload = {
        name: formik.values.name
      };
      if (itemToUpdate != undefined) {
        // update the selected filters attributes with payload
        const updatedFilters = savedFilters[table_name].map((filter) =>
          filter.name === itemToUpdate.name ? { ...filter, ...payload } : filter
        );

        // update the saved filters with the updated filters
        sessionStorage.setItem('savedFilters', JSON.stringify({ ...savedFilters, [table_name]: updatedFilters }));
        enqueueSnackbar('Filtre başarıyla güncellendi', { variant: 'success' });
      } else {
        // add the new filter to the saved filters
        const newFilter = { name: formik.values.name, filters: {} };
        sessionStorage.setItem(
          'savedFilters',
          JSON.stringify({ ...savedFilters, [table_name]: [...(savedFilters[table_name] || []), newFilter] })
        );
        enqueueSnackbar('Filtre başarıyla eklendi', { variant: 'success' });
      }
      window.dispatchEvent(new Event('storage'));
      setCreationVisible(false);
    }
  });

  return (
    <MainCard title={itemToUpdate != undefined ? 'Filtre Güncelle' : 'Filtre Tanımlama'}>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <Stack spacing={1}>
              <InputLabel htmlFor="topic">Filtre Adı</InputLabel>
              <TextField
                autoComplete="off"
                fullWidth
                id="name"
                name="name"
                placeholder="Özel filtrenize bir ad verin"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <Stack direction="row" justifyContent="flex-end">
              <AnimateButton>
                <Button variant="contained" type="submit" color="primary">
                  <FormattedMessage id={itemToUpdate != undefined ? 'update' : 'add'} />
                </Button>
              </AnimateButton>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </MainCard>
  );
};

export default FilterCreation;
