import React, { createContext, useContext, useState } from 'react';

// Create a context for the Joyride state
const JoyrideContext = createContext();

// Custom hook to use the Joyride context
export const useJoyride = () => {
  return useContext(JoyrideContext);
};

// Joyride provider component to wrap your app
export const JoyrideProvider = ({ children }) => {
  const [run, setRun] = useState(true);

  return <JoyrideContext.Provider value={{ run, setRun }}>{children}</JoyrideContext.Provider>;
};
