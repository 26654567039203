// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { Diagram, HomeTrendUp, CardPos } from 'iconsax-react';

// icons
const icons = {
  realty: HomeTrendUp,
  reports: Diagram,
  payments: CardPos
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const sos = {
  id: 'sos',
  title: 'SOS',
  type: 'group',

  children: [
    {
      id: 'realty',
      title: <FormattedMessage id="realty" />,
      type: 'item',
      url: '/realty',
      icon: icons.realty
    },
    {
      id: 'reports',
      title: <FormattedMessage id="sales-reports" />,
      type: 'item',
      url: '/reports',
      icon: icons.reports
    },
    {
      id: 'payments',
      title: <FormattedMessage id="payments" />,
      type: 'item',
      url: '/payments',
      icon: icons.payments
    }
  ]
};

export default sos;
