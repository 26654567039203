import React from 'react';
import { Stack } from '@mui/material';
import Avatar from 'components/@extended/Avatar';
import CustomTooltip from 'components/@extended/Tooltip';

const ProductImageCell = ({ image_url, avatarProps }) => {
  if (image_url == null) return null;

  return (
    <CustomTooltip
      arrow
      color="primary"
      title={
        <Stack>
          <img src={process.env.REACT_APP_API_URL + image_url} alt="product" style={{ width: null, height: 250 }} />
        </Stack>
      }
    >
      <Stack direction={'row'} spacing={1} alignItems={'center'}>
        <Avatar size="xs" src={process.env.REACT_APP_API_URL + image_url} {...avatarProps}></Avatar>
      </Stack>
    </CustomTooltip>
  );
};

export default ProductImageCell;
