// material-ui
import { TextField, Autocomplete, Chip, ListItem } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { notificationTypes } from 'data/notificationTypes';

const options = Object.values(notificationTypes);

function NotificationTypeSelect({ selectedNotificationType, setSelectedNotificationType, onChange }) {
  const theme = useTheme();
  return (
    <Autocomplete
      id="notification-type-select"
      value={selectedNotificationType || null}
      options={options}
      getOptionLabel={(op) => op.label}
      onChange={(event, newValue) => {
        onChange != undefined ? onChange() : null;
        setSelectedNotificationType && setSelectedNotificationType(newValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Bildirim Tipi Ara"
          label=""
          sx={{ '& .MuiAutocomplete-input.Mui-disabled': { WebkitTextFillColor: theme.palette.text.primary } }}
        />
      )}
      renderOption={(props, option) => (
        <ListItem {...props}>
          <Chip icon={option?.icon} label={option?.label || 'null'} color={option?.color || 'default'} />
        </ListItem>
      )}
    />
  );
}

export default NotificationTypeSelect;
