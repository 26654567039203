// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { KyberNetwork, Messages2, Calendar1, Kanban, Profile2User, Bill, UserSquare, ShoppingBag, Notification } from 'iconsax-react';

// icons
const icons = {
  applications: KyberNetwork,
  chat: Messages2,
  calendar: Calendar1,
  kanban: Kanban,
  customer: Profile2User,
  notifications: Notification,
  invoice: Bill,
  profile: UserSquare,
  ecommerce: ShoppingBag,
  tasks: Kanban
};

// ==============================|| MENU ITEMS - APPLICATIONS ||============================== //

const applications = {
  id: 'applications',
  title: <FormattedMessage id="applications" />,
  type: 'group',
  children: [
    {
      id: 'profile',
      title: <FormattedMessage id="profile" />,
      type: 'item',
      icon: icons.profile,
      url: '/profile'
    },
    {
      id: 'notifications',
      title: <FormattedMessage id="notifications" />,
      type: 'item',
      icon: icons.notifications,
      url: '/notifications'
    },
    {
      id: 'chat',
      title: <FormattedMessage id="chat" />,
      type: 'item',
      icon: icons.chat,
      url: '/chat'
    }
    /* {
      id: 'tasks',
      title: <FormattedMessage id="tasks" />,
      type: 'item',
      icon: icons.tasks,
      url: '/tasks'
    } */
  ]
};

export default applications;
