import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';

// mui
import { Grid, Stack, FormHelperText, InputLabel, TextField, Button, Typography, Switch, FormControlLabel } from '@mui/material';

// project imports
import UploadMultiFile from 'components/third-party/dropzone/MultiFile';
import { useUpdateDocument, useUploadDocument } from 'hooks/document/useDocumentData';
import MainCard from 'components/MainCard';
import AnimateButton from 'components/@extended/AnimateButton';
import LinearWithLabel from 'components/@extended/progress/LinearWithLabel';

// third-party
import { useFormik } from 'formik';
import * as yup from 'yup';
import { FormattedMessage } from 'react-intl';
import ProjectSelect from 'components/select-boxes/ProjectSelect';
import { Global, Lock } from 'iconsax-react';
import CustomTooltip from 'components/@extended/Tooltip';
import useAuth from 'hooks/useAuth';

// assets

const validationSchema = (itemToUpdate) =>
  yup.object({
    files: yup
      .array()
      .test('files-required-if-not-folder', 'Lütfen dosya seçiniz', function (value) {
        const { is_folder } = this.parent;
        if (is_folder == 0 && !itemToUpdate && (!value || value.length < 1)) {
          return false;
        }
        return true;
      })
      .max(1, 'Tek seferde en fazla 1 dosya seçebilirsiniz'),
    name: yup.string().required('Lütfen bir ad giriniz'),
    description: yup.string().nullable(),
    project: yup.object().nullable(),
    is_private: yup.number().oneOf([0, 1]).required(),
    is_folder: yup.number().oneOf([0, 1]).required(),
    parent_id: yup.number().nullable()
  });

const AddDocument = ({ itemToUpdate, selectedFolder, onCancel, table_name, object_id, setIsChanged, nano_id }) => {
  // get nano id from the url
  const { isLoggedIn } = useAuth();
  console.log('nano_id', nano_id);
  const { mutate: uploadDocument, data: uploadResponse } = useUploadDocument();
  const { mutate: updateDocument, data: updateResponse } = useUpdateDocument();

  useEffect(() => {
    if (updateResponse?.data?.success) {
      setIsChanged(false);
      onCancel && onCancel();
    }
  }, [updateResponse]);

  useEffect(() => {
    if (uploadResponse?.data?.success) {
      setIsChanged(false);
      onCancel && onCancel();
    }
  }, [uploadResponse]);

  const handleFileDrop = (acceptedFiles, setFieldValue) => {
    const fileKey = acceptedFiles[0]?.path;
    if (fileKey) {
      setFieldValue('name', fileKey);
    }
  };
  const [progress, setProgress] = useState(0);

  const onProgress = (percentCompleted) => {
    setProgress(percentCompleted);
  };

  const formik = useFormik({
    initialValues: {
      files: itemToUpdate?.files || [],
      name: itemToUpdate?.name || '',
      description: itemToUpdate?.description || '',
      project: itemToUpdate?.project || null,
      is_private: itemToUpdate?.is_private || 0,
      is_folder: itemToUpdate?.is_folder || 0,
      parent_id: itemToUpdate?.parent_id || selectedFolder?.id || null
    },
    validationSchema: validationSchema(itemToUpdate),
    onSubmit: (values) => {
      console.log('onSubmit console.log test', values);
      let payload = {
        nano_id: nano_id,
        document: values?.files && values?.files[0],
        name: values.name,
        description: values.description,
        project_id: values.project?.id,
        is_private: values.is_private,
        is_folder: values.is_folder,
        parent_id: values.parent_id
      };

      object_id && (payload.object_id = object_id);
      table_name && (payload.table_name = table_name);

      // remove null values from payload
      Object.keys(payload).forEach((key) => payload[key] == null && delete payload[key]);

      console.log('payload', payload);

      if (itemToUpdate) {
        updateDocument({ ...payload, id: itemToUpdate.id });
      } else {
        uploadDocument({ payload, onProgress });
      }
    }
  });

  useEffect(() => {
    setIsChanged(formik.dirty);
  }, [formik.dirty, setIsChanged]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <MainCard
        title={
          formik.values?.is_folder == 0 ? (
            itemToUpdate == null ? (
              <FormattedMessage id="upload-a-document" />
            ) : (
              <FormattedMessage id="update-your-document" />
            )
          ) : itemToUpdate == null ? (
            <FormattedMessage id="create-a-folder" />
          ) : (
            <FormattedMessage id="update-your-folder" />
          )
        }
      >
        <Grid container spacing={3}>
          {isLoggedIn && (
            <Grid item xs={12} lg={12}>
              <Stack spacing={1} direction={'row'} alignItems={'center'} justifyContent={'center'}>
                <Stack
                  style={{ opacity: formik.values?.is_folder == 1 ? 0.4 : 1 }}
                  spacing={1}
                  direction={'row'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <img alt={'documenticon'} src={'/fileIcons/img-file-pdf.svg'} />
                  <InputLabel>Belge</InputLabel>
                </Stack>
                <FormControlLabel
                  control={
                    <Switch
                      disabled={itemToUpdate != undefined}
                      checked={formik.values?.is_folder == 1}
                      onChange={(e) => formik.setFieldValue('is_folder', e.target.checked ? 1 : 0)}
                      name="is_folder"
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                  label={
                    <Stack
                      style={{ opacity: formik.values?.is_folder == 0 ? 0.4 : 1 }}
                      spacing={1}
                      direction={'row'}
                      alignItems={'center'}
                      justifyContent={'center'}
                    >
                      <InputLabel>Klasör</InputLabel>
                      <img alt={'foldericon'} src={'/fileIcons/folder.svg'} width={64} />
                    </Stack>
                  }
                  labelPlacement="end"
                />
              </Stack>
            </Grid>
          )}
          <Grid item xs={12}>
            {!itemToUpdate && formik.values?.is_folder == 0 && (
              <Stack spacing={1} alignItems="center" mt={2}>
                <UploadMultiFile
                  showList={false}
                  setFieldValue={formik.setFieldValue}
                  onFileDrop={(acceptedFiles) => handleFileDrop(acceptedFiles, formik.setFieldValue)}
                  files={formik.values.files}
                  error={formik.touched.files && !!formik.errors.files}
                />
                {formik.touched.files && formik.errors.files && (
                  <FormHelperText error id="standard-weight-helper-text-password-login">
                    {formik.errors.files}
                  </FormHelperText>
                )}
              </Stack>
            )}
          </Grid>

          <Grid item xs={12} lg={6}>
            <Stack spacing={1}>
              <InputLabel>
                {formik.values?.is_folder == 1 ? <FormattedMessage id="folder-name" /> : <FormattedMessage id="document-name" />}
              </InputLabel>

              <TextField
                autoComplete="off"
                fullWidth
                id="name"
                name="name"
                placeholder={formik.values?.is_folder == 1 ? 'Klasöre bir ad verin' : 'Belgeye bir ad verin'}
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </Stack>
          </Grid>
          {isLoggedIn && (
            <Grid item xs={12} lg={6}>
              <Stack spacing={1}>
                <InputLabel>Üst Klasör ID&apos;si</InputLabel>

                <TextField
                  autoComplete="off"
                  fullWidth
                  id="parent_id"
                  name="parent_id"
                  placeholder="Üst klasör ID'si"
                  value={formik.values.parent_id}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.parent_id && Boolean(formik.errors.parent_id)}
                  helperText={formik.touched.parent_id && formik.errors.parent_id}
                />
              </Stack>
            </Grid>
          )}
          <Grid item xs={12} lg={isLoggedIn ? 6 : 12}>
            <Stack spacing={1}>
              <InputLabel htmlFor="name">
                <FormattedMessage id="description" />
              </InputLabel>
              <TextField
                autoComplete="off"
                fullWidth
                id="description"
                name="description"
                placeholder={formik.values?.is_folder == 1 ? 'Klasör açıklaması' : 'Belge bir açıklama girin'}
                value={formik.values.description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.description && Boolean(formik.errors.description)}
                helperText={formik.touched.description && formik.errors.description}
              />
            </Stack>
          </Grid>
          {isLoggedIn && (
            <>
              <Grid item xs={12} lg={6}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="project">
                    <FormattedMessage id="project" />
                  </InputLabel>
                  <ProjectSelect
                    id="project"
                    selectedProject={formik.values.project}
                    setSelectedProject={(value) => formik.setFieldValue('project', value)}
                    formik={formik}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} lg={12}>
                <Stack spacing={1} direction={'row'} alignItems={'center'} justifyContent={'center'}>
                  <CustomTooltip
                    arrow
                    color="success"
                    title="Herkese açık belgeler yalnızca belgelere erişimi olan kullanıcılar tarafından gözükecektir."
                  >
                    <Stack
                      style={{ opacity: formik.values?.is_private == 1 ? 0.4 : 1 }}
                      spacing={1}
                      direction={'row'}
                      alignItems={'center'}
                      justifyContent={'center'}
                    >
                      <Global />
                      <InputLabel>Herkese Açık</InputLabel>
                    </Stack>
                  </CustomTooltip>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={formik.values?.is_private == 1}
                        onChange={(e) => formik.setFieldValue('is_private', e.target.checked ? 1 : 0)}
                        name="is_private"
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    }
                    label={
                      <CustomTooltip
                        arrow
                        color="primary"
                        title="Özel belgeleri sadece siz görürsünüz. Erişimini ileride değiştirebilirsiniz."
                      >
                        <Stack
                          style={{ opacity: formik.values?.is_private == 0 ? 0.4 : 1 }}
                          spacing={1}
                          direction={'row'}
                          alignItems={'center'}
                          justifyContent={'center'}
                        >
                          <InputLabel>Özel</InputLabel>
                          <Lock />
                        </Stack>
                      </CustomTooltip>
                    }
                    labelPlacement="end"
                  />
                </Stack>
              </Grid>
            </>
          )}
          {progress > 0 && progress < 100 && (
            <Grid item xs={12}>
              <Stack spacing={1}>
                <Typography variant="caption" color="success">
                  {formik.values.files[0]?.name} | {(((progress / 100) * formik.values.files[0]?.size) / 1024 / 1024)?.toFixed(2)}/
                  {(formik.values.files[0]?.size / 1024 / 1024)?.toFixed(2)} MB
                </Typography>

                <LinearWithLabel value={progress} color="success" sx={{ height: 12 }} />
              </Stack>
            </Grid>
          )}

          <Grid item xs={12}>
            <Stack direction="row" justifyContent="flex-end">
              <Stack direction={'row'} spacing={1}>
                <AnimateButton>
                  <Button variant="text" color="secondary" onClick={() => onCancel && onCancel()}>
                    <FormattedMessage id="cancel" />
                  </Button>
                </AnimateButton>
                <AnimateButton>
                  <Button variant="contained" type="submit" color="primary">
                    <FormattedMessage
                      id={
                        formik.values?.is_folder == 0
                          ? itemToUpdate != undefined
                            ? 'update'
                            : 'upload'
                          : itemToUpdate != undefined
                          ? 'update'
                          : 'create'
                      }
                    />
                  </Button>
                </AnimateButton>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </MainCard>
    </form>
  );
};

AddDocument.propTypes = {
  itemToUpdate: PropTypes.any,
  onCancel: PropTypes.func,
  table_name: PropTypes.string,
  object_id: PropTypes.string
};

export default AddDocument;
