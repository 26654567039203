import { useQuery, useMutation, useQueryClient } from 'react-query';
import axios from 'utils/axios2';

const fetchStorages = () => {
  return axios.get('/api/storages');
};

export const useStoragesData = () => {
  return useQuery('storages', fetchStorages, { keepPreviousData: true, staleTime: 1000 * 6, cacheTime: 1000 * 6 });
};

const fetchAutocompleteStorages = async (params = {}) => {
  const queryParams = new URLSearchParams(
    Object.entries(params).reduce((acc, [key, value]) => {
      if (value != null) {
        acc[key] = value;
      }
      return acc;
    }, {})
  ).toString();

  const { data } = await axios.get(`/api/storages/autocomplete${queryParams ? `?${queryParams}` : ''}`);
  return data;
};

export const useAutocompleteStorages = (params) => {
  return useQuery(['storages', 'autocomplete', params], () => fetchAutocompleteStorages(params), {
    enabled: params?.isFocused,
    staleTime: 60 * 1000,
    cacheTime: 60 * 1000
  });
};

const createStorage = (storage) => {
  return axios.post('/api/storages', storage);
};

export const useCreateStorage = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: createStorage,
    onSuccess: () => {
      queryClient.invalidateQueries(['storages']);
    }
  });
  return mutation;
};

const fetchStoragesStats = () => {
  return axios.get('/api/storages/get_storages_stats');
};

export const useStoragesStats = () => {
  return useQuery('storages_stats', fetchStoragesStats, { staleTime: 1000 * 60, cacheTime: 1000 * 60, keepPreviousData: true });
};

const fetchStorage = ({ queryKey }) => {
  const id = queryKey[1];
  console.log('queryKey', queryKey, 'id', id, `/api/storages?id=${id}`);
  return axios.get(`/api/storages?id=${id}`);
};

export const useStorageData = (id) => {
  return useQuery(['storage', id], fetchStorage, { staleTime: 1000 });
};
