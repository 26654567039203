import React from 'react';
import { Chip } from '@mui/material';
import { Clock } from 'iconsax-react';

export const marksUrgency = [
  {
    range: [0, 25], // Range for "Düşük"
    value: 10,
    label: 'Düşük',
    color: 'primary'
  },
  {
    range: [26, 50], // Range for "Normal"
    label: 'Normal',
    value: 26,
    color: 'success'
  },
  {
    range: [51, 75], // Range for "Acil"
    value: 51,
    label: 'Acil',
    color: 'warning'
  },
  {
    range: [76, 100], // Range for "Çok Acil"
    value: 76,
    label: 'Çok Acil',
    color: 'error'
  }
];

export const findMarkForValueUrgencyCell = (value) => {
  return marksUrgency.find((mark) => value >= mark.range[0] && value <= mark.range[1]);
};

const UrgencyCell = ({ value }) => {
  // Finding the appropriate mark based on the value
  const urgencyMark = findMarkForValueUrgencyCell(value);

  return (
    <Chip
      icon={<Clock />}
      label={urgencyMark ? urgencyMark.label : 'Bilinmiyor'} // Show label or a default text if not found
      color={urgencyMark ? urgencyMark.color : 'default'} // Default color if not found
      variant="outlined"
    />
  );
};

export default UrgencyCell;
