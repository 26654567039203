import { Box, Stack, Typography } from '@mui/material';
import CustomTooltip from 'components/@extended/Tooltip';
import React from 'react';
import { FormattedDate, FormattedRelativeTime, FormattedTime } from 'react-intl';

const TimestampCell = ({ value, detailed }) => {
  const now = Date.now(); // Şu anki zaman
  const timestamp = new Date(value).getTime();
  const timeDifferenceInSeconds = (timestamp - now) / 1000; // Şu an ile hücredeki zaman arasındaki fark (saniye cinsinden)

  const TooltipContent = () => {
    return (
      <Stack spacing={1}>
        <FormattedRelativeTime value={timeDifferenceInSeconds} numeric="auto" updateIntervalInSeconds={1} />
        <Typography variant="caption">
          <FormattedDate value={new Date(value)} year="numeric" month="numeric" day="2-digit" />{' '}
          <FormattedTime value={new Date(value)} hour="numeric" minute="numeric" second="numeric" />
        </Typography>
      </Stack>
    );
  };

  const DetailedView = () => {
    return (
      <Stack spacing={1}>
        <FormattedDate value={new Date(value)} year="numeric" month="numeric" day="2-digit" />{' '}
        <FormattedTime value={new Date(value)} hour="numeric" minute="numeric" second="numeric" />
      </Stack>
    );
  };
  return detailed ? (
    <DetailedView />
  ) : (
    <CustomTooltip arrow color="info" title={<TooltipContent />} placement="top">
      <Box
        sx={{
          display: 'inline-flex'
        }}
      >
        <FormattedDate value={new Date(value)} year="numeric" month="numeric" day="2-digit" />
      </Box>
    </CustomTooltip>
  );
};

export default TimestampCell;
