import { useState, useEffect } from 'react';

const useUnreadNotificationCount = () => {
  const [totalCount, setTotalCount] = useState(null);
  const [countByMenu, setCountByMenu] = useState({});

  useEffect(() => {
    const loadValue = () => {
      const savedValue = sessionStorage.getItem('unreadNotificationCount');
      const savedCountsByMenu = sessionStorage.getItem('unreadCountsByMenu');
      if (savedValue && !isNaN(savedValue)) {
        setTotalCount(parseInt(savedValue, 10));
        setCountByMenu(JSON.parse(savedCountsByMenu));
      } else {
        setTotalCount(0);
        setCountByMenu({});
      }
    };
    loadValue();
    window.addEventListener('storage', loadValue);
    return () => {
      window.removeEventListener('storage', loadValue);
    };
  }, []);

  return { totalCount, countByMenu };
};

export default useUnreadNotificationCount;
