// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { Data2, Key } from 'iconsax-react';

// icons
const icons = {
  list: Data2,

  access: Key
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const developer = {
  id: 'developer',
  title: <FormattedMessage id="developer" />,
  type: 'group',
  children: [
    {
      id: 'access',
      title: <FormattedMessage id="access" />,
      type: 'item',
      icon: icons.access,
      perms: ['#admin'],
      url: '/access'
    },

    {
      id: 'logsTables',
      title: 'API Logları',
      type: 'item',
      url: '/loglists',
      icon: icons.list
    }
  ]
};

export default developer;
