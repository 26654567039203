import PropTypes from 'prop-types';

// material-ui
import { Button, Dialog, DialogContent, Stack, Typography } from '@mui/material';

// project-imports
import Avatar from 'components/@extended/Avatar';
import { PopupTransition } from 'components/@extended/Transitions';

// assets
import { Add } from 'iconsax-react';

// third-party
import { FormattedMessage } from 'react-intl';

// ==============================|| CUSTOMER - DELETE ||============================== //

export default function AlertClosing({ highlight, open, onClose, onAgree }) {
  return (
    <Dialog
      sx={{ zIndex: 100005 }}
      open={open}
      onClose={onClose}
      keepMounted
      TransitionComponent={PopupTransition}
      maxWidth="xs"
      aria-labelledby="column-delete-title"
      aria-describedby="column-delete-description"
    >
      <DialogContent sx={{ mt: 2, my: 1 }}>
        <Stack alignItems="center" spacing={3.5}>
          <Avatar color={'error'} sx={{ width: 72, height: 72 }}>
            <Add style={{ transform: 'rotate(45deg)' }} />
          </Avatar>
          <Stack spacing={1}>
            <Typography variant="h5" align="center" color={'error.main'}>
              {highlight}
            </Typography>
            <Typography variant="h5" align="center">
              Girdiğiniz bilgiler kaydedilmemiş olabilir.
            </Typography>
            <Typography variant="h5" align="center">
              Kapatmak istediğinize emin misiniz?
            </Typography>
          </Stack>

          <Stack direction="row" spacing={2} sx={{ width: 1 }}>
            <Button fullWidth onClick={onClose} color="secondary" variant="outlined">
              <FormattedMessage id="cancel" />
            </Button>
            <Button
              fullWidth
              color={'error'}
              variant="contained"
              onClick={() => {
                onAgree();
              }}
              autoFocus
            >
              <FormattedMessage id="close" />
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

AlertClosing.propTypes = {
  title: PropTypes.string,
  des1: PropTypes.string,
  des2: PropTypes.string,
  des3: PropTypes.string,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  handleApprove: PropTypes.func
};
